import React, {Component} from 'react';
import {connect} from 'react-redux';
import { TOGGLE_HEADER, SET_PRODUCT } from '../../../actions/ActionTypes';
import { Link } from 'react-router-dom'

class ProductCard extends Component {

  clickHandler() {
    this.props.toggleHeader(false);
    this.props.setProducts(this.props.item[0].values[0].content_string_en, null);
    this.props.history.push({search: `?lang=${this.props.language}`, pathname:'/product'}, {category: this.props.item.name});
  }

  render() {
    let imgSrc = this.props.item[3].values[0] ? this.props.item[3].values[0].resized_s_url : null
    return (
      <div id="ProductCard" className="mx-md-2 d-flex flex-column align-items-center" >
        <img src={imgSrc} />
        <div className="name font-20">
          {this.props.item[0].values[0][`content_string_${this.props.language}`]}
        </div>
        <div className="text font-10">
          {this.props.item[1].values[0][`content_text_${this.props.language}`]}
        </div>

          <div className="button" onClick={()=>this.clickHandler()}>
            More
          </div>

      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    history: state.uiReducer.history,
    language: state.uiReducer.language
  };
};

const dispatchToProps = {
  toggleHeader: (bool) => (dispatch) => dispatch({type: TOGGLE_HEADER, bool: bool}),
  setProducts: (category, brand) => (dispatch) => dispatch({type: SET_PRODUCT, category: category, brand: brand}),
}

export default connect(mapStateToProps, dispatchToProps)(ProductCard);
