import React, {Component} from 'react';
import {connect} from 'react-redux';
import * as actions from 'actions/Action.js';

import {Footer, About, Header, Background, People, Teams, Suppliers, Client, Products, Award, Contact, Grading, Packaging, ProductsTable } from './index.js';

import { SAVE_PATH } from '../actions/ActionTypes';

import Loader from 'react-loader-spinner';
import Icon from '../assets/icon/cgt-logo.svg'
import loading_BG from "../assets/photo/bg-1.jpg"

class MainContent extends Component {
  constructor(props){
    super(props)
    console.log(props);
    // console.log(props.location.search);
  }
  componentDidMount() {
    if (this.props.previousPath.length > 0 && this.props.previousPath !== "/") {

      if (this.props.history.location.state) {
        let element = document.getElementById(this.props.history.location.state.id);
        element.scrollIntoView({ block: 'start',  behavior: 'smooth' });
      }
    }
  }

  render(){
    return (
      <div id="MainPage">
        <Header links={this.props.links} scrollToComponent={this.props.scrollToComponent} linkClickHandler={this.props.linkClickHandler}/>

        <Background/>
        <About data={this.props.dataSet.about}/>
        {
          this.props.dataSet.services ? (
            <About data={this.props.dataSet.services}/>
          ) : (
            <div></div>
          )
        }
        {/* <People/> */}
        <Teams/>
        <Suppliers/>
        {/* <Client/> */}
        <Products/>
        {/* <Award/> */}
        <Grading/>
        <Packaging/>

        <Footer links={this.props.links} scrollToComponent={this.props.scrollToComponent} linkClickHandler={this.props.linkClickHandler}/>
      </div>
    )
  }
}

class MainPage extends Component {

  constructor(props){
    super(props);
  }

  componentDidMount() {
    this.props.loadData();
  }

  componentWillUnmount() {
    this.props.savePath(this.props.history.location.pathname);
  }

  render() {

    if (this.props.dataSet && Object.keys(this.props.dataSet).length > 2 ) {

        return (
          <MainContent
          dataSet={this.props.dataSet}
          previousPath={this.props.previousPath}
          history={this.props.history}
          links={this.props.links}
          scrollToComponent={this.props.scrollToComponent}
          linkClickHandler={this.props.linkClickHandler}
          />
        )
    }

    return (
      <div className="d-flex align-items-center justify-content-center w-100" style={{height: '100vh'}}>
        <img className="loading-bg" src={loading_BG}/>
        <Loader className="loading-spinner" type="Triangle" color="#00d877" height={150} width={150} />
        <div className="loading-icon-container">
          <img className="loading-icon" src={Icon}/>
        </div>
      </div>
    )

  }
}

const mapStateToProps = (state) => {
  return {
    history: state.uiReducer.history,
    previousPath: state.uiReducer.previousPath,
    dataSet: state.dataReducer.dataSet
  };
};

const dispatchToProps = {
  savePath: (path) => (dispatch) => dispatch({type: SAVE_PATH, path: path}),
  loadData: actions.loadData
}

export default connect(mapStateToProps, dispatchToProps)(MainPage);
