import {
  STORE_API_DATA_TO_DATA_REDUCER,
  STORE_PRODUCT_BANNER_TO_DATA_REDUCER
} from 'actions/ActionTypes';
import * as actionTypes from '../actions/ActionTypes';
import {
  qurttyApiHelper,
  axios,
  ormHelper
} from 'qurtty-js-sdk';

// const organizeData = (pages) => {
//   let page1 = pages[0];
//   let page2 = pages[1];
//   //
//   // let bannerImgs = page1[dataInRows[0]].map(obj => obj.)
// }

let getDataInRows = (entities) => {
  let structure = entities[0].structure
  return entities.map(entity => {
    return structure.getAssociation('attrs').map((attr) => {
      let values = entity.getValues(attr)
      let transformedValues = attr.type_type === 'Eav::ParticleType' ? values.map(val => val.content_particle()) : values
      return {
        type: attr.typeName(),
        values: transformedValues
      }
    })
  })
}

const init = () => {
  axios.defaults.headers.common['Portal-Token'] = '1c0f398d3044d9c23e9ade68cfa6df31'
  return Promise.all([
    qurttyApiHelper.getResources('eav/attr_types'),
    qurttyApiHelper.getResources('eav/particle_types'),
  ]).then(() => {
    return qurttyApiHelper.getSingleResource('projects', 56).then(project => {
      return project.$refreshAssociation('participant_docs', {
        _with_structure: true
      }).then(() => {
        return project
      })
    })
  })
}

export const loadData = () => (dispatch) => {
  let getDataPromise = init().then(project => {
    return project.website_control.$refreshAssociation("webpages").then(() => {

      let webpages = project.website_control.getAssociation('webpages')

      let allPagesPromises = webpages.map((wp, pageNum) => {

        let sections = wp.getAssociation('webpage_sections');

        let listOfEntities = [];
        let loadAllEntitiesPromises = sections.map((section) => {
          let structure = section.structure;
          return structure.$doubleRefreshAssociation('entities').then(() => {
            structure.getAssociation('entities').forEach((entity) => listOfEntities.push(entity))
          })
        })

        let fetchParticlesPromise = Promise.all(loadAllEntitiesPromises).then(() => {
          return ormHelper.$fetchLinkedParticles(listOfEntities, project, {
            fetchLinked: {
              Photo: "all",
              MemberCard: "all",
              CompanyCard: "all",
              Product: "skip",
              "Eav::Entity": "skip"
            },
            params: {
              per_page: 100,
            },
          })
        })

        return fetchParticlesPromise.then(() => {
          return sections.map((section) => {
            // console.log("!!!!!!!!!", section);
            let headings_en = (section.name_en + '').split(" | ");
            let headings_zh_hk = (section.name_zh_hk + '').split(" | ");
            let structure = section.structure;
            let description_en = section.description_en
            let description_zh_hk = section.description_zh_hk

            let dataInRows;

            let entities = structure.getAssociation('entities') //row
            dataInRows = getDataInRows(entities)

            return {
              heading_en: headings_en[0],
              subheading_en: headings_en[1],
              subsubheading_en: description_en,
              heading_zh_hk: headings_zh_hk[0],
              subheading_zh_hk: headings_zh_hk[1],
              subsubheading_zh_hk: description_zh_hk,
              rows: dataInRows,
              is_hidden: section.is_hidden,
            }
          })
        })
      })
      return Promise.all(allPagesPromises).then((resp) => {

        return {
          project: project,
          data: resp
        }
      })

    })
  })

  getDataPromise.then((resp) => {
    let dataSet = {};
    dataSet['homepageBanner'] = resp.data[0][0];
    dataSet['about'] = resp.data[0][1];
    dataSet['people'] = resp.data[0][2];
    dataSet['team'] = resp.data[0][3];
    dataSet['partners'] = resp.data[0][4];
    dataSet['clients'] = resp.data[0][5];
    dataSet['products'] = resp.data[0][6];
    dataSet['certificate'] = resp.data[0][7];
    dataSet['grading'] = resp.data[0][8];
    dataSet['packaging'] = resp.data[0][9];
    dataSet['contact'] = resp.data[0][10];
    dataSet['joinus'] = resp.data[0][11];
    dataSet['productBkg'] = resp.data[0][12]
    dataSet['services'] = resp.data[0][13]
    resp.data = dataSet;
    dispatch({
      type: STORE_API_DATA_TO_DATA_REDUCER,
      payload: resp
    })
  })
}

const loadProductsRouteFromHome = (project, products) => {
  let webpages = project.website_control.getAssociation('webpages')

  let productPage = webpages[1]
  let bannerSection = productPage.getAssociation('webpage_sections')[0] //banner
  let productSection = productPage.getAssociation('webpage_sections')[1]
  let categoryEntities = productSection.structure.getAssociation('entities')

  let brandRoomAttr = productSection.structure.getAssociation('attrs').find(attr => attr.type_type === 'Eav::Structure')
  let brandEntityIds = categoryEntities.map(ett => ett.getValues(brandRoomAttr).map(val => val.content_particle_id)).flat()
  let brandStructure = brandRoomAttr.type()
  return brandStructure.$doubleRefreshAssociation('entities', {
    id: brandEntityIds,
    specific: true,
  }).then(() => {
    return bannerSection.structure.$doubleRefreshAssociation('entities').then(() => {
      let listOfEntities = []

      let brandEntities = brandStructure.getAssociation('entities')
      brandEntities.forEach(entity => listOfEntities.push(entity))

      let bannerEntities = bannerSection.structure.getAssociation('entities')
      bannerEntities.forEach(entity => listOfEntities.push(entity))

      console.log("hey", bannerEntities, listOfEntities)

      return ormHelper.$fetchLinkedParticles(listOfEntities, project, {
        fetchLinked: {
          Photo: "all",
          MemberCard: "skip",
          CompanyCard: "skip",
          Product: "all",
          "Eav::Entity": "all"
        },
        params: {
          per_page: 300,
          specific: true,
        },
      }).then(() => {
        let categoryRows = products.rows
        let brandStructureAttrs = brandStructure.getAssociation('attrs')
        for (let cateRow of categoryRows) {
          console.log(cateRow[2].values);
          let thisBrandEntities = cateRow[2].values.map(val => val.content_particle())
          cateRow[2].values = getDataInRows(thisBrandEntities)
          let brandRows = cateRow[2].values
          for (let row2 of brandRows) {
            row2[0].values = row2[0].values.map(val => val.content_particle().getAssociation('values'))
          }
        }

        let productBannerRows = getDataInRows(bannerEntities)

        return { 'categoryRows': categoryRows, 'productBannerRows': productBannerRows }
      })
    })
  })
}

const loadProductsDirectly = () => {

  return init().then(project => {
    return project.website_control.$refreshAssociation("webpages").then(() => {

      let webpages = project.website_control.getAssociation('webpages')

      let productPage = webpages[1]

      //load the outer most layer table of product page
      let productSection = productPage.getAssociation('webpage_sections')[1];

      let loadProductSectionPromise = productSection.structure.$doubleRefreshAssociation('entities');

      let categoryRows = []

      return loadProductSectionPromise.then(() => {
        let structure = productSection.structure;
        let entities = structure.getAssociation('entities') //row

        categoryRows = getDataInRows(entities)



        let categoryEntities = productSection.structure.getAssociation('entities')

        let brandRoomAttr = productSection.structure.getAssociation('attrs').find(attr => attr.type_type === 'Eav::Structure')
        let brandEntityIds = categoryEntities.map(ett => ett.getValues(brandRoomAttr).map(val => val.content_particle_id)).flat()
        let brandStructure = brandRoomAttr.type()

        let bannerSection = productPage.getAssociation('webpage_sections')[0]
        return brandStructure.$doubleRefreshAssociation('entities', {
          id: brandEntityIds,
          specific: true,
        }).then(() => {

          return bannerSection.structure.$doubleRefreshAssociation('entities').then(() => {
            let bannerEntities = bannerSection.structure.getAssociation('entities')

            //get contact section in home page
            let homePage = webpages.find(wp => wp.name_en === 'Home')
            let contactSection = homePage.getAssociation('webpage_sections').find(session => session.name_en === 'Contact')
            return contactSection.structure.$doubleRefreshAssociation('entities').then(() => {


              let contactEntities = contactSection.structure.getAssociation('entities')

              let listOfEntities = []
              bannerSection.structure.getAssociation('entities').forEach((entity) => listOfEntities.push(entity))
              contactSection.structure.getAssociation('entities').forEach((entity) => listOfEntities.push(entity))
              brandStructure.getAssociation('entities').forEach((entity) => listOfEntities.push(entity))

              return ormHelper.$fetchLinkedParticles(listOfEntities, project, {
                fetchLinked: {
                  Photo: "all",
                  MemberCard: "skip",
                  CompanyCard: "all",
                  Product: "all",
                  "Eav::Entity": "all"
                },
                params: {
                  per_page: 300,
                  specific: true,
                },
              }).then(() => {
                let brandStructureAttrs = brandStructure.getAssociation('attrs')
                for (let cateRow of categoryRows) {
                  let thisBrandEntities = cateRow[2].values.map(val => val.content_particle())
                  cateRow[2].values = getDataInRows(thisBrandEntities)
                  let brandRows = cateRow[2].values
                  for (let row2 of brandRows) {
                    row2[0].values = row2[0].values.map(val => val.content_particle().getAssociation('values'))
                  }
                }
                let contactRows = getDataInRows(contactEntities)
                let productBannerRows = getDataInRows(bannerEntities)


                return { 'categoryRows': categoryRows, 'contactRows': contactRows, 'productBannerRows': productBannerRows }
              })

            })
          })

        })
      })

    })
  })
}

export const loadProducts = (project, dataSet) => (dispatch) => {
  if (project === null && dataSet === null) {
    loadProductsDirectly().then(resp => {
      //console.log("loadProductsDirectly", resp)

      let contactRows = { 'rows': resp.contactRows };
      let bannerRows = { 'rows': resp.productBannerRows }

      //console.log("bannerRows", bannerRows)

      let data = {};
      data['contact'] = contactRows;

      let dataPayloadObj = {}
      dataPayloadObj['data'] = data

      //console.log("payloadObj", payloadObj)

      dispatch({
        type: actionTypes.SET_ALL_PRODUCTS,
        payload: resp.categoryRows
      })
      dispatch({
        type: STORE_PRODUCT_BANNER_TO_DATA_REDUCER,
        payload: bannerRows
      })
      dispatch({
        type: STORE_API_DATA_TO_DATA_REDUCER,
        payload: dataPayloadObj
      })
    })
  } else {
    //console.log('dataSet.products', dataSet.products);
    loadProductsRouteFromHome(project, dataSet.products).then(resp => {
      //console.log("loadProductsRouteFromHome", resp)

      let bannerRows = { 'rows': resp.productBannerRows }
      //console.log("bannerRows", bannerRows)

      dispatch({
        type: actionTypes.SET_ALL_PRODUCTS,
        payload: resp.categoryRows
      })
      dispatch({
        type: STORE_PRODUCT_BANNER_TO_DATA_REDUCER,
        payload: bannerRows
      })
    })
  }
}
