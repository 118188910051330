import React, {Component} from 'react';
import {connect} from 'react-redux';
import { } from '../../actions/ActionTypes';
import Colorbar from '../../components/colorbar/Colorbar';
import AwardCard from './awardCard/AwardCard';
import Award1 from '../../assets/materials/award1.png';
import Award2 from '../../assets/materials/award2.png';
import Award3 from '../../assets/materials/award3.jpeg';
import Award4 from '../../assets/materials/award4.png';

class Award extends Component {
  constructor(props){
    super(props);
    this.state={
      awards: [
        {img: Award1, name: 'ISO 9001', content: 'The marketing department is dedicated to building ecoATM Gazelle as the leading reCommerce brancom The marketing department is dedicated to building ecoATM Gazelle as the leading reCommerce brancom'},
        {img: Award2, name: 'ISO 14001', content: 'The marketing department is dedicated to building ecoATM Gazelle as the leading reCommerce brancom The marketing department is dedicated to building ecoATM Gazelle as the leading reCommerce brancom'},
        {img: Award3, name: 'OHSAS 18001', content: 'The marketing department is dedicated to building ecoATM Gazelle as the leading reCommerce brancom The marketing department is dedicated to building ecoATM Gazelle as the leading reCommerce brancom'},
        {img: Award4, name: 'R2', content: 'The marketing department is dedicated to building ecoATM Gazelle as the leading reCommerce brancom The marketing department is dedicated to building ecoATM Gazelle as the leading reCommerce brancom'},
      ]
    }
  }
  render() {
    // console.log(this.props.data);
    const data = this.props.data;
    const heading = data[`heading_${this.props.language}`];
    const subheading = data[`subheading_${this.props.language}`]; const subsubheading = data[`subsubheading_${this.props.language}`]

    const awards = data.rows;
    if (this.props.data.is_hidden) {
      return (<div></div>)
    } else {
      return (
        <div id="Award" >
          <div className="py-4 px-3 py-md-5 px-md-5 d-flex flex-column align-items-center">
            <h6 className="session font-weight-normal">{heading}</h6>
            <h2 className="title">{subheading}</h2>
            <Colorbar/>
            <div className="subsubheading font-12 mt-3"> {subsubheading} </div>

            <div className="card-container pt-4 pt-md-5 py-md-3 d-flex flex-row row justify-content-center">
              {awards.map((item, index) =>
                <AwardCard className="" key={index} item={item}/>
              )}
            </div>
          </div>
        </div>
      )
    }
  }
}

const mapStateToProps = (state) => {
  return {
    language: state.uiReducer.language, data: state.dataReducer.dataSet.certificate
  };
};

const dispatchToProps = {
}

export default connect(mapStateToProps, dispatchToProps)(Award);
