import * as actionTypes from '../actions/ActionTypes';
import { createBrowserHistory } from 'history';
import { routerHelper } from 'qurtty-js-sdk';

const history = createBrowserHistory({
  basename: routerHelper.getBasename(window.location.href)
});

const initialState = {
  language: 'en', //zh_hk

  history: history,
  allowMainScroll: true,
  testss: "",
  testData: [],
  current_category: null, //andus
  current_brand: null,
  isPopup: false,
  isHeader: false,
  popupIndex: -1,
  isShowCategory: false,
  previousPath: "",

  categories: []
};

const uiReducer = (state = initialState, action) => {

  switch (action.type) {
    case actionTypes.SET_ALL_PRODUCTS:
      console.log(action.payload);
      let categories = action.payload.map(cateRow => {
        return {
          name_zh_hk: cateRow[0].values[0].content_string_zh_hk,
          name_en: cateRow[0].values[0].content_string_en,
          brands: cateRow[2].values.map(brandRow => {
            return {
              name: brandRow[0].values[0][0].content_string_en,
              products: brandRow[1].values.map(pdct => {
                let photo = pdct.getAssociation('photos')[0]
                return {
                  name: pdct.name_en,
                  content: pdct.description_en[0].content,
                  img: photo ? photo.resized_s_url : '',
                }
              }),
            }
          })
        }
      })
      return {
        ...state,
        categories: categories
      }
    case actionTypes.SWITCH_LANGUAGE: {
      if (state.language === 'en'){
        return {...state, language: 'zh_hk'}
      } else {
        return {...state, language: 'en'}
      }

    }
    case actionTypes.SET_LANGUAGE: {
      if (action.language === 'zh_hk') {
        return {...state, language: action.language}
      } else {
        return {...state, language: 'en'}
      }
    }

    case actionTypes.SET_PRODUCT:
      console.log('[set brand] ', action.category, action.brand);
      return {
        ...state,
        current_category: action.category,
        current_brand: action.brand || (action.category.brands || [])[0],
      }
    case actionTypes.TOGGLE_POPUP:
      console.log('TOGGLE_POPUP');
      if (action.bool) {

        return {
          ...state,
          isPopup: action.bool,
          popupIndex: action.index
        }
      }
      return {
        ...state,
        isPopup: action.bool,
      }
    case actionTypes.NEXT_PRODUCT:
      console.log('NEXT_PRODUCT');
      return {
        ...state,
        popupIndex: state.popupIndex + 1,
      }
    case actionTypes.PREV_PRODUCT:
      console.log('PREV_PRODUCT');
      return {
        ...state,
        popupIndex: state.popupIndex - 1,
      }
    case actionTypes.TOGGLE_CATEGORY:
      return {
        ...state,
        isShowCategory: !state.isShowCategory,
      }
    case actionTypes.SAVE_PATH:
      console.log("save to prev path path =", action.path)
      return {
        ...state,
        previousPath: action.path,
      }
    case actionTypes.TOGGLE_HEADER:
      console.log('TOGGLE_HEADER: ', action.bool);
      if (action.bool != null) {
        return {
          ...state,
          isHeader: action.bool
        }
      } else {
        return {
          ...state,
          isHeader: !state.isHeader
        }
      }
    case actionTypes.ALLOW_MAIN_SCROLL:
      console.log('ALLOW_MAIN_SCROLL: ', action.bool);
      return {
        ...state,
        allowMainScroll: action.bool
      }
    default:
      return state;
  }
}

export default uiReducer;
