import React, {Component} from 'react';
import {connect} from 'react-redux';
import { ALLOW_MAIN_SCROLL } from '../../actions/ActionTypes';
import Colorbar from '../../components/colorbar/Colorbar';
import team1 from '../../assets/materials/team1.jpeg';
import close from '../../assets/icon/close-white.svg';

class Teams extends Component {
  constructor(props) {
    super(props);

    // this.toggle = this.toggle.bind(this);
    this.state = {
      isPopup: false,
      activeIndex: 0,
      // teams: [
      //   {img: team1, name: 'Marketing', description: 'The marketing department is dedicated to building ecoATM Gazelle as the leading reCommerce brancom...'},
      //   {img: team1, name: 'Marketing', description: 'The marketing department is dedicated to building ecoATM Gazelle as the leading reCommerce brancom...'},
      //   {img: team1, name: 'Marketing', description: 'The marketing department is dedicated to building ecoATM Gazelle as the leading reCommerce brancom...'},
      //   {img: team1, name: 'Marketing', description: 'The marketing department is dedicated to building ecoATM Gazelle as the leading reCommerce brancom...'},
      // ]
    };
  }

  popup(heading, subheading){
    if(this.state.isPopup){
      const data = this.props.joinus_data;
      const intro = data.rows[0][1].values[0][`content_html_${this.props.language}`];
      const posts = data.rows.filter( (item, index) => index !== 0 );
      // posts =
      return (
        <div className="popup-container" onClick={()=>{
          this.setState({isPopup: false})
          // this.props.allowMainScroll(true)
          document.body.style.overflow = 'scroll'

        }}>
          <div className="popup" onClick={(event)=>event.stopPropagation()}>
            <h6 className="session font-weight-normal"> {heading} </h6>
            <h2 className="title"> {subheading} </h2>
            <Colorbar />

            <div className="intro font-11" dangerouslySetInnerHTML={{__html: intro}}/>
            <div className="intro-posts">
              {posts.map( (post, index) => (
                <div key="index" className={`intro-post ${this.state.activeIndex === index ? 'active' : ''}`}
                  onClick={()=>this.setState({activeIndex: index})}
                > {post[0].values[0][`content_string_${this.props.language}`]}</div>
              ))}
            </div>
            <div className="posts">

                <div className="post">

                  <div className="post-title"> {posts[this.state.activeIndex][0].values[0][`content_string_${this.props.language}`]} </div>
                  <Colorbar width={'100%'}/>
                  <div className="post-content" dangerouslySetInnerHTML={{__html: posts[this.state.activeIndex][1].values[0][`content_html_${this.props.language}`]}}/>

                  <div> </div>
                </div>

            </div>

            <div className="close-button" onClick={()=>{
              this.setState({isPopup: false})
              // this.props.allowMainScroll(true)
              document.body.style.overflow = 'scroll'


            }}>
              <img src={close}/>
            </div>
          </div>
        </div>
      )
    }
    return;
  }

  render() {
    // console.log(this.props.data);
    console.log(this.props.joinus_data);

    const data = this.props.data;
    const heading = data[`heading_${this.props.language}`];
    const subheading = data[`subheading_${this.props.language}`]; const subsubheading = data[`subsubheading_${this.props.language}`]

    const teams = data.rows;

    if (this.props.data.is_hidden) {
      return (<div></div>)
    } else {
      return (
        <div id="Teams">
          <div className="py-4 px-3 py-md-5 px-md-5 d-flex flex-column align-items-center">
            <h6 className="session font-weight-normal"> {heading} </h6>
            <h2 className="title"> {subheading} </h2>
            <Colorbar/>
            <div className="subsubheading font-12 mt-3"> {subsubheading} </div>

            <div className="team-container">
              <div className="pt-4 pt-md-5 d-flex flex-row row justify-content-center">
                {teams.map((item ,index) =>
                  {
                    // console.log(item);
                    return <div key={index} className="team mx-2 mx-md-2 d-flex mb-3 flex-column align-items-center" >
                      {item[0].values.length > 0 && <img src={item[0].values[0] ? item[0].values[0].resized_xs_url : null}/>}
                      <div className="cover">
                        <div className="name">{ item[1].values[0][`content_string_${this.props.language}`]}</div>
                        <div className="description">{ item[2].values[0][`content_text_${this.props.language}`]}</div>
                      </div>
                    </div>
                  }
                )}
              </div>
              {/*
                <div className="join-button" onClick={()=>{
                this.setState({isPopup: !this.state.isPopup})
                document.body.style.overflow = 'hidden'
                }}>{this.props.language === 'en' ? 'Join Us': '加入我們'}</div>
                */}
              </div>

            </div>
            {this.popup(heading, subheading)}
          </div>
        )
    }

  }
}

const mapStateToProps = (state) => {
  return {
    language: state.uiReducer.language,
    data: state.dataReducer.dataSet.team,
    language: state.uiReducer.language,
    joinus_data: state.dataReducer.dataSet.joinus
  };
};

const dispatchToProps = {
  allowMainScroll: (bool) => dispatch => dispatch({type: ALLOW_MAIN_SCROLL, bool: bool}),
}

export default connect(mapStateToProps, dispatchToProps)(Teams);
