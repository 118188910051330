var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) {
  return typeof obj;
} : function (obj) {
  return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
};

import { schemaModel, session, ormState } from './schemas';
import { doubleAPILookupList, associationNameLookupList, modelClassToModelNameLookupList, modelNamePolymorphicLookupList } from './lookupList';
import { modelRefreshAPI, modelCreateAPI, modelSavingAPI, modelDeleteAPI, modelAssociationRefreshAPI } from '../api/api';
import * as api from '../api/api';
import * as apiHelper from '../api/apiHelper';
import { normalize } from 'normalizr';
import pluralize from 'pluralize';
import _ from 'lodash'; // Static helper function
// Photo helper function

var recursiveFormData = function recursiveFormData(obj, form, namespace) {
  var formData = form;
  var formKey = void 0;

  for (var property in obj) {
    if (obj.hasOwnProperty(property)) {
      if (namespace) {
        formKey = namespace + '[' + property + ']';
      } else {
        formKey = property;
      }

      if (_typeof(obj[property]) === 'object') {
        recursiveFormData(obj[property], formData, formKey);
      } else {
        formData.append(formKey, obj[property]);
      }
    }
  }

  return formData;
};

var photoUpload = function photoUpload(url, file, param) {
  var formData = new FormData(); //console.log(file);

  formData.append('file', file);
  recursiveFormData(param, formData); //console.log(formData);

  return api.uploadPhoto(url, formData).then(function (resp) {
    var normalizedData = normalize(resp.data, {
      photo: schemaModel.Photo
    });
    normalizedDataInsertORM(normalizedData);
    return resp;
  });
};

var recursiveFiles = function recursiveFiles(url, fileList, param) {
  return new Promise(function (resolve) {
    if (fileList.length > 0) {
      var file = fileList.pop();
      return photoUpload(url, file, param).then(function (resp) {
        return recursiveFiles(url, fileList, param).then(function (subResp) {
          var response = subResp;
          response.success.push(session.Photo.withId(resp.data.photo.id)); //console.log(response.success);

          resolve(response);
        });
      }, function (resp) {
        return recursiveFiles(url, fileList, param).then(function (subResp) {
          // console.log(file);
          var response = subResp;
          response.fail.push({
            fileName: file.name,
            size: file.size
          });
          resolve(response);
        });
      });
    } else {
      resolve({
        success: [],
        fail: []
      });
    }
  });
}; // Photo helper function End
// Export Function


export var organizeParam = function organizeParam(model, argument) {
  var param = {};

  if (argument.length > 0) {
    var args = Array.from(argument);
    args.forEach(function (text) {
      param[text] = model[text];
    });
  } else {
    var keyList = Object.keys(model).filter(function (text) {
      return text[0] !== '_' && text[0] !== '$' && text !== 'id';
    });
    keyList.forEach(function (text) {
      param[text] = model[text];
    });
  }

  return param;
};
/**
 * convert resources URL like 'member_cards' to model name like 'MemberCard'
 * @param  {String} resourcesUrl resources URL
 * @return {String}              model name
 */

export var resourcesUrlToModelName = function resourcesUrlToModelName(resourcesUrl) {
  var lookupResult = doubleAPILookupList[resourcesUrl];
  var modelName = void 0;

  if (lookupResult) {
    modelName = lookupResult.modelName;
  } else {
    modelName = resourcesUrl.replace('/', '00');
    modelName = pluralize(_.upperFirst(_.camelCase(modelName).replace('00', '_')), 1);
  }

  return modelName;
};
export var associationNameToResourcesUrl = function associationNameToResourcesUrl(associationName) {
  var resourcesUrl = associationNameLookupList[associationName] || associationName;
  return resourcesUrl;
};
export var associationNameToModelName = function associationNameToModelName(associationName) {
  return resourcesUrlToModelName(associationNameToResourcesUrl(associationName));
};
export var modelClassToModelName = function modelClassToModelName(modelClass) {
  var name = modelClassToModelNameLookupList[modelClass];
  return name || modelClass.replace('::', '_');
};
export var modelNameToPolymorphicNames = function modelNameToPolymorphicNames(modelName) {
  var names = modelNamePolymorphicLookupList[modelName] || [];
  return names;
};
export var normalizedDataInsertORM = function normalizedDataInsertORM(normalizedData) {
  // console.log(normalizedData);
  for (var _iterator = Object.keys(normalizedData.entities), _isArray = Array.isArray(_iterator), _i = 0, _iterator = _isArray ? _iterator : _iterator[Symbol.iterator]();;) {
    var _ref;

    if (_isArray) {
      if (_i >= _iterator.length) break;
      _ref = _iterator[_i++];
    } else {
      _i = _iterator.next();
      if (_i.done) break;
      _ref = _i.value;
    }

    var key = _ref; // console.log(key);   Check Missing Key

    for (var _iterator2 = Object.values(normalizedData.entities[key]), _isArray2 = Array.isArray(_iterator2), _i2 = 0, _iterator2 = _isArray2 ? _iterator2 : _iterator2[Symbol.iterator]();;) {
      var _ref2;

      if (_isArray2) {
        if (_i2 >= _iterator2.length) break;
        _ref2 = _iterator2[_i2++];
      } else {
        _i2 = _iterator2.next();
        if (_i2.done) break;
        _ref2 = _i2.value;
      }

      var rec = _ref2;
      var modelName = resourcesUrlToModelName(key);

      if (modelName.endsWith('Exposure')) {
        session['' + rec.card_type + modelName].upsert(rec);
      } else if (modelName === 'PhotoablePhotoParticipation') {
        session[rec.photoable_type + 'PhotoParticipationIn' + rec.photo_participation_party_type].upsert(rec);
      } else if (modelName === 'PhotoParticipation') {
        var type = rec.party_type.replace('::', '_');
        session['PhotoParticipationIn' + type].upsert(rec);
      } else if (modelName === 'Website') {
        var _type = rec.card_type;
        session["\u0394" + _type + "\u0394Website"].upsert(rec);
      } else if (modelName === 'Version') {
        var _type2 = rec.item_type.replace('::', '_');

        session["\u0394" + _type2 + "\u0394Version"].upsert(rec);
      }

      if (!session[modelName]) {
        console.log('Wah!!', modelName, key);
      }

      session[modelName].upsert(rec);
    }
  }
};
export var modelPhotoUpload = function modelPhotoUpload(url, fileList, param) {
  if ((typeof fileList === 'undefined' ? 'undefined' : _typeof(fileList)) === 'object') {
    return recursiveFiles(url, fileList, param);
  } else {
    return photoUpload(url, fileList, param);
  }
};
export var modelRefresh = function modelRefresh(url, record, param) {
  var modelName = modelClassToModelName(record.model_class);
  return modelRefreshAPI(url, record.id, param).then(function (json) {
    var _normalize;

    var key2 = Object.keys(json)[0];
    var normalizedData = normalize(json, (_normalize = {}, _normalize[key2] = schemaModel[modelName], _normalize));
    normalizedDataInsertORM(normalizedData);
    var id = Object.values(json)[0].id;
    return session[modelName].withId(id);
  });
};
export var modelAssociationRefresh = function modelAssociationRefresh(url, associationName, record, param) {
  var associationUrl = associationNameToResourcesUrl(associationName);
  var associationModelName = resourcesUrlToModelName(associationUrl);
  var newUrl = url + '/' + record.id + '/' + associationName;
  return modelAssociationRefreshAPI(newUrl, param).then(function (json) {
    var key = Object.keys(json)[0];
    var normalizedData = void 0;

    try {
      var _normalize2;

      normalizedData = normalize(json, (_normalize2 = {}, _normalize2[key] = [schemaModel[associationModelName]], _normalize2));
    } catch (e) {
      console.log('failed to normalize: ', associationName, associationUrl, associationModelName);
    }

    normalizedDataInsertORM(normalizedData);
    var ids = Object.values(json).map(function (rec) {
      return rec.id;
    });
    return session[associationModelName].filter(function (rec) {
      return ids.includes(rec.id);
    });
  });
};
export var doubleModelAssociationRefresh = function doubleModelAssociationRefresh(url, associationName, record, param) {
  var associationUrl = associationNameToResourcesUrl(associationName);
  var associationModelName = resourcesUrlToModelName(associationUrl);
  var newUrl = url + '/' + record.id + '/' + associationName;
  return apiHelper.doubleAPIChecking(newUrl, param).then(function (resp) {
    return resp.particleList;
  });
};
export var modelCreate = function modelCreate(associationName, record, param) {
  var url = record.modelUrl + '/' + record.id + '/' + associationName;
  var key = pluralize(_.camelCase(associationName), 1); // guess lookup key based on associationName

  var associationUrl = associationNameToResourcesUrl(associationName);
  var modelName = resourcesUrlToModelName(associationUrl);
  return modelCreateAPI(url, param).then(function (json) {
    var key2 = Object.keys(json)[0];
    var normalizedData = void 0;

    try {
      var _normalize3;

      normalizedData = normalize(json, (_normalize3 = {}, _normalize3[key2] = schemaModel[modelName], _normalize3));
    } catch (e) {
      console.log('failed to normalize: ', associationName, associationUrl, modelName);
    }

    normalizedDataInsertORM(normalizedData);
    var id = Object.values(json)[0].id;
    return session[modelName].withId(id);
  });
};
export var modelSaving = function modelSaving(url, id, record) {
  return function () {
    var param = {};

    for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    var isDirectSave = args.length > 0 && _typeof(args[0]) === 'object';

    if (isDirectSave) {
      param = args[0];
    } else {
      param = organizeParam(record, args);
    }

    return modelSavingAPI(url, id, param).then(function (json) {
      if (isDirectSave) {
        record.update(param);
      }

      var modelName = modelClassToModelName(record.model_class);

      if ((typeof json === 'undefined' ? 'undefined' : _typeof(json)) === 'object') {
        var key2 = Object.keys(json)[0];
        var normalizedData = void 0;

        try {
          var _normalize4;

          normalizedData = normalize(json, (_normalize4 = {}, _normalize4[key2] = schemaModel[modelName], _normalize4));
        } catch (e) {
          console.log('failed to normalize: ', key2, modelName);
        }

        normalizedDataInsertORM(normalizedData);
      } else {// 204 empty string returned
      }

      return session[modelName].withId(id);
    });
  };
};
export var modelSavingChanges = function modelSavingChanges(url, id, record) {
  return function () {
    var params = {};

    for (var _len2 = arguments.length, args = Array(_len2), _key2 = 0; _key2 < _len2; _key2++) {
      args[_key2] = arguments[_key2];
    }

    var isDirectSave = args.length > 0 && _typeof(args[0]) === 'object';

    if (isDirectSave) {
      params = args[0];
    } else {
      if (args.length > 0) {
        params = organizeParam(record, args);
      } else {
        params = record.$_changes;
      }
    }

    params = Object.keys(params).filter(function (key) {
      return key in record.$_changes && record.$_changes[key] != record[key];
    }).reduce(function (sum, key) {
      sum[key] = record.$_changes[key];
      return sum;
    }, {});

    if (Object.keys(params).length === 0) {
      return Promise.resolve(record);
    } else {
      return modelSavingAPI(url, id, params).then(function (json) {
        if (!isDirectSave) {
          try {
            record.update(params);
          } catch (e) {}
        }

        return json;
      });
    }
  };
};
export var modelDelete = function modelDelete(url, id, record) {
  return function () {
    return modelDeleteAPI(url, id).then(function () {
      // to delete all inherited parents in orm
      var modelName = modelClassToModelName(record.model_class);
      var names = [modelName].concat(modelNameToPolymorphicNames(modelName));
      console.log('deleting in models: ', names);

      for (var _iterator3 = names, _isArray3 = Array.isArray(_iterator3), _i3 = 0, _iterator3 = _isArray3 ? _iterator3 : _iterator3[Symbol.iterator]();;) {
        var _ref3;

        if (_isArray3) {
          if (_i3 >= _iterator3.length) break;
          _ref3 = _iterator3[_i3++];
        } else {
          _i3 = _iterator3.next();
          if (_i3.done) break;
          _ref3 = _i3.value;
        }

        var name = _ref3;
        var clazz = session[name];

        if (clazz) {
          var rec = clazz.withId(id);

          if (rec) {
            rec.delete();
          }
        }
      }
    });
  };
};
/**
 * http GET particles(photos/cards/products/entities) of specified entities
 * @param  {Array} entities     specified entities
 * @param  {Project/Doc} host         the environment that particles live in
 * @param  {Object} [options={}] should be default to:
 * {fetchLinked: {
        Photo: "all", （available strategies: 'all', 'first-val-only', 'skip'）
        MemberCard: "all",
        CompanyCard: "all",
        Product: "all",
        Eav::Entity: "all"
      }
    }

 * @return {null}              [description]
 */

export var $fetchLinkedParticles = function $fetchLinkedParticles(entities, host) {
  var options = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
  options.fetchLinked = options.fetchLinked || {};
  options.params = options.params || {}; // get list of attrs

  var allAttrs = entities.reduce(function (arr, ett) {
    for (var _iterator4 = ett.structure.getAssociation('attrs'), _isArray4 = Array.isArray(_iterator4), _i4 = 0, _iterator4 = _isArray4 ? _iterator4 : _iterator4[Symbol.iterator]();;) {
      var _ref4;

      if (_isArray4) {
        if (_i4 >= _iterator4.length) break;
        _ref4 = _iterator4[_i4++];
      } else {
        _i4 = _iterator4.next();
        if (_i4.done) break;
        _ref4 = _i4.value;
      }

      var attr = _ref4;

      if (!arr.includes(attr)) {
        arr.push(attr);
      }
    }

    return arr;
  }, []); // list of types for reference only
  // types = ['Photo', 'MemberCard', 'CompanyCard', 'Product', 'Eav::Entity']

  var photoId_Array = [];
  var memberId_Array = [];
  var companyId_Array = [];
  var productId_Array = []; //modify this list if a new type is required

  var particleType_list = [{
    type: 'Photo',
    url: 'photos',
    id_array: photoId_Array
  }, {
    type: 'MemberCard',
    url: 'member_cards',
    id_array: memberId_Array
  }, {
    type: 'CompanyCard',
    url: 'company_cards',
    id_array: companyId_Array
  }, {
    type: 'Product',
    url: 'products',
    id_array: productId_Array
  }]; //loop the table

  allAttrs.filter(function (attr) {
    return attr.type_type === 'Eav::ParticleType';
  }).forEach(function (attr) {
    entities.forEach(function (entity) {
      var values = entity.getValues(attr);

      if (values.length > 0) {
        particleType_list.forEach(function (obj) {
          if (obj.type === values[0].content_particle_type) {
            if (options.fetchLinked[obj.type] === "all") {
              values.forEach(function (val) {
                return obj.id_array.push(val.content_particle_id);
              });
            } else {
              obj.id_array.push(values[0].content_particle_id);
            }
          }
        });
      }
    });
  });
  var roomAttrs = allAttrs.filter(function (attr) {
    return attr.type_type === 'Eav::Structure' && attr.type();
  });
  var typeStructureEntities = void 0;

  if (options.fetchLinked['Eav::Entity'] === 'all') {
    typeStructureEntities = roomAttrs.reduce(function (sum, attr) {
      var values = entities.map(function (ett) {
        return ett.getAssociation('values').filter(function (val) {
          return val.attr_id === attr.id;
        });
      }).flat();
      var ids = values.map(function (val) {
        return val.content_particle_id;
      });
      var existingRow = sum.find(function (row) {
        return row.structure.id === attr.type().id;
      });

      if (existingRow) {
        var _existingRow$entityId;

        (_existingRow$entityId = existingRow.entityIds).push.apply(_existingRow$entityId, ids);
      } else {
        sum.push({
          structure: attr.type(),
          entityIds: ids
        });
      }

      return sum;
    }, []);
  } else {
    typeStructureEntities = [];
  }

  return new Promise(function (resolve, reject) {
    var promises = [];
    particleType_list.forEach(function (obj) {
      if (obj.id_array.length > 0) {
        promises.push(apiHelper.doubleAPIChecking(obj.url, Object.assign({
          id: obj.id_array,
          project_id: host.id
        }, options.params), null));
      }
    });
    promises.push.apply(promises, typeStructureEntities.map(function (row) {
      return row.structure.$doubleRefreshAssociation('entities', {
        id: row.entityIds,
        specific: true
      });
    }));
    Promise.all(promises).then(function () {
      return resolve();
    });
  });
}; // export {organizeParam, normalizedDataInsertORM, modelSaving, modelDelete};