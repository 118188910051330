var _class, _temp2, _class2, _temp3, _class3, _temp4, _class4, _temp5, _class5, _temp6, _class6, _temp7, _class7, _temp8, _class8, _temp9, _class9, _temp10, _class10, _temp11, _class11, _temp12, _class12, _temp13, _class13, _temp14, _class14, _temp15, _class15, _temp16;

function _classCallCheck(instance, Constructor) {
  if (!(instance instanceof Constructor)) {
    throw new TypeError("Cannot call a class as a function");
  }
}

function _possibleConstructorReturn(self, call) {
  if (!self) {
    throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
  }

  return call && (typeof call === "object" || typeof call === "function") ? call : self;
}

function _inherits(subClass, superClass) {
  if (typeof superClass !== "function" && superClass !== null) {
    throw new TypeError("Super expression must either be null or a function, not " + typeof superClass);
  }

  subClass.prototype = Object.create(superClass && superClass.prototype, {
    constructor: {
      value: subClass,
      enumerable: false,
      writable: true,
      configurable: true
    }
  });
  if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass;
}

import { QurttyModel } from './modelDefault';
import { fk, many, oneToOne, attr, Model, ORM } from 'redux-orm';
import { session } from '../schemas';
var Version = (_temp2 = _class = function (_QurttyModel) {
  _inherits(Version, _QurttyModel);

  function Version() {
    var _temp, _this, _ret;

    _classCallCheck(this, Version);

    for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    return _ret = (_temp = (_this = _possibleConstructorReturn(this, _QurttyModel.call.apply(_QurttyModel, [this].concat(args))), _this), _this.modelUrl = 'paper_trail/versions', _temp), _possibleConstructorReturn(_this, _ret);
  }

  return Version;
}(QurttyModel), _class.modelName = 'Version', _class.fields = {}, _temp2);
var ΔPhotoΔVersion = (_temp3 = _class2 = function (_Version) {
  _inherits(ΔPhotoΔVersion, _Version);

  function ΔPhotoΔVersion() {
    _classCallCheck(this, ΔPhotoΔVersion);

    return _possibleConstructorReturn(this, _Version.apply(this, arguments));
  }

  return ΔPhotoΔVersion;
}(Version), _class2.modelName = 'ΔPhotoΔVersion', _class2.fields = {
  item_id: fk({
    to: 'Photo',
    as: 'item',
    relatedName: 'versions'
  })
}, _temp3);
var ΔMemberCardΔVersion = (_temp4 = _class3 = function (_Version2) {
  _inherits(ΔMemberCardΔVersion, _Version2);

  function ΔMemberCardΔVersion() {
    _classCallCheck(this, ΔMemberCardΔVersion);

    return _possibleConstructorReturn(this, _Version2.apply(this, arguments));
  }

  return ΔMemberCardΔVersion;
}(Version), _class3.modelName = 'ΔMemberCardΔVersion', _class3.fields = {
  item_id: fk({
    to: 'MemberCard',
    as: 'item',
    relatedName: 'versions'
  })
}, _temp4);
var ΔCompanyCardΔVersion = (_temp5 = _class4 = function (_Version3) {
  _inherits(ΔCompanyCardΔVersion, _Version3);

  function ΔCompanyCardΔVersion() {
    _classCallCheck(this, ΔCompanyCardΔVersion);

    return _possibleConstructorReturn(this, _Version3.apply(this, arguments));
  }

  return ΔCompanyCardΔVersion;
}(Version), _class4.modelName = 'ΔCompanyCardΔVersion', _class4.fields = {
  item_id: fk({
    to: 'CompanyCard',
    as: 'item',
    relatedName: 'versions'
  })
}, _temp5);
var ΔBusinessCardΔVersion = (_temp6 = _class5 = function (_Version4) {
  _inherits(ΔBusinessCardΔVersion, _Version4);

  function ΔBusinessCardΔVersion() {
    _classCallCheck(this, ΔBusinessCardΔVersion);

    return _possibleConstructorReturn(this, _Version4.apply(this, arguments));
  }

  return ΔBusinessCardΔVersion;
}(Version), _class5.modelName = 'ΔBusinessCardΔVersion', _class5.fields = {
  item_id: fk({
    to: 'BusinessCard',
    as: 'item',
    relatedName: 'versions'
  })
}, _temp6);
var ΔMembershipΔVersion = (_temp7 = _class6 = function (_Version5) {
  _inherits(ΔMembershipΔVersion, _Version5);

  function ΔMembershipΔVersion() {
    _classCallCheck(this, ΔMembershipΔVersion);

    return _possibleConstructorReturn(this, _Version5.apply(this, arguments));
  }

  return ΔMembershipΔVersion;
}(Version), _class6.modelName = 'ΔMembershipΔVersion', _class6.fields = {
  item_id: fk({
    to: 'Membership',
    as: 'item',
    relatedName: 'versions'
  })
}, _temp7);
var ΔProductΔVersion = (_temp8 = _class7 = function (_Version6) {
  _inherits(ΔProductΔVersion, _Version6);

  function ΔProductΔVersion() {
    _classCallCheck(this, ΔProductΔVersion);

    return _possibleConstructorReturn(this, _Version6.apply(this, arguments));
  }

  return ΔProductΔVersion;
}(Version), _class7.modelName = 'ΔProductΔVersion', _class7.fields = {
  item_id: fk({
    to: 'Product',
    as: 'item',
    relatedName: 'versions'
  })
}, _temp8);
var ΔWebsiteΔVersion = (_temp9 = _class8 = function (_Version7) {
  _inherits(ΔWebsiteΔVersion, _Version7);

  function ΔWebsiteΔVersion() {
    _classCallCheck(this, ΔWebsiteΔVersion);

    return _possibleConstructorReturn(this, _Version7.apply(this, arguments));
  }

  return ΔWebsiteΔVersion;
}(Version), _class8.modelName = 'ΔWebsiteΔVersion', _class8.fields = {
  item_id: fk({
    to: 'Website',
    as: 'item',
    relatedName: 'versions'
  })
}, _temp9);
var ΔPhoneΔVersion = (_temp10 = _class9 = function (_Version8) {
  _inherits(ΔPhoneΔVersion, _Version8);

  function ΔPhoneΔVersion() {
    _classCallCheck(this, ΔPhoneΔVersion);

    return _possibleConstructorReturn(this, _Version8.apply(this, arguments));
  }

  return ΔPhoneΔVersion;
}(Version), _class9.modelName = 'ΔPhoneΔVersion', _class9.fields = {
  item_id: fk({
    to: 'Phone',
    as: 'item',
    relatedName: 'versions'
  })
}, _temp10);
var ΔPhoneExposureΔVersion = (_temp11 = _class10 = function (_Version9) {
  _inherits(ΔPhoneExposureΔVersion, _Version9);

  function ΔPhoneExposureΔVersion() {
    _classCallCheck(this, ΔPhoneExposureΔVersion);

    return _possibleConstructorReturn(this, _Version9.apply(this, arguments));
  }

  return ΔPhoneExposureΔVersion;
}(Version), _class10.modelName = 'ΔPhoneExposureΔVersion', _class10.fields = {
  item_id: fk({
    to: 'PhoneExposure',
    as: 'item',
    relatedName: 'versions'
  })
}, _temp11);
var ΔAddressΔVersion = (_temp12 = _class11 = function (_Version10) {
  _inherits(ΔAddressΔVersion, _Version10);

  function ΔAddressΔVersion() {
    _classCallCheck(this, ΔAddressΔVersion);

    return _possibleConstructorReturn(this, _Version10.apply(this, arguments));
  }

  return ΔAddressΔVersion;
}(Version), _class11.modelName = 'ΔAddressΔVersion', _class11.fields = {
  item_id: fk({
    to: 'Address',
    as: 'item',
    relatedName: 'versions'
  })
}, _temp12);
var ΔAddressExposureΔVersion = (_temp13 = _class12 = function (_Version11) {
  _inherits(ΔAddressExposureΔVersion, _Version11);

  function ΔAddressExposureΔVersion() {
    _classCallCheck(this, ΔAddressExposureΔVersion);

    return _possibleConstructorReturn(this, _Version11.apply(this, arguments));
  }

  return ΔAddressExposureΔVersion;
}(Version), _class12.modelName = 'ΔAddressExposureΔVersion', _class12.fields = {
  item_id: fk({
    to: 'AddressExposure',
    as: 'item',
    relatedName: 'versions'
  })
}, _temp13);
var ΔEmailΔVersion = (_temp14 = _class13 = function (_Version12) {
  _inherits(ΔEmailΔVersion, _Version12);

  function ΔEmailΔVersion() {
    _classCallCheck(this, ΔEmailΔVersion);

    return _possibleConstructorReturn(this, _Version12.apply(this, arguments));
  }

  return ΔEmailΔVersion;
}(Version), _class13.modelName = 'ΔEmailΔVersion', _class13.fields = {
  item_id: fk({
    to: 'Email',
    as: 'item',
    relatedName: 'versions'
  })
}, _temp14);
var ΔEmailExposureΔVersion = (_temp15 = _class14 = function (_Version13) {
  _inherits(ΔEmailExposureΔVersion, _Version13);

  function ΔEmailExposureΔVersion() {
    _classCallCheck(this, ΔEmailExposureΔVersion);

    return _possibleConstructorReturn(this, _Version13.apply(this, arguments));
  }

  return ΔEmailExposureΔVersion;
}(Version), _class14.modelName = 'ΔEmailExposureΔVersion', _class14.fields = {
  item_id: fk({
    to: 'EmailExposure',
    as: 'item',
    relatedName: 'versions'
  })
}, _temp15);
var ΔEav_ValueΔVersion = (_temp16 = _class15 = function (_Version14) {
  _inherits(ΔEav_ValueΔVersion, _Version14);

  function ΔEav_ValueΔVersion() {
    _classCallCheck(this, ΔEav_ValueΔVersion);

    return _possibleConstructorReturn(this, _Version14.apply(this, arguments));
  }

  return ΔEav_ValueΔVersion;
}(Version), _class15.modelName = 'ΔEav_ValueΔVersion', _class15.fields = {
  item_id: fk({
    to: 'Eav_Value',
    as: 'item',
    relatedName: 'versions'
  })
}, _temp16);
export var versionModelRegister = function versionModelRegister(orm) {
  orm.register(Version, ΔPhotoΔVersion, ΔMemberCardΔVersion, ΔCompanyCardΔVersion, ΔBusinessCardΔVersion, ΔProductΔVersion, ΔWebsiteΔVersion, ΔPhoneΔVersion, ΔPhoneExposureΔVersion, ΔAddressΔVersion, ΔAddressExposureΔVersion, ΔEmailΔVersion, ΔEmailExposureΔVersion, ΔEav_ValueΔVersion);
};