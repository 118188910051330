var _class, _temp;

function _classCallCheck(instance, Constructor) {
  if (!(instance instanceof Constructor)) {
    throw new TypeError("Cannot call a class as a function");
  }
}

function _possibleConstructorReturn(self, call) {
  if (!self) {
    throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
  }

  return call && (typeof call === "object" || typeof call === "function") ? call : self;
}

function _inherits(subClass, superClass) {
  if (typeof superClass !== "function" && superClass !== null) {
    throw new TypeError("Super expression must either be null or a function, not " + typeof superClass);
  }

  subClass.prototype = Object.create(superClass && superClass.prototype, {
    constructor: {
      value: subClass,
      enumerable: false,
      writable: true,
      configurable: true
    }
  });
  if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass;
}

import { QurttyModel } from './modelDefault';
import { fk, many, oneToOne, attr, Model, ORM } from 'redux-orm';
var RegistrationInvitation = (_temp = _class = function (_QurttyModel) {
  _inherits(RegistrationInvitation, _QurttyModel);

  function RegistrationInvitation() {
    _classCallCheck(this, RegistrationInvitation);

    return _possibleConstructorReturn(this, _QurttyModel.apply(this, arguments));
  }

  return RegistrationInvitation;
}(QurttyModel), _class.modelName = 'RegistrationInvitation', _class.fields = {
  inviter_id: fk({
    to: 'Member',
    as: 'inviter'
  }),
  invitee_id: fk({
    to: 'Member',
    as: 'invitee',
    relatedName: 'received_registration_invitations'
  }),
  invitee_card_email_id: fk({
    to: 'Email',
    as: 'invitee_card_email',
    relatedName: 'received_registration_invitations'
  })
}, _temp);
export var invitationModelRegister = function invitationModelRegister(orm) {
  orm.register(RegistrationInvitation);
};