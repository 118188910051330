import { combineReducers, createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';

import dataReducer from './dataReducer';
import uiReducer from './uiReducer';


const rootReducer = combineReducers({
  dataReducer: dataReducer,
  uiReducer: uiReducer
})

const initialState = {};

const logger = store => next => action => {
  console.log('dispatching', action);
  let result = next(action);
  console.log('next state', store.getState());
  return result;
};

const store = createStore(rootReducer, initialState, applyMiddleware(thunk, logger));

export default store;
