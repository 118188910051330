var _class, _temp, _class2, _temp2;

function _classCallCheck(instance, Constructor) {
  if (!(instance instanceof Constructor)) {
    throw new TypeError("Cannot call a class as a function");
  }
}

function _possibleConstructorReturn(self, call) {
  if (!self) {
    throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
  }

  return call && (typeof call === "object" || typeof call === "function") ? call : self;
}

function _inherits(subClass, superClass) {
  if (typeof superClass !== "function" && superClass !== null) {
    throw new TypeError("Super expression must either be null or a function, not " + typeof superClass);
  }

  subClass.prototype = Object.create(superClass && superClass.prototype, {
    constructor: {
      value: subClass,
      enumerable: false,
      writable: true,
      configurable: true
    }
  });
  if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass;
}

import { QurttyModel } from './modelDefault';
import { fk, many, oneToOne, attr } from 'redux-orm';
var Member = (_temp = _class = function (_QurttyModel) {
  _inherits(Member, _QurttyModel);

  function Member() {
    _classCallCheck(this, Member);

    return _possibleConstructorReturn(this, _QurttyModel.apply(this, arguments));
  }

  Member.prototype.getPhotoUrl = function getPhotoUrl(urlName) {
    var category = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'card_profile';
    var options = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
    var card = this.getAss('hold_member_cards')[0];

    if (card) {
      return card.getPhotoUrl(urlName, category, options);
    }
  };

  return Member;
}(QurttyModel), _class.modelName = 'Member', _class.fields = {
  profile_photo: fk({
    to: 'Photo',
    as: 'profile_photo',
    relatedName: 'member_profile_photo'
  }),
  employment_id: fk({
    to: 'Employment',
    as: '_employment',
    relatedName: 'employee_account'
  })
}, _temp);
var MemberName = (_temp2 = _class2 = function (_QurttyModel2) {
  _inherits(MemberName, _QurttyModel2);

  function MemberName() {
    _classCallCheck(this, MemberName);

    return _possibleConstructorReturn(this, _QurttyModel2.apply(this, arguments));
  }

  return MemberName;
}(QurttyModel), _class2.modelName = 'MemberName', _class2.fields = {
  member_id: fk({
    to: 'Member',
    as: 'member',
    relatedName: 'member_names'
  })
}, _temp2);
export var memberModelRegister = function memberModelRegister(orm) {
  orm.register(Member, MemberName);
};