var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) {
  return typeof obj;
} : function (obj) {
  return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
};

var _class, _temp, _class2, _temp2, _class3, _temp3, _class4, _temp4, _class5, _temp5, _class6, _temp6, _class7, _temp7, _class8, _temp8, _class9, _temp9, _class10, _temp10, _class11, _temp12, _class12, _temp13, _class13, _temp14, _class14, _temp15, _class15, _temp16, _class16, _temp17;

function _classCallCheck(instance, Constructor) {
  if (!(instance instanceof Constructor)) {
    throw new TypeError("Cannot call a class as a function");
  }
}

function _possibleConstructorReturn(self, call) {
  if (!self) {
    throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
  }

  return call && (typeof call === "object" || typeof call === "function") ? call : self;
}

function _inherits(subClass, superClass) {
  if (typeof superClass !== "function" && superClass !== null) {
    throw new TypeError("Super expression must either be null or a function, not " + typeof superClass);
  }

  subClass.prototype = Object.create(superClass && superClass.prototype, {
    constructor: {
      value: subClass,
      enumerable: false,
      writable: true,
      configurable: true
    }
  });
  if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass;
}

import { QurttyModel } from './modelDefault';
import * as apiHelper from '../../api/apiHelper';
import { fk, many, oneToOne, attr, Model, ORM } from 'redux-orm';
import { session } from '../schemas';
import _ from 'lodash';
var Project = (_temp = _class = function (_QurttyModel) {
  _inherits(Project, _QurttyModel);

  function Project() {
    _classCallCheck(this, Project);

    return _possibleConstructorReturn(this, _QurttyModel.apply(this, arguments));
  }

  return Project;
}(QurttyModel), _class.modelName = 'Project', _class.fields = {
  structures: many('Eav_Structure'),
  website_control: oneToOne('WebsiteControl'),
  cover_id: fk({
    to: 'Photo',
    as: '_cover',
    relatedName: 'covered_projects'
  }),
  member_id: fk({
    to: 'Member',
    as: '_member',
    relatedName: 'projects'
  }),
  photo_extension_structure: oneToOne('Eav_Structure', 'photo_extented_project'),
  member_card_extension_structure: oneToOne('Eav_Structure', 'member_card_extented_project'),
  company_card_extension_structure: oneToOne('Eav_Structure', 'company_card_extented_project'),
  product_extension_structure: oneToOne('Eav_Structure', 'product_extented_project')
}, _temp);
var ProjectCheckpoint = (_temp2 = _class2 = function (_QurttyModel2) {
  _inherits(ProjectCheckpoint, _QurttyModel2);

  function ProjectCheckpoint() {
    _classCallCheck(this, ProjectCheckpoint);

    return _possibleConstructorReturn(this, _QurttyModel2.apply(this, arguments));
  }

  return ProjectCheckpoint;
}(QurttyModel), _class2.modelName = 'ProjectCheckpoint', _class2.fields = {
  project_id: fk({
    to: 'Project',
    as: '_project',
    relatedName: 'project_checkpoints'
  })
}, _temp2);
var Doc = (_temp3 = _class3 = function (_QurttyModel3) {
  _inherits(Doc, _QurttyModel3);

  function Doc() {
    _classCallCheck(this, Doc);

    return _possibleConstructorReturn(this, _QurttyModel3.apply(this, arguments));
  }

  return Doc;
}(QurttyModel), _class3.modelName = 'Doc', _class3.fields = {
  structure: oneToOne('Eav_Structure'),
  cover_id: fk({
    to: 'Photo',
    as: '_cover',
    relatedName: 'covered_docs'
  }),
  member_id: fk({
    to: 'Member',
    as: '_member',
    relatedName: 'docs'
  }),
  photo_extension_structure: oneToOne('Eav_Structure', 'photo_extented_doc'),
  member_card_extension_structure: oneToOne('Eav_Structure', 'member_card_extented_doc'),
  company_card_extension_structure: oneToOne('Eav_Structure', 'company_card_extented_doc'),
  product_extension_structure: oneToOne('Eav_Structure', 'product_extented_doc')
}, _temp3);
var WebsiteControl = (_temp4 = _class4 = function (_QurttyModel4) {
  _inherits(WebsiteControl, _QurttyModel4);

  function WebsiteControl() {
    _classCallCheck(this, WebsiteControl);

    return _possibleConstructorReturn(this, _QurttyModel4.apply(this, arguments));
  }

  return WebsiteControl;
}(QurttyModel), _class4.modelName = 'WebsiteControl', _class4.fields = {// project_id: fk({ to: 'Project', as: 'project', relatedName: 'website_control' })
}, _temp4);
var Webpage = (_temp5 = _class5 = function (_QurttyModel5) {
  _inherits(Webpage, _QurttyModel5);

  function Webpage() {
    _classCallCheck(this, Webpage);

    return _possibleConstructorReturn(this, _QurttyModel5.apply(this, arguments));
  }

  return Webpage;
}(QurttyModel), _class5.modelName = 'Webpage', _class5.fields = {
  website_control_id: fk({
    to: 'WebsiteControl',
    as: 'website_control',
    relatedName: 'webpages'
  })
}, _temp5);
var WebpageSection = (_temp6 = _class6 = function (_QurttyModel6) {
  _inherits(WebpageSection, _QurttyModel6);

  function WebpageSection() {
    _classCallCheck(this, WebpageSection);

    return _possibleConstructorReturn(this, _QurttyModel6.apply(this, arguments));
  }

  return WebpageSection;
}(QurttyModel), _class6.modelName = 'WebpageSection', _class6.fields = {
  webpage_id: fk({
    to: 'Webpage',
    as: 'webpage',
    relatedName: 'webpage_sections'
  }),
  structure_id: fk({
    to: 'Eav_Structure',
    as: 'structure',
    relatedName: 'webpage_sections'
  })
}, _temp6);
var WebpageSectionAttr = (_temp7 = _class7 = function (_QurttyModel7) {
  _inherits(WebpageSectionAttr, _QurttyModel7);

  function WebpageSectionAttr() {
    _classCallCheck(this, WebpageSectionAttr);

    return _possibleConstructorReturn(this, _QurttyModel7.apply(this, arguments));
  }

  return WebpageSectionAttr;
}(QurttyModel), _class7.modelName = 'WebpageSectionAttr', _class7.fields = {
  webpage_section_id: fk({
    to: 'WebpageSection',
    as: 'webpage_section',
    relatedName: 'webpage_section_attrs'
  }),
  attr_id: fk({
    to: 'Eav_Attr',
    as: 'attr',
    relatedName: 'webpage_section_attrs'
  })
}, _temp7);
var Eav_AttrType = (_temp8 = _class8 = function (_QurttyModel8) {
  _inherits(Eav_AttrType, _QurttyModel8);

  function Eav_AttrType() {
    _classCallCheck(this, Eav_AttrType);

    return _possibleConstructorReturn(this, _QurttyModel8.apply(this, arguments));
  }

  return Eav_AttrType;
}(QurttyModel), _class8.modelName = 'Eav_AttrType', _class8.fields = {}, _temp8);
var Eav_ParticleType = (_temp9 = _class9 = function (_QurttyModel9) {
  _inherits(Eav_ParticleType, _QurttyModel9);

  function Eav_ParticleType() {
    _classCallCheck(this, Eav_ParticleType);

    return _possibleConstructorReturn(this, _QurttyModel9.apply(this, arguments));
  }

  return Eav_ParticleType;
}(QurttyModel), _class9.modelName = 'Eav_ParticleType', _class9.fields = {}, _temp9); // Eav_Structure

var Eav_Structure = (_temp10 = _class10 = function (_QurttyModel10) {
  _inherits(Eav_Structure, _QurttyModel10);

  function Eav_Structure() {
    _classCallCheck(this, Eav_Structure);

    return _possibleConstructorReturn(this, _QurttyModel10.apply(this, arguments));
  }

  Eav_Structure.prototype.host = function host() {
    return session[this.host_type].withId(this.host_id);
  };

  Eav_Structure.prototype.entitiesOfParent = function entitiesOfParent(parentEntityId, roomAttrId) {
    return this.getAss('entities').filter(function (ett) {
      var parentValues = session.Eav_Value.filter(function (val) {
        return val.content_particle_type === 'Eav::Entity' && val.content_particle_id === ett.id;
      }).toModelArray();
      return parentValues.some(function (val) {
        return val.entity_id === parentEntityId && val.attr_id === roomAttrId;
      });
    });
  };

  return Eav_Structure;
}(QurttyModel), _class10.modelName = 'Eav_Structure', _class10.fields = {}, _temp10);
var Eav_Entity = (_temp12 = _class11 = function (_QurttyModel11) {
  _inherits(Eav_Entity, _QurttyModel11);

  function Eav_Entity() {
    var _temp11, _this11, _ret;

    _classCallCheck(this, Eav_Entity);

    for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    return _ret = (_temp11 = (_this11 = _possibleConstructorReturn(this, _QurttyModel11.call.apply(_QurttyModel11, [this].concat(args))), _this11), _this11.$createLinkedValue = function (_ref) {
      var attr_id = _ref.attr_id,
          particle = _ref.particle;
      return _this11.$createAssociation('values', {
        attr_id: attr_id,
        content_particle_id: particle.id,
        content_particle_type: particle.model_class
      });
    }, _this11.$destroyLinkedValue = function (_ref2) {
      var attr_id = _ref2.attr_id,
          particle = _ref2.particle;

      var value = _this11.getAss('values').find(function (val) {
        return val.attr_id === attr_id && val.content_particle_type === particle.model_class && val.content_particle_id === particle.id;
      });

      if (value) {
        return value.$destroy();
      } else {
        return Promise.resolve();
      }
    }, _temp11), _possibleConstructorReturn(_this11, _ret);
  }
  /**
   * get an attr by index/key
   * @param  {Number/String/QurttyModel} arg attr index/key of the attr, or attr itself
   * @return {QurttyModel}     the attr
   */


  Eav_Entity.prototype.parseAttr = function parseAttr(arg) {
    var attr = void 0;

    if ((typeof arg === 'undefined' ? 'undefined' : _typeof(arg)) === 'object') {
      attr = arg;
    } else {
      var structure = this.structure;

      if (!structure) {
        structure = session.Eav_Structure.withId(this.structure_id);
      }

      var attrs = structure.getAssociation('attrs');

      if (typeof arg === 'number') {
        attr = attrs[arg];
      } else if (typeof arg === 'string') {
        attr = attrs.find(function (atr) {
          return atr.key === arg;
        });
      }
    }

    return attr;
  };
  /**
   * get the entity's values of an attr
   * @param  {Number/String/QurttyModel} attr index/key of the attr, or attr itself
   * @return {Array}      of values
   */


  Eav_Entity.prototype.getValues = function getValues(arg) {
    // If attr is attr.id
    // If attr is not number, then assume attr is an object
    var attr = this.parseAttr(arg);

    if (!attr) {
      return null;
    }

    var attrId = attr.id;
    return this.values.filter(function (val) {
      return val.attr_id === attrId;
    }).orderBy('display_order', 'asc').toModelArray();
  };

  Eav_Entity.prototype.getValue = function getValue(arg) {
    return this.getValues(arg)[0];
  };

  Eav_Entity.prototype.getValueOrValues = function getValueOrValues(arg) {
    var attr = this.parseAttr(arg);

    if (!attr) {
      return null;
    }

    if (attr.has_multiple_values) {
      return this.getValues(attr);
    } else {
      return this.getValue(attr);
    }
  };

  Eav_Entity.prototype.coverText = function coverText() {
    var locale = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 'en';
    var value = this.getAss('values')[0];
    return value ? value.coverText(locale) : '';
  };

  return Eav_Entity;
}(QurttyModel), _class11.modelName = 'Eav_Entity', _class11.fields = {
  structure_id: fk({
    to: 'Eav_Structure',
    as: 'structure',
    relatedName: 'entities'
  })
}, _temp12);
var Eav_Attr = (_temp13 = _class12 = function (_QurttyModel12) {
  _inherits(Eav_Attr, _QurttyModel12);

  function Eav_Attr() {
    _classCallCheck(this, Eav_Attr);

    return _possibleConstructorReturn(this, _QurttyModel12.apply(this, arguments));
  }

  Eav_Attr.prototype.type = function type() {
    return session[this.type_type.replace('::', '_')].withId(this.type_id);
  };

  Eav_Attr.prototype.typeName = function typeName() {
    var thisType = this.type();

    if (thisType) {
      if (thisType.model_class === 'Eav::ParticleType') {
        return thisType.class_name;
      } else if (thisType.model_class === 'Eav::AttrType') {
        return thisType.name;
      } else {
        return 'Eav::Entity';
      }
    } else {
      return null;
    }
  };

  return Eav_Attr;
}(QurttyModel), _class12.modelName = 'Eav_Attr', _class12.fields = {
  structure_id: fk({
    to: 'Eav_Structure',
    as: 'structure',
    relatedName: 'attrs'
  }),
  type_structure: oneToOne('Eav_Structure')
}, _temp13);
var Eav_Value = (_temp14 = _class13 = function (_QurttyModel13) {
  _inherits(Eav_Value, _QurttyModel13);

  function Eav_Value() {
    _classCallCheck(this, Eav_Value);

    return _possibleConstructorReturn(this, _QurttyModel13.apply(this, arguments));
  }
  /**
   * get the photo/card/product inside the value
   * @return {Photo/Card/Product} [description]
   */


  Eav_Value.prototype.content_particle = function content_particle() {
    if (this.content_particle_type) {
      var modelName = this.content_particle_type.replace('::', '_');

      if (!session[modelName]) {
        console.log('wah!!', modelName);
      }

      return session[modelName].withId(this.content_particle_id);
    }
  };

  Eav_Value.prototype.content = function content() {
    var locale = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 'en';

    switch (this.attr.typeName()) {
      // attr.type_type ===  'Eav::AttrType'
      case 'date':
        return this.content_date;
        break;

      case 'number':
        return this.content_number;
        break;

      case 'time':
        return this.content_time;
        break;

      case 'string':
        return this['content_string_' + locale];
        break;

      case 'text':
        return this['content_text_' + locale];
        break;

      case 'html':
        return this['content_html_' + locale];
        break;

      case 'json':
        var content_json = this['content_json_' + locale];
        return _(content_json).get('[0].content');
        break;
      // attr.type_type === 'Eav::ParticleType'

      case 'Photo':
      case 'MemberCard':
      case 'CompanyCard':
      case 'Product':
      case 'Eav::Invitation':
        return this.content_particle();
        break;

      case 'Eav::Entity':
        return this.content_particle();
        break;

      default:
        return null;
    }
  };

  Eav_Value.prototype.coverText = function coverText() {
    var locale = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 'en';

    switch (this.attr.typeName()) {
      // attr.type_type ===  'Eav::AttrType'
      case 'date':
      case 'number':
      case 'time':
      case 'string':
      case 'text':
      case 'html':
      case 'json':
        return this.content(locale);
      // attr.type_type === 'Eav::ParticleType'

      case 'MemberCard':
        {
          var particle = this.content_particle();
          return particle ? particle.printName(locale) : '';
        }

      case 'Photo':
        {
          var _particle = this.content_particle();

          return _particle ? _particle.name : '';
        }

      case 'Eav::Invitation':
        return '';

      case 'Eav::Entity':
        {
          var _particle2 = this.content_particle();

          return _particle2 ? _particle2.coverText(locale) : '';
        }

      default:
        return '';
    }
  };

  return Eav_Value;
}(QurttyModel), _class13.modelName = 'Eav_Value', _class13.fields = {
  entity_id: fk({
    to: 'Eav_Entity',
    as: 'entity',
    relatedName: 'values'
  }),
  attr_id: fk({
    to: 'Eav_Attr',
    as: 'attr',
    relatedName: 'values'
  })
}, _temp14);
var Eav_Invitation = (_temp15 = _class14 = function (_QurttyModel14) {
  _inherits(Eav_Invitation, _QurttyModel14);

  function Eav_Invitation() {
    _classCallCheck(this, Eav_Invitation);

    return _possibleConstructorReturn(this, _QurttyModel14.apply(this, arguments));
  }

  return Eav_Invitation;
}(QurttyModel), _class14.modelName = 'Eav_Invitation', _class14.fields = {}, _temp15);
var Eav_EntityPermission = (_temp16 = _class15 = function (_QurttyModel15) {
  _inherits(Eav_EntityPermission, _QurttyModel15);

  function Eav_EntityPermission() {
    _classCallCheck(this, Eav_EntityPermission);

    return _possibleConstructorReturn(this, _QurttyModel15.apply(this, arguments));
  }

  return Eav_EntityPermission;
}(QurttyModel), _class15.modelName = 'Eav_EntityPermission', _class15.fields = {
  entity_id: fk({
    to: 'Eav_Entity',
    as: 'entity',
    relatedName: 'entity_permissions'
  }),
  member_card_id: fk({
    to: 'MemberCard',
    as: '_member_card',
    relatedName: 'entity_permissions'
  })
}, _temp16);
var Eav_EntityComment = (_temp17 = _class16 = function (_QurttyModel16) {
  _inherits(Eav_EntityComment, _QurttyModel16);

  function Eav_EntityComment() {
    _classCallCheck(this, Eav_EntityComment);

    return _possibleConstructorReturn(this, _QurttyModel16.apply(this, arguments));
  }

  return Eav_EntityComment;
}(QurttyModel), _class16.modelName = 'Eav_EntityComment', _class16.fields = {
  member_card: oneToOne('MemberCard'),
  entity_id: fk({
    to: 'Eav_Entity',
    as: '_entity',
    relatedName: 'entity_comments'
  }),
  member_id: fk({
    to: 'Member',
    as: '_member',
    relatedName: 'entity_comments'
  })
}, _temp17);
export var projectModelRegister = function projectModelRegister(orm) {
  orm.register(Project, ProjectCheckpoint, Doc, WebsiteControl, Webpage, WebpageSection, WebpageSectionAttr, Eav_AttrType, Eav_ParticleType, Eav_Structure, Eav_Entity, Eav_Attr, Eav_Value, Eav_Invitation, Eav_EntityPermission, Eav_EntityComment);
};