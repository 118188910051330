import React, {Component} from 'react';
import {connect} from 'react-redux';
// import { } from '../../actions/ActionTypes';
// import Categories from './categories/Categories'
// import Phones from './phones/Phones'
import Colorbar from '../../components/colorbar/Colorbar';
// import BG from '../../assets/photo/productbox-bg.jpg';
import ProductCard from './productCard/ProductCard';
import Phone from '../../assets/materials/iphone-x.jpeg';
import Tablet from '../../assets/materials/tablet.jpg';
import Accessory from '../../assets/materials/vr.png';

class Products extends Component {
  constructor(props) {
    super(props);
    this.state = {
    }
  }

  render() {
    // console.log(this.props.data);
    const data = this.props.data;
    const heading = data[`heading_${this.props.language}`];
    const subheading = data[`subheading_${this.props.language}`]; const subsubheading = data[`subsubheading_${this.props.language}`]
    let imgSrc = this.props.bkgData.rows[0][0].values[0] ? this.props.bkgData.rows[0][0].values[0].resized_m_url : null

    if (this.props.data.is_hidden) {
      return (<div></div>)
    } else {
      return (<div id="Products" style={{backgroundImage: `url(${imgSrc})`}}>
      <div className="py-4 px-3 py-md-5 px-md-5 d-flex flex-column align-items-center">
        <h6 className="session font-weight-normal text-white">{heading}</h6>
        <h2 className="title text-white">
          {subheading}
        </h2>
        <Colorbar isWhite={true}/>
        <div className="subsubheading font-12 mt-3 text-white"> {subsubheading} </div>

        <div className="card-container pt-4 pt-md-5 py-md-3 d-flex flex-row row justify-content-center">
          {this.props.data.rows.map((item, index) => <ProductCard key={index} item={item}/>)}
        </div>
      </div>
    </div>)
    }

  }
}

const mapStateToProps = (state) => {
  return {
    language: state.uiReducer.language,
    data: state.dataReducer.dataSet.products,
    bkgData: state.dataReducer.dataSet.productBkg
  };
};

const dispatchToProps = {}

export default connect(mapStateToProps, dispatchToProps)(Products);
