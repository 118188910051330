import React, {Component} from 'react';
import {connect} from 'react-redux';
import { } from '../../actions/ActionTypes';
import Colorbar from '../../components/colorbar/Colorbar';
import photo1 from '../../assets/photo/grading-photo-1.jpg'
import grade1 from '../../assets/materials/grade1.jpg';
import Slider from "react-slick";
import {
  Carousel,
  CarouselItem,
  CarouselControl,
  CarouselIndicators,
  CarouselCaption
} from 'reactstrap';

class Grading extends Component {
  constructor(props){
    super(props);
    this.state = {
      activeIndex: 0,
      activeSilde: 0
    }
    this.next = this.next.bind(this);
    this.previous = this.previous.bind(this);
    this.goToIndex = this.goToIndex.bind(this);
    this.onExiting = this.onExiting.bind(this);
    this.onExited = this.onExited.bind(this);
  }

  indexChanged(index){
    this.setState({
      activeIndex: index
    })
  }

  onExiting() {
    this.animating = true;
  }

  onExited() {
    this.animating = false;
  }

  next(items) {
    if (this.animating) return;
    const nextIndex = this.state.activeSilde === items.length - 1 ? 0 : this.state.activeSilde + 1;
    this.setState({ activeSilde: nextIndex });
  }

  previous(items) {
    if (this.animating) return;
    const prevIndex = this.state.activeSilde === 0 ? items.length - 1 : this.state.activeSilde - 1;
    this.setState({ activeSilde: prevIndex });
  }

  goToIndex(newIndex) {
    if (this.animating) return;
    this.setState({ activeSilde: newIndex });
  }

  render() {
    const { activeIndex, activeSilde } = this.state;


    // console.log(this.props.data);
    const data = this.props.data;
    const heading = data[`heading_${this.props.language}`];
    const subheading = data[`subheading_${this.props.language}`]; const subsubheading = data[`subsubheading_${this.props.language}`]

    const grades = data.rows;

    const items = grades[this.state.activeIndex][3].values.map( item => {
      return {
        src: item ? item.resized_s_url : null,
        altText: '',
        caption: ''}
    })

    const slides = items.map((item, index) => {
      return (
        <CarouselItem
          onExiting={this.onExiting}
          onExited={this.onExited}
          key={index}
        >
          <img className="backgroundImg" src={item.src} alt={item.altText} />

          <CarouselCaption captionText={item.caption} captionHeader={item.caption} />
        </CarouselItem>
      );
    });

    var settings = {
      // dots: true,
      // slidesToShow: window.matchMedia("(min-width: 768px)").matches ? 2 : 1,
      infinite: true,
      autoplay: false,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplaySpeed: 10000,
      // centerMode: true,
    };

    let imgSrc = grades[this.state.activeIndex][2].values[0] ? grades[this.state.activeIndex][2].values[0].resized_s_url : null

    if (this.props.data.is_hidden) {
      return (<div></div>)
    } else {
      return (
        <div id="Grading" >
          <div className="py-4 px-3 py-md-5 px-md-5 d-flex flex-column align-items-center">
            <h6 className="session font-weight-normal">{heading}</h6>
            <h2 className="title"> {subheading} </h2>
            <Colorbar/>
            <div className="subsubheading font-12 mt-3"> {subsubheading} </div>

            <div className="content-container w-100 pt-4 pt-md-5 pb-md-4 column d-flex flex-column align-items-center justify-content-center" >
              <div className="content-inner-container d-flex flex-column flex-md-row w-100 justify-content-center">

                <div className="left-container d-flex flex-column">

                  <div className="tab-container mb-3">
                    {grades.map( (item, index) =>
                      <div key={index} className={`tab mx-1 px-2 ${this.state.activeIndex === index ? 'active' : ''}`}
                        onClick={() => {
                          console.log('change to grade: ', index);
                          this.setState({ activeIndex: index })
                        }}>
                        {item[0].values[0][`content_string_${this.props.language}`]}
                      </div>
                    )}
                  </div>

                  <div className="outline-photo">
                    <img src={imgSrc} />
                  </div>
                </div>

                <div className="right-container d-flex flex-column mt-4 mt-md-0">

                  <div className="description mb-3">
                    {grades[this.state.activeIndex][1].values[0][`content_string_${this.props.language}`]}
                  </div>

                  <div className="container mb-4">

                    {/*
                      <Slider {...settings} ref={slider => (this.slider = slider)} >
                      {grades[this.state.activeIndex][3].values.map( (item, index) =>
                      <div key={index}>
                      <img src={item ? item.resized_s_url : null}/>
                      </div>
                      )}
                      </Slider>
                      */}

                      <Carousel
                        className="single-slide mb-4"
                        interval="10000"
                        activeIndex={activeSilde}
                        next={()=>this.next(items)}
                        previous={()=>this.previous(items)}
                        >
                        <CarouselIndicators items={items} activeIndex={activeSilde} onClickHandler={this.goToIndex} />
                        {slides}
                        <CarouselControl direction="prev" directionText="Previous" onClickHandler={()=>this.previous(items)} />
                        <CarouselControl direction="next" directionText="Next" onClickHandler={()=>this.next(items)} />
                      </Carousel>

                    </div>
                  </div>
                </div>

              </div>

            </div>
          </div>
        )
    }

  }
}

const mapStateToProps = (state) => {
  return {
    language: state.uiReducer.language, data: state.dataReducer.dataSet.grading
  };
};

const dispatchToProps = {
}

export default connect(mapStateToProps, dispatchToProps)(Grading);
