import React, {Component} from 'react';
import {connect} from 'react-redux';
import { TOGGLE_POPUP, TOGGLE_CATEGORY } from '../../../actions/ActionTypes';
import phone from '../../../assets/materials/iphone-x.jpeg';
import ProductPopup from '../productPopup/ProductPopup';

class Card extends Component {
  render(){
    return (
      <div className="card column" onClick={()=>{
        this.props.togglePopup(true, this.props.index)
        document.body.style.overflow = 'hidden'
      }}>
        <img className="" src={this.props.item.img}/>
        <div className="">
           <div className="card-brand"> {this.props.brand} </div>
           <div className="card-model"> {this.props.item.name} </div>
        </div>
      </div>
    )
  }
}

class Phones extends Component {
  constructor(props){
    super(props);
    this.state={
      keyword: '',
      limitNumber: 30
    }
  }

  render() {
    let { keyword } = this.state; //search keyword
    let { current_brand, isPopup, togglePopup, current_category } = this.props;
    let products = [];

    if (current_brand && current_brand.products) { // check if exist
      if (keyword.length > 0) { // check if searching
        console.log('filter: ', this.state.keyword );

        if(current_category !== 'All') { // not All
          products = current_brand.products.filter( item => (current_brand.name.toLowerCase().includes(keyword.toLowerCase()) || item.name.toLowerCase().includes(keyword.toLowerCase())));
        } else { // All
          products = current_brand.products.filter( item => (item.brand.toLowerCase().includes(keyword.toLowerCase()) || item.name.toLowerCase().includes(keyword.toLowerCase())));
        }
      } else {
        products = current_brand.products;
      }
    }

    return (
      <div id="Phones" className="col-md-9">
        <div className="header">
          <div className="sideBtnSpace">
            <div className="icon-category mobileSideBtn"
              onClick={()=>{this.props.toggleCategory(); this.props.toggleBackDrop()}}
            />
          </div>
          <input className="search" type="text" id="model" name="model" placeholder={this.props.language === 'zh_hk' ? '搜尋' : 'Search'}
            onChange={(e)=>this.setState({keyword: e.target.value})}
          />
        </div>

        <div className="table">

          { current_category === 'All' && products && products.map((item, index) => index < this.state.limitNumber ?
            <Card key={index} brand={item.brand} item={item} index={index} togglePopup={togglePopup}/> : null
          )}

          { current_category !== 'All' && products && products.map((item, index) => index < this.state.limitNumber ?
            <Card key={index} brand={current_brand.name} item={item} index={index} togglePopup={togglePopup}/> : null
          )}

          { products.length === 0 && <div className="prompt"> {this.props.language === 'zh_hk' ? '沒有結果' : 'no result.'}  </div>}

        </div>

          { current_category === 'All' && products && products.length > this.state.limitNumber &&
          <div className="load-more" onClick={() => {
            this.setState({limitNumber: this.state.limitNumber + 30})
          }}> {this.props.language === 'zh_hk' ? '顯示更多產品' : 'LOAD MORE PRODUCTS'} </div>
        }

        <div className={`popup-container ${isPopup ? 'active' : ''}`} onClick={()=> {
          togglePopup(false)
          document.body.style.overflow = 'scroll'
        }
        }>
          <ProductPopup brand={current_brand ? current_brand.name : null} list={products}/>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    isPopup: state.uiReducer.isPopup,
    current_category: state.uiReducer.current_category,
    categories: state.uiReducer.categories,
    language: state.uiReducer.language
  };
};

const dispatchToProps = {
  toggleCategory: () => dispatch => dispatch({type: TOGGLE_CATEGORY}),
  togglePopup: (bool, index) => dispatch => dispatch({type: TOGGLE_POPUP, bool: bool, index: index})
}

export default connect(mapStateToProps, dispatchToProps)(Phones);
