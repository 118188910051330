import React, {Component} from 'react';
import {connect} from 'react-redux';
import { } from '../../actions/ActionTypes';
import Colorbar from '../../components/colorbar/Colorbar';
import bg1 from '../../assets/photo/banner-1.jpg';
import packaging1 from '../../assets/materials/packaging1.jpg';
import packaging2 from '../../assets/materials/packaging2.jpg';
import ReactDOM from "react-dom";

import Slider from "react-slick";

import {
  Carousel,
  CarouselItem,
  CarouselControl,
  CarouselIndicators,
  CarouselCaption
} from 'reactstrap';

const items = [
  {
    src: packaging1,
    altText: 'packaging1',
    caption: ''
  },
  {
    src: packaging2,
    altText: 'packaging2',
    caption: ''
  },
  {
    src: packaging1,
    altText: 'packaging3',
    caption: ''
  }
];


class Packaging extends Component {

  constructor(props) {
    super(props);
    this.state = { activeIndex: 0 };
    this.next = this.next.bind(this);
    this.previous = this.previous.bind(this);
    this.goToIndex = this.goToIndex.bind(this);
    this.onExiting = this.onExiting.bind(this);
    this.onExited = this.onExited.bind(this);
  }

  onExiting() {
    this.animating = true;
  }

  onExited() {
    this.animating = false;
  }

  next() {
    if (this.animating) return;
    const nextIndex = this.state.activeIndex === items.length - 1 ? 0 : this.state.activeIndex + 1;
    this.setState({ activeIndex: nextIndex });
  }

  previous() {
    if (this.animating) return;
    const nextIndex = this.state.activeIndex === 0 ? items.length - 1 : this.state.activeIndex - 1;
    this.setState({ activeIndex: nextIndex });
  }

  goToIndex(newIndex) {
    if (this.animating) return;
    this.setState({ activeIndex: newIndex });
  }

  render() {
    // console.log(this.props.data);
    const data = this.props.data;
    const heading = data[`heading_${this.props.language}`];
    const subheading = data[`subheading_${this.props.language}`]; const subsubheading = data[`subsubheading_${this.props.language}`]

    const photos = data.rows[0][0].values;

    const { activeIndex } = this.state;

    const slides = items.map((item, index) => {
      return (
        <CarouselItem
          onExiting={this.onExiting}
          onExited={this.onExited}
          key={index}
        >
          <img className="backgroundImg" src={item.src} alt={item.altText} />

          <CarouselCaption captionText={item.caption} captionHeader={item.caption} />
        </CarouselItem>
      );
    });

    var settings = {
      dots: true,
      slidesToShow: 3,
      infinite: true,
      slidesToScroll: 1,
      centerMode: true,
      autoplay: true,
      autoplaySpeed: 3000,
      // draggable: false
    };

    if (this.props.data.is_hidden) {
      return (<div></div>)
    } else {
      return (
        <div id="Packaging">

          <div className="py-4 px-3 py-md-5 px-md-5 d-flex flex-column align-items-center">
            <h6 className="session font-weight-normal">{heading}</h6>
            <h2 className="title">{subheading}</h2>
            <Colorbar/>
            <div className="subsubheading font-12 mt-3"> {subsubheading} </div>

            <Carousel
              className="single-slide mt-5 mb-4"
              interval="3000"
              activeIndex={activeIndex}
              next={this.next}
              previous={this.previous}
              >
              <CarouselIndicators items={items} activeIndex={activeIndex} onClickHandler={this.goToIndex} />
              {slides}
              <CarouselControl direction="prev" directionText="Previous" onClickHandler={this.previous} />
              <CarouselControl direction="next" directionText="Next" onClickHandler={this.next} />
            </Carousel>

            <div className="carousel multi-slide container mt-5 mb-4">
              <Slider {...settings}>
                {photos.map( (item, index) =>
                  <div key={index}>
                    <img src={item ? item.resized_s_url : null}/>
                  </div>
                )}
                {photos.length < 4 &&
                  photos.map( (item, index) =>
                  <div key={index}>
                    <img src={item ? item.resized_s_url : null}/>
                  </div>
                )}
              </Slider>
            </div>

          </div>

        </div>
      );
    }
  }

}

const mapStateToProps = (state) => {
  return {
    language: state.uiReducer.language, data: state.dataReducer.dataSet.packaging
  };
};

const dispatchToProps = {
}

export default connect(mapStateToProps, dispatchToProps)(Packaging);
