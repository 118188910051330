import React, {Component} from 'react';
import {connect} from 'react-redux';
import { TOGGLE_POPUP, NEXT_PRODUCT, PREV_PRODUCT } from '../../../actions/ActionTypes';
import placeholder from '../../../assets/materials/product-image-placeholder.jpg';
import close from '../../../assets/icon/close-white.svg';

class ProductPopup extends Component {
  constructor(props){
    super(props);
    this.state = ({
      current_index: this.props.popupIndex,
    })
  }

  prevButtonClicked(){
    // console.log('list_length', this.props.list.length);
    // console.log('popupIndex', this.props.popupIndex);
    if ((this.props.popupIndex >= 1)) {
      this.props.prevProduct();
    }
  }

  nextButtonClicked(){
    // console.log('list_length', this.props.list.length);
    // console.log('popupIndex', this.props.popupIndex);
    if (this.props.list.length > (this.props.popupIndex + 1)) {
      this.props.nextProduct();
    }
  }

  render() {
    const item = this.props.list[this.props.popupIndex];
    // console.log(this.props.list.length);
    // console.log(this.props.popupIndex);
    // console.log(item);
    return (
      <div id="ProductPopup" onClick={(e)=>e.stopPropagation()} className=" mx-md-2 row d-flex flex-row justify-content-center align-items-center" >

        <div
          className={`prev-button ${(this.props.popupIndex >= 1) ? '' : 'hide'}`}
          onClick={()=>{
            // if(this.props.popupIndex >= 1) {
              this.prevButtonClicked()}
            // }
          }> {'<'}
        </div>

        <div className="photo col-md-5">
          <img src={item ? item.img: placeholder}/>
        </div>

        <div className="content col-md-5 d-flex flex-column align-items-md-start align-self-center">
          <div className="name font-18 font-weight-light">
            {this.props.current_category === 'All' && item ? item.brand : ''}
            {this.props.current_category !== 'All' ? this.props.brand : ''}
          </div>
          <div className="model font-24">
            {item ? item.name : 'no name.'}
          </div>
          <div className="text font-12">
            {item ? item.content : 'no content.'}
          </div>
        </div>
        <div
          className={`next-button ${this.props.list.length > (this.props.popupIndex + 1) ? '' : 'hide'}`}
          onClick={()=> {
            // if( this.props.list.length > (this.props.popupIndex + 1) ) {
              this.nextButtonClicked()
            // }
          }}> {'>'}
        </div>

        <div className="close-button" onClick={()=>  {
            this.props.togglePopup(false)
            document.body.style.overflow = 'scroll'
          }}>
          <img src={close}/>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    popupIndex: state.uiReducer.popupIndex,
    current_category: state.uiReducer.current_category,
    language: state.uiReducer.language
  };
};

const dispatchToProps = {
  togglePopup: (bool) => dispatch => dispatch({type: TOGGLE_POPUP, bool: bool}),
  prevProduct: () => dispatch => dispatch({type: PREV_PRODUCT}),
  nextProduct: () => dispatch => dispatch({type: NEXT_PRODUCT})
}

export default connect(mapStateToProps, dispatchToProps)(ProductPopup);
