import React, {Component} from 'react';
import {connect} from 'react-redux';
import {} from '../../actions/ActionTypes';
import Colorbar from '../../components/colorbar/Colorbar';
// import photo1 from '../../assets/materials/about-photo-1.jpg';
import Truncate from 'react-truncate';


class About extends Component {

  constructor(props){
    super(props);
    this.state = {
      isFullText: []
    }
  }

  scrollToComponent(id) {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ block: 'start',  behavior: 'smooth' });
    }
  }

  render() {
      // console.log(this.props.data);
      const data = this.props.data;
      const heading = data[`heading_${this.props.language}`];
      const subheading = data[`subheading_${this.props.language}`]; const subsubheading = data[`subsubheading_${this.props.language}`]

      if (this.props.data.is_hidden) {
        return (<div></div>)
      } else {
        return (
          <div id="About">
            <div className="py-4 px-3 py-md-5 px-md-5 d-flex flex-column align-items-center">
              <h6 className="session font-weight-normal">{heading}</h6>
              <h2 className="title"> {subheading} </h2>
              <Colorbar/>
              <div className="subsubheading font-12 mt-3"> {subsubheading} </div>

              {
                data.rows.map((row, i) => {
                  const photo = row[0].values[0] ? row[0].values[0].resized_s_url : null
                  const text = row[1].values[0][`content_html_${this.props.language}`];
                  const fulltext = row[2].values[0][`content_html_${this.props.language}`];

                  return (
                    <div id={`About-${row[1].values[0].id}`} className="content-container pt-4 pt-md-5 pb-md-4 row d-flex align-items-start justify-content-center" >
                      <img className="photo col-md-5 mr-md-2 img-contain" src={photo}></img>
                      <div className="text-container col-md-5 ml-md-2 mb-2 d-flex flex-column align-items-center align-items-md-start">
                        <div className="pt-3 py-md-0 text-justify" >
                          <div dangerouslySetInnerHTML={{__html: this.state.isFullText[i] ? fulltext: text}}/>
                        </div>
                        <div className="button" onClick={()=>{
                            let newIsFullText = this.state.isFullText
                            newIsFullText[i] = !newIsFullText[i]
                            this.setState({isFullText: newIsFullText});
                            this.scrollToComponent('About-' + row[1].values[0].id);
                          }}>
                          {this.props.language === 'zh_hk' ? (this.state.isFullText[i] ?  '顯示較少 <<' : '顯示更多 >>') : (this.state.isFullText[i] ?  'Show Less <<' : 'Read More >>')}
                        </div>
                      </div>
                    </div>
                  )
                })

              }
            </div>
          </div>
        )
      }

  }
}

const mapStateToProps = (state) => {
  return {
    language: state.uiReducer.language,
  };
};

const dispatchToProps = {
}

export default connect(mapStateToProps, dispatchToProps)(About);
