var _class, _temp, _class2, _temp2, _class3, _temp3, _class4, _temp4, _class5, _temp5, _class6, _temp7, _class7, _temp9, _class8, _temp11, _class9, _temp13, _class10, _temp15, _class11, _temp16, _class12, _temp17, _class13, _temp19, _class14, _temp21, _class15, _temp23, _class16, _temp25, _class17, _temp26;

function _classCallCheck(instance, Constructor) {
  if (!(instance instanceof Constructor)) {
    throw new TypeError("Cannot call a class as a function");
  }
}

function _possibleConstructorReturn(self, call) {
  if (!self) {
    throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
  }

  return call && (typeof call === "object" || typeof call === "function") ? call : self;
}

function _inherits(subClass, superClass) {
  if (typeof superClass !== "function" && superClass !== null) {
    throw new TypeError("Super expression must either be null or a function, not " + typeof superClass);
  }

  subClass.prototype = Object.create(superClass && superClass.prototype, {
    constructor: {
      value: subClass,
      enumerable: false,
      writable: true,
      configurable: true
    }
  });
  if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass;
}

import { QurttyModel } from './modelDefault';
import { fk, many, oneToOne, attr, Model, ORM } from 'redux-orm';
var Photo = (_temp = _class = function (_QurttyModel) {
  _inherits(Photo, _QurttyModel);

  function Photo() {
    _classCallCheck(this, Photo);

    return _possibleConstructorReturn(this, _QurttyModel.apply(this, arguments));
  }

  Photo.prototype.getUrl = function getUrl(urlName) {
    return this[urlName] || this.icon_url;
  };

  Photo.prototype.getOwnerCard = function getOwnerCard() {
    return this.owner.getAssociation('hold_member_cards')[0];
  };

  return Photo;
}(QurttyModel), _class.modelName = 'Photo', _class.fields = {
  owner_id: fk({
    to: 'Member',
    as: 'owner',
    relatedName: 'photos'
  }),
  extension_entity: oneToOne('Eav_Entity')
}, _temp);
var PhotoFrame = (_temp2 = _class2 = function (_QurttyModel2) {
  _inherits(PhotoFrame, _QurttyModel2);

  function PhotoFrame() {
    _classCallCheck(this, PhotoFrame);

    return _possibleConstructorReturn(this, _QurttyModel2.apply(this, arguments));
  }

  return PhotoFrame;
}(QurttyModel), _class2.modelName = 'PhotoFrame', _class2.fields = {
  photo_id: fk({
    to: 'Photo',
    as: '_photo',
    relatedName: 'photo_frames'
  })
}, _temp2);
var Album = (_temp3 = _class3 = function (_QurttyModel3) {
  _inherits(Album, _QurttyModel3);

  function Album() {
    _classCallCheck(this, Album);

    return _possibleConstructorReturn(this, _QurttyModel3.apply(this, arguments));
  }

  return Album;
}(QurttyModel), _class3.modelName = 'Album', _class3.fields = {
  member_id: fk({
    to: 'Member',
    as: 'member',
    relatedName: 'albums'
  })
}, _temp3);
var AlbumPhoto = (_temp4 = _class4 = function (_QurttyModel4) {
  _inherits(AlbumPhoto, _QurttyModel4);

  function AlbumPhoto() {
    _classCallCheck(this, AlbumPhoto);

    return _possibleConstructorReturn(this, _QurttyModel4.apply(this, arguments));
  }

  return AlbumPhoto;
}(QurttyModel), _class4.modelName = 'AlbumPhoto', _class4.fields = {
  photo_id: fk({
    to: 'Photo',
    as: 'photo',
    relatedName: 'album_photos'
  }),
  album_id: fk({
    to: 'Album',
    as: 'album',
    relatedName: 'album_photos'
  })
}, _temp4);
var PhotoParticipation = (_temp5 = _class5 = function (_QurttyModel5) {
  _inherits(PhotoParticipation, _QurttyModel5);

  function PhotoParticipation() {
    _classCallCheck(this, PhotoParticipation);

    return _possibleConstructorReturn(this, _QurttyModel5.apply(this, arguments));
  }

  return PhotoParticipation;
}(QurttyModel), _class5.modelName = 'PhotoParticipation', _class5.fields = {
  photo_id: fk({
    to: 'Photo',
    as: 'photo',
    relatedName: 'photo_participations'
  })
}, _temp5);
var PhotoParticipationInMember = (_temp7 = _class6 = function (_PhotoParticipation) {
  _inherits(PhotoParticipationInMember, _PhotoParticipation);

  function PhotoParticipationInMember() {
    var _temp6, _this6, _ret;

    _classCallCheck(this, PhotoParticipationInMember);

    for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    return _ret = (_temp6 = (_this6 = _possibleConstructorReturn(this, _PhotoParticipation.call.apply(_PhotoParticipation, [this].concat(args))), _this6), _this6.modelUrl = 'photo_participations', _temp6), _possibleConstructorReturn(_this6, _ret);
  }

  return PhotoParticipationInMember;
}(PhotoParticipation), _class6.modelName = 'PhotoParticipationInMember', _class6.fields = {
  party_id: fk({
    to: 'Member',
    as: 'party',
    relatedName: 'bag_photo_participations'
  })
}, _temp7);
var PhotoParticipationInCompany = (_temp9 = _class7 = function (_PhotoParticipation2) {
  _inherits(PhotoParticipationInCompany, _PhotoParticipation2);

  function PhotoParticipationInCompany() {
    var _temp8, _this7, _ret2;

    _classCallCheck(this, PhotoParticipationInCompany);

    for (var _len2 = arguments.length, args = Array(_len2), _key2 = 0; _key2 < _len2; _key2++) {
      args[_key2] = arguments[_key2];
    }

    return _ret2 = (_temp8 = (_this7 = _possibleConstructorReturn(this, _PhotoParticipation2.call.apply(_PhotoParticipation2, [this].concat(args))), _this7), _this7.modelUrl = 'photo_participations', _temp8), _possibleConstructorReturn(_this7, _ret2);
  }

  return PhotoParticipationInCompany;
}(PhotoParticipation), _class7.modelName = 'PhotoParticipationInCompany', _class7.fields = {
  party_id: fk({
    to: 'Company',
    as: 'party',
    relatedName: 'bag_photo_participations'
  })
}, _temp9);
var PhotoParticipationInProject = (_temp11 = _class8 = function (_PhotoParticipation3) {
  _inherits(PhotoParticipationInProject, _PhotoParticipation3);

  function PhotoParticipationInProject() {
    var _temp10, _this8, _ret3;

    _classCallCheck(this, PhotoParticipationInProject);

    for (var _len3 = arguments.length, args = Array(_len3), _key3 = 0; _key3 < _len3; _key3++) {
      args[_key3] = arguments[_key3];
    }

    return _ret3 = (_temp10 = (_this8 = _possibleConstructorReturn(this, _PhotoParticipation3.call.apply(_PhotoParticipation3, [this].concat(args))), _this8), _this8.modelUrl = 'photo_participations', _temp10), _possibleConstructorReturn(_this8, _ret3);
  }

  return PhotoParticipationInProject;
}(PhotoParticipation), _class8.modelName = 'PhotoParticipationInProject', _class8.fields = {}, _temp11);
var PhotoParticipationInDoc = (_temp13 = _class9 = function (_PhotoParticipation4) {
  _inherits(PhotoParticipationInDoc, _PhotoParticipation4);

  function PhotoParticipationInDoc() {
    var _temp12, _this9, _ret4;

    _classCallCheck(this, PhotoParticipationInDoc);

    for (var _len4 = arguments.length, args = Array(_len4), _key4 = 0; _key4 < _len4; _key4++) {
      args[_key4] = arguments[_key4];
    }

    return _ret4 = (_temp12 = (_this9 = _possibleConstructorReturn(this, _PhotoParticipation4.call.apply(_PhotoParticipation4, [this].concat(args))), _this9), _this9.modelUrl = 'photo_participations', _temp12), _possibleConstructorReturn(_this9, _ret4);
  }

  return PhotoParticipationInDoc;
}(PhotoParticipation), _class9.modelName = 'PhotoParticipationInDoc', _class9.fields = {}, _temp13);
var PhotoParticipationInEav_Structure = (_temp15 = _class10 = function (_PhotoParticipation5) {
  _inherits(PhotoParticipationInEav_Structure, _PhotoParticipation5);

  function PhotoParticipationInEav_Structure() {
    var _temp14, _this10, _ret5;

    _classCallCheck(this, PhotoParticipationInEav_Structure);

    for (var _len5 = arguments.length, args = Array(_len5), _key5 = 0; _key5 < _len5; _key5++) {
      args[_key5] = arguments[_key5];
    }

    return _ret5 = (_temp14 = (_this10 = _possibleConstructorReturn(this, _PhotoParticipation5.call.apply(_PhotoParticipation5, [this].concat(args))), _this10), _this10.modelUrl = 'photo_participations', _temp14), _possibleConstructorReturn(_this10, _ret5);
  }

  return PhotoParticipationInEav_Structure;
}(PhotoParticipation), _class10.modelName = 'PhotoParticipationInEav_Structure', _class10.fields = {}, _temp15);
var PhotoablePhoto = (_temp16 = _class11 = function (_QurttyModel6) {
  _inherits(PhotoablePhoto, _QurttyModel6);

  function PhotoablePhoto() {
    _classCallCheck(this, PhotoablePhoto);

    return _possibleConstructorReturn(this, _QurttyModel6.apply(this, arguments));
  }

  return PhotoablePhoto;
}(QurttyModel), _class11.modelName = 'PhotoablePhoto', _class11.fields = {
  photo_id: fk({
    to: 'Photo',
    as: 'photo',
    relatedName: 'photoable_photos'
  })
}, _temp16);
var PhotoablePhotoParticipation = (_temp17 = _class12 = function (_QurttyModel7) {
  _inherits(PhotoablePhotoParticipation, _QurttyModel7);

  function PhotoablePhotoParticipation() {
    _classCallCheck(this, PhotoablePhotoParticipation);

    return _possibleConstructorReturn(this, _QurttyModel7.apply(this, arguments));
  }

  return PhotoablePhotoParticipation;
}(QurttyModel), _class12.modelName = 'PhotoablePhotoParticipation', _class12.fields = {}, _temp17);
var MemberCardPhotoParticipationInMember = (_temp19 = _class13 = function (_PhotoablePhotoPartic) {
  _inherits(MemberCardPhotoParticipationInMember, _PhotoablePhotoPartic);

  function MemberCardPhotoParticipationInMember() {
    var _temp18, _this13, _ret6;

    _classCallCheck(this, MemberCardPhotoParticipationInMember);

    for (var _len6 = arguments.length, args = Array(_len6), _key6 = 0; _key6 < _len6; _key6++) {
      args[_key6] = arguments[_key6];
    }

    return _ret6 = (_temp18 = (_this13 = _possibleConstructorReturn(this, _PhotoablePhotoPartic.call.apply(_PhotoablePhotoPartic, [this].concat(args))), _this13), _this13.modelUrl = 'photoable_photo_participations', _temp18), _possibleConstructorReturn(_this13, _ret6);
  }

  return MemberCardPhotoParticipationInMember;
}(PhotoablePhotoParticipation), _class13.modelName = 'MemberCardPhotoParticipationInMember', _class13.fields = {
  photoable_id: fk({
    to: 'MemberCard',
    as: 'photoable',
    relatedName: 'member_card_in_member_photo_participations'
  }),
  photo_participation_id: fk({
    to: 'PhotoParticipationInMember',
    as: 'photo_participation',
    relatedName: 'member_card_in_member_photo_participations'
  })
}, _temp19);
var CompanyCardPhotoParticipationInMember = (_temp21 = _class14 = function (_PhotoablePhotoPartic2) {
  _inherits(CompanyCardPhotoParticipationInMember, _PhotoablePhotoPartic2);

  function CompanyCardPhotoParticipationInMember() {
    var _temp20, _this14, _ret7;

    _classCallCheck(this, CompanyCardPhotoParticipationInMember);

    for (var _len7 = arguments.length, args = Array(_len7), _key7 = 0; _key7 < _len7; _key7++) {
      args[_key7] = arguments[_key7];
    }

    return _ret7 = (_temp20 = (_this14 = _possibleConstructorReturn(this, _PhotoablePhotoPartic2.call.apply(_PhotoablePhotoPartic2, [this].concat(args))), _this14), _this14.modelUrl = 'photoable_photo_participations', _temp20), _possibleConstructorReturn(_this14, _ret7);
  }

  return CompanyCardPhotoParticipationInMember;
}(PhotoablePhotoParticipation), _class14.modelName = 'CompanyCardPhotoParticipationInMember', _class14.fields = {
  photoable_id: fk({
    to: 'CompanyCard',
    as: 'photoable',
    relatedName: 'company_card_in_member_photo_participations'
  }),
  photo_participation_id: fk({
    to: 'PhotoParticipationInMember',
    as: 'photo_participation',
    relatedName: 'company_card_in_member_photo_participations'
  })
}, _temp21);
var MemberCardPhotoParticipationInCompany = (_temp23 = _class15 = function (_PhotoablePhotoPartic3) {
  _inherits(MemberCardPhotoParticipationInCompany, _PhotoablePhotoPartic3);

  function MemberCardPhotoParticipationInCompany() {
    var _temp22, _this15, _ret8;

    _classCallCheck(this, MemberCardPhotoParticipationInCompany);

    for (var _len8 = arguments.length, args = Array(_len8), _key8 = 0; _key8 < _len8; _key8++) {
      args[_key8] = arguments[_key8];
    }

    return _ret8 = (_temp22 = (_this15 = _possibleConstructorReturn(this, _PhotoablePhotoPartic3.call.apply(_PhotoablePhotoPartic3, [this].concat(args))), _this15), _this15.modelUrl = 'photoable_photo_participations', _temp22), _possibleConstructorReturn(_this15, _ret8);
  }

  return MemberCardPhotoParticipationInCompany;
}(PhotoablePhotoParticipation), _class15.modelName = 'MemberCardPhotoParticipationInCompany', _class15.fields = {
  photoable_id: fk({
    to: 'MemberCard',
    as: 'photoable',
    relatedName: 'member_card_in_company_photo_participations'
  }),
  photo_participation_id: fk({
    to: 'PhotoParticipationInCompany',
    as: 'photo_participation',
    relatedName: 'member_card_in_company_photo_participations'
  })
}, _temp23);
var CompanyCardPhotoParticipationInCompany = (_temp25 = _class16 = function (_PhotoablePhotoPartic4) {
  _inherits(CompanyCardPhotoParticipationInCompany, _PhotoablePhotoPartic4);

  function CompanyCardPhotoParticipationInCompany() {
    var _temp24, _this16, _ret9;

    _classCallCheck(this, CompanyCardPhotoParticipationInCompany);

    for (var _len9 = arguments.length, args = Array(_len9), _key9 = 0; _key9 < _len9; _key9++) {
      args[_key9] = arguments[_key9];
    }

    return _ret9 = (_temp24 = (_this16 = _possibleConstructorReturn(this, _PhotoablePhotoPartic4.call.apply(_PhotoablePhotoPartic4, [this].concat(args))), _this16), _this16.modelUrl = 'photoable_photo_participations', _temp24), _possibleConstructorReturn(_this16, _ret9);
  }

  return CompanyCardPhotoParticipationInCompany;
}(PhotoablePhotoParticipation), _class16.modelName = 'CompanyCardPhotoParticipationInCompany', _class16.fields = {
  photoable_id: fk({
    to: 'CompanyCard',
    as: 'photoable',
    relatedName: 'company_card_in_company_photo_participations'
  }),
  photo_participation_id: fk({
    to: 'PhotoParticipationInCompany',
    as: 'photo_participation',
    relatedName: 'company_card_in_company_photo_participations'
  })
}, _temp25);
var CardParticipation = (_temp26 = _class17 = function (_QurttyModel8) {
  _inherits(CardParticipation, _QurttyModel8);

  function CardParticipation() {
    _classCallCheck(this, CardParticipation);

    return _possibleConstructorReturn(this, _QurttyModel8.apply(this, arguments));
  }

  return CardParticipation;
}(QurttyModel), _class17.modelName = 'CardParticipation', _class17.fields = {}, _temp26);
export var photoModelRegister = function photoModelRegister(orm) {
  orm.register(Album, AlbumPhoto, PhotoParticipation, PhotoParticipationInMember, PhotoParticipationInProject, PhotoParticipationInDoc, PhotoParticipationInEav_Structure, PhotoablePhoto, PhotoablePhotoParticipation, PhotoParticipationInCompany, MemberCardPhotoParticipationInMember, CompanyCardPhotoParticipationInMember, MemberCardPhotoParticipationInCompany, CompanyCardPhotoParticipationInCompany, Photo, PhotoFrame, CardParticipation);
};