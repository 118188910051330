export const STORE_API_DATA_TO_DATA_REDUCER = "STORE_API_DATA_TO_DATA_REDUCER";
export const STORE_PRODUCT_BANNER_TO_DATA_REDUCER = "STORE_PRODUCT_BANNER_TO_DATA_REDUCER"

export const TEST = "TEST";
export const SET_PRODUCT = "SET_PRODUCT";
export const TOGGLE_POPUP = "TOGGLE_POPUP";
export const NEXT_PRODUCT = "NEXT_PRODUCT";
export const PREV_PRODUCT = "PREV_PRODUCT";
export const TOGGLE_CATEGORY = "TOGGLE_CATEGORY";
export const SAVE_PATH = "SAVE_PATH";
export const TOGGLE_HEADER = "TOGGLE_HEADER";
export const ALLOW_MAIN_SCROLL = "ALLOW_MAIN_SCROLL";
export const SET_ALL_PRODUCTS = "SET_ALL_PRODUCTS";
export const SWITCH_LANGUAGE = "SWITCH_LANGUAGE";
export const SET_LANGUAGE = "SET_LANGUAGE";
