import { schema } from 'normalizr';
import { orm } from './models'; // Set up schema
// Structure

var attrSchema = new schema.Entity('eav/attrs', {});
var valueSchema = new schema.Entity('eav/values', {});
var entitySchema = new schema.Entity('eav/entities', {
  values: [valueSchema]
});
var structureSchema = new schema.Entity('eav/structures', {
  attrs: [attrSchema],
  entities: [entitySchema]
});
attrSchema.define({
  type_structure: structureSchema
}); // Exposures

var ParsedAddressSchema = new schema.Entity('parsed_addresses');
var AddressSchema = new schema.Entity('addresses', {
  parsed_address: ParsedAddressSchema
});
var EmailSchema = new schema.Entity('emails');
var PhoneSchema = new schema.Entity('phones');
var websiteSchema = new schema.Entity('websites');
var albumSchema = new schema.Entity('albums');
var albumPhotoSchema = new schema.Entity('album_photos');
var photoFrameSchema = new schema.Entity('photo_frames');
var photoSchema = new schema.Entity('photos', {
  extension_entity: entitySchema,
  photo_frames: [photoFrameSchema]
});
var photoablePhotoSchema = new schema.Entity('photoable_photos', {
  photo: photoSchema
});
var productSchema = new schema.Entity('products', {
  photos: [photoSchema],
  photoable_photos: [photoablePhotoSchema],
  extension_entity: entitySchema
});
var addressExposuresSchema = new schema.Entity('address_exposures', {
  address: AddressSchema
});
var emailExposuresSchema = new schema.Entity('email_exposures', {
  email: EmailSchema
});
var phoneExposuresSchema = new schema.Entity('phone_exposures', {
  phone: PhoneSchema
});
var photoParticipationSchema = new schema.Entity('photo_participations', {
  photo: photoSchema
});
var photoablePhotoParticipationSchema = new schema.Entity('photoable_photo_participations', {
  photo_participation: photoParticipationSchema
});
var cardParticipationSchema = new schema.Entity('card_participations', {});
AddressSchema.define({
  address_exposures: [addressExposuresSchema]
});
EmailSchema.define({
  email_exposures: [emailExposuresSchema]
});
PhoneSchema.define({
  phone_exposures: [phoneExposuresSchema]
});
photoSchema.define({
  photo_participations: [photoParticipationSchema]
});
var friendshipSchema = new schema.Entity('friendships');
var websiteControlSchema = new schema.Entity('website_controls', {});
var webpageSectionAttrSchema = new schema.Entity('webpage_section_attrs', {});
var webpageSectionSchema = new schema.Entity('webpage_sections', {
  webpage_section_attrs: [webpageSectionAttrSchema]
});
var webpageSchema = new schema.Entity('webpages', {
  webpage_sections: [webpageSectionSchema]
});
var projectSchema = new schema.Entity('projects', {
  structures: [structureSchema],
  photo_extension_structure: structureSchema,
  member_card_extension_structure: structureSchema,
  company_card_extension_structure: structureSchema,
  product_extension_structure: structureSchema,
  website_control: websiteControlSchema,
  cover: photoSchema
});
var projectCheckpointSchema = new schema.Entity('project_checkpoints', {});
var docSchema = new schema.Entity('docs', {
  structure: structureSchema,
  photo_extension_structure: structureSchema,
  member_card_extension_structure: structureSchema,
  company_card_extension_structure: structureSchema,
  product_extension_structure: structureSchema,
  cover: photoSchema
});
var attrTypeSchema = new schema.Entity('eav/attr_types', {});
var particleTypeSchema = new schema.Entity('eav/particle_types', {}); // Structure End
// Card

var businessCardSchema = new schema.Entity('business_cards', {
  address_exposures: [addressExposuresSchema],
  email_exposures: [emailExposuresSchema],
  phone_exposures: [phoneExposuresSchema],
  websites: [websiteSchema]
});
var membershipSchema = new schema.Entity('memberships', {
  business_card: businessCardSchema
});
var memberCardSchema = new schema.Entity('member_cards', {
  memberships: [membershipSchema],
  address_exposures: [addressExposuresSchema],
  email_exposures: [emailExposuresSchema],
  phone_exposures: [phoneExposuresSchema],
  websites: [websiteSchema],
  photoable_photo_participations: [photoablePhotoParticipationSchema],
  extension_entity: entitySchema,
  photo: photoSchema
});
memberCardSchema.define({
  sealed_for_card: memberCardSchema,
  card_participations: [cardParticipationSchema]
});
var societySchema = new schema.Entity('societies');
var societyMemberCardSchema = new schema.Entity('society_member_cards');
societySchema.define({
  child_societies: [societySchema],
  society_member_cards: [societyMemberCardSchema]
});
var companyCardSchema = new schema.Entity('company_cards', {
  memberships: [membershipSchema],
  address_exposures: [addressExposuresSchema],
  email_exposures: [emailExposuresSchema],
  phone_exposures: [phoneExposuresSchema],
  websites: [websiteSchema],
  photoable_photo_participations: [photoablePhotoParticipationSchema],
  extension_entity: entitySchema
});
companyCardSchema.define({
  card_participations: [cardParticipationSchema]
});
var memberNameSchema = new schema.Entity('member_names', {});
var memberSchema = new schema.Entity('members', {
  hold_member_cards: [memberCardSchema],
  hold_primary_member_card: memberCardSchema,
  card_addresses: [AddressSchema],
  card_emails: [EmailSchema],
  card_phones: [PhoneSchema],
  profile_photo: photoSchema,
  member_names: [memberNameSchema]
});
var assetPoolSchema = new schema.Entity('asset_pools', {});
var companySchema = new schema.Entity('companies', {
  asset_pool: assetPoolSchema,
  hold_company_cards: [companyCardSchema],
  card_addresses: [AddressSchema],
  card_emails: [EmailSchema],
  card_phones: [PhoneSchema]
});
var departmentSchema = new schema.Entity('departments', {});
departmentSchema.define({
  child_departments: [departmentSchema]
});
var employmentDepartmentSchema = new schema.Entity('employment_departments', {
  membership: membershipSchema
});
var staffCardSchema = new schema.Entity('staff_cards', {
  member_card: memberCardSchema,
  company_card: companyCardSchema
});
var employmentSchema = new schema.Entity('employments', {
  employee_master: memberSchema,
  employee_account: memberSchema,
  sealed_employee_account_card: memberCardSchema,
  employment_departments: [employmentDepartmentSchema],
  staff_cards: [staffCardSchema],
  company: companySchema
});
memberSchema.define({
  employment: employmentSchema
});
var entityPermissionSchema = new schema.Entity('eav/entity_permissions', {
  entities: [entitySchema],
  member_card: memberCardSchema
});
var entityCommentSchema = new schema.Entity('eav/entity_comments', {
  member_card: memberCardSchema
});
entitySchema.define({
  entity_permissions: [entityPermissionSchema]
});
projectSchema.define({
  member: memberSchema
});
docSchema.define({
  member: memberSchema
});
var versionSchema = new schema.Entity('paper_trail/versions', {});
var zoneSchema = new schema.Entity('hk_postal/zones', {});
var districtSchema = new schema.Entity('hk_postal/districts', {});
var streetSchema = new schema.Entity('hk_postal/streets', {});
var streetNumberSchema = new schema.Entity('hk_postal/street_numbers', {
  street: streetSchema
});
var estateSchema = new schema.Entity('hk_postal/estates', {});
var phaseSchema = new schema.Entity('hk_postal/phases', {});
var buildingSchema = new schema.Entity('hk_postal/buildings', {
  phase: phaseSchema,
  estate: estateSchema
});
var floorSchema = new schema.Entity('hk_postal/floors', {});
var unitSchema = new schema.Entity('hk_postal/units', {});
var streetBuildingSchema = new schema.Entity('hk_postal/street_buildings', {});
var streetEstateSchema = new schema.Entity('hk_postal/street_estates', {});
var streetNumberBuildingSchema = new schema.Entity('hk_postal/street_number_buildings', {});
var streetNumberEstateSchema = new schema.Entity('hk_postal/street_number_estates', {});
var HkPostal_AddressSchema = new schema.Entity('hk_postal/address', {});
AddressSchema.define({
  hk_postal_address: HkPostal_AddressSchema
});
buildingSchema.define({
  street_number_buildings: [streetNumberBuildingSchema]
});
var Recruit_AcademicRecordSchema = new schema.Entity('recruit/academic_records', {});
var Recruit_AwardSchema = new schema.Entity('recruit/awards', {});
var Recruit_QuestionSchema = new schema.Entity('recruit/questions', {});
var Recruit_WorkExperienceSchema = new schema.Entity('recruit/work_experiences', {});
var RecruitFormSchema = new schema.Entity('recruit_forms', {
  academic_records: [Recruit_AcademicRecordSchema],
  awards: [Recruit_AwardSchema],
  questions: [Recruit_QuestionSchema],
  work_experiences: [Recruit_WorkExperienceSchema],
  applicant_card: memberCardSchema
});
export var schemaModel = {
  ParsedAddress: ParsedAddressSchema,
  Address: AddressSchema,
  Email: EmailSchema,
  Phone: PhoneSchema,
  Photo: photoSchema,
  PhotoFrame: photoFrameSchema,
  Album: albumSchema,
  AlbumPhoto: albumPhotoSchema,
  PhotoParticipation: photoParticipationSchema,
  PhotoablePhoto: photoablePhotoSchema,
  PhotoablePhotoParticipation: photoablePhotoParticipationSchema,
  CardParticipation: cardParticipationSchema,
  Product: productSchema,
  ProjectCheckpoint: projectCheckpointSchema,
  Project: projectSchema,
  Doc: docSchema,
  WebsiteControl: websiteControlSchema,
  Webpage: webpageSchema,
  WebpageSection: webpageSectionSchema,
  WebpageSectionAttr: webpageSectionAttrSchema,
  Eav_AttrType: attrTypeSchema,
  Eav_ParticleType: particleTypeSchema,
  Eav_Attr: attrSchema,
  Eav_Value: valueSchema,
  Eav_Entity: entitySchema,
  Eav_Structure: structureSchema,
  Eav_EntityPermission: entityPermissionSchema,
  Eav_EntityComment: entityCommentSchema,
  Membership: membershipSchema,
  MemberCard: memberCardSchema,
  CompanyCard: companyCardSchema,
  StaffCard: staffCardSchema,
  AddressExposure: addressExposuresSchema,
  EmailExposure: emailExposuresSchema,
  PhoneExposure: phoneExposuresSchema,
  Member: memberSchema,
  MemberName: memberNameSchema,
  Society: societySchema,
  SocietyMemberCard: societyMemberCardSchema,
  Company: companySchema,
  AssetPool: assetPoolSchema,
  Employment: employmentSchema,
  Department: departmentSchema,
  EmploymentDepartment: employmentDepartmentSchema,
  Friendship: friendshipSchema,
  Website: websiteSchema,
  Version: versionSchema,
  HkPostal_Zone: zoneSchema,
  HkPostal_District: districtSchema,
  HkPostal_Street: streetSchema,
  HkPostal_StreetNumber: streetNumberSchema,
  HkPostal_Estate: estateSchema,
  HkPostal_Phase: phaseSchema,
  HkPostal_Building: buildingSchema,
  HkPostal_Floor: floorSchema,
  HkPostal_Unit: unitSchema,
  HkPostal_StreetBuilding: streetBuildingSchema,
  HkPostal_StreetEstate: streetEstateSchema,
  HkPostal_StreetNumberBuilding: streetNumberBuildingSchema,
  HkPostal_StreetNumberEstate: streetNumberEstateSchema,
  HkPostal_Address: HkPostal_AddressSchema,
  RecruitForm: RecruitFormSchema,
  Recruit_AcademicRecord: Recruit_AcademicRecordSchema,
  Recruit_Award: Recruit_AwardSchema,
  Recruit_Question: Recruit_QuestionSchema,
  Recruit_WorkExperience: Recruit_WorkExperienceSchema
};
export var ormState = orm.getEmptyState();
export var session = orm.mutableSession(ormState);
export default orm;