import React, {Component} from 'react';
import {connect} from 'react-redux';
import { } from '../../actions/ActionTypes';
import Colorbar from '../../components/colorbar/Colorbar';

class Client extends Component {
  constructor(props){
    super(props);
    this.state = {
      isFullText: false
    }
  }

  scrollToComponent(id) {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ block: 'start',  behavior: 'smooth' });
    }
  }

  render() {
    // console.log(this.props.data);
    const data = this.props.data;
    const heading = data[`heading_${this.props.language}`];
    const subheading = data[`subheading_${this.props.language}`]; const subsubheading = data[`subsubheading_${this.props.language}`]

    const photo = data.rows[0][0].values[0] ? data.rows[0][0].values[0].resized_s_url : null
    const text = data.rows[0][1].values[0][`content_text_${this.props.language}`];
    const fulltext = data.rows[0][2].values[0][`content_text_${this.props.language}`];
    const flags = data.rows[0][3].values;

    if (this.props.data.is_hidden) {
      return (<div></div>)
    } else {
      return (
        <div id="Client" >
          <div className="py-4 px-3 py-md-5 px-md-5 d-flex flex-column align-items-center">
            <h6 className="session font-weight-normal">{heading}</h6>
            <h2 className="title">{subheading}</h2>
            <Colorbar/>
            <div className="subsubheading font-12 mt-3"> {subsubheading} </div>

            <div className="content-container pt-4 pt-md-5 pb-md-4 row d-flex align-items-start justify-content-center" >
              <img className="photo col-md-5 mr-md-2 img-contain" src={photo}></img>
              <div className="text-container col-md-5 ml-md-2 mb-2 d-flex flex-column align-items-center align-items-md-start">
                <div className="py-3 py-md-0 text-justify" >
                  {this.state.isFullText ? fulltext: text}
                </div>

                <div className="flags">
                  {flags.map( (item, index) => <img key={index} className="flag" src={item.icon_url}/> )}
                </div>

                <div className="button" onClick={()=>{
                    this.setState({isFullText: !this.state.isFullText});
                    this.scrollToComponent('Client');
                  }}>
                  {this.props.language === 'zh_hk' ? (this.state.isFullText ?  '顯示較少 <<' : '顯示更多 >>') : (this.state.isFullText ?  'Show Less <<' : 'Read More >>')}
                </div>
              </div>
            </div>

          </div>
        </div>
      )
    }

  }
}

const mapStateToProps = (state) => {
  return {
    language: state.uiReducer.language, data: state.dataReducer.dataSet.clients
  };
};

const dispatchToProps = {
}

export default connect(mapStateToProps, dispatchToProps)(Client);
