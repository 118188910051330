var _extends = Object.assign || function (target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = arguments[i];

    for (var key in source) {
      if (Object.prototype.hasOwnProperty.call(source, key)) {
        target[key] = source[key];
      }
    }
  }

  return target;
};
/*
 * Here is second level of API calling. Those are not for developer to export as action.
 * They are just helper function.
 */


import { normalize } from 'normalizr';
import { schemaModel, session, ormState } from '../orm/schemas';
import * as qurttyApi from './api';
import { normalizedDataInsertORM, resourcesUrlToModelName, associationNameToModelName } from '../orm/ormHelpers';
/**
 *This function purpose is getting the particle list for reducer and convert raw data to model.
 *DoubleAPIChecking will check the do particle need to update and ask server for the particle ORM doesn't have it.
 *DoubleAPIChecking have 2 part:
 *First part will only check if anythings have to update or download from server. Then pass the list to second part. If not then return the particle llst and not go for second part.
 *Second part will ask server for the data and insert those into ORM.
 * @param  {String} resourcesUrl        The URL for GET request
 * @param  {Object} [firstApiParam={}]  The parameter of checking need update API call.
 * @param  {Object} [secondApiParam={}] The parameter of checking what particle need to update. If this parameter is null, then will use the firstApiParam.
 * @param  {Object} [options={}]        If you need to sort the returned particle list, pass options={noArray: true} and use the api in https://redux-orm.github.io/redux-orm/QuerySet.html
 * @return {Promise}                     Promise of the model instance array
 *
 */

export var doubleAPIChecking = function doubleAPIChecking(resourcesUrl) {
  var firstApiParam = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  var secondApiParam = arguments[2];
  var options = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : {};
  var associationUrl = /^.+\/\d+\//.test(resourcesUrl) ? resourcesUrl.replace(/^.+\/\d+\//, '') : null;

  var firstParam = _extends({}, firstApiParam, {
    only_attributes: ['id', 'updated_at']
  });

  return new Promise(function (resolve, reject) {
    qurttyApi.getResourcesAPI(resourcesUrl, firstParam).then(function (json) {
      var modelName = associationUrl ? associationNameToModelName(associationUrl) : resourcesUrlToModelName(resourcesUrl);
      var key = Object.keys(json)[0];
      var particleList = json[key];
      var ormStateModelList = ormState[modelName];
      var existingParticleList = particleList.filter(function (particle) {
        return ormStateModelList.items.includes(particle.id) && particle.updated_at === ormStateModelList.itemsById[particle.id].updated_at;
      }).map(function (particle) {
        return particle.id;
      });
      var secondParam = {};

      if (secondApiParam) {
        secondParam = _extends({}, secondApiParam, {
          not: existingParticleList
        });
      } else {
        secondParam = _extends({}, firstApiParam, {
          not: existingParticleList
        });
      }

      secondParam.page = firstApiParam.page || 1;
      secondParam.per_page = firstApiParam.per_page || 20; // FIXME: This must be fix since not here to define how much page of data to get.

      if (particleList.length === existingParticleList.length) {
        var particleIdList = particleList.map(function (p) {
          return p.id;
        });
        var particleReturnList = session[modelName].filter(function (rec) {
          return particleIdList.includes(rec.id);
        }).orderBy('display_order', 'asc');

        if (!options.noArray) {
          particleReturnList = particleReturnList.toModelArray();
        }

        resolve({
          particleList: particleReturnList
        });
      } else {
        qurttyApi.getResourcesAPI(resourcesUrl, secondParam).then(function (json) {
          var _normalize;

          var normalizedData = normalize(json, (_normalize = {}, _normalize[key] = [schemaModel[modelName]], _normalize));
          normalizedDataInsertORM(normalizedData);
          var particleIdList = particleList.map(function (p) {
            return p.id;
          });
          var particleReturnList = session[modelName].filter(function (rec) {
            return particleIdList.includes(rec.id);
          }).orderBy('display_order', 'asc');

          if (!options.noArray) {
            particleReturnList = particleReturnList.toModelArray();
          }

          resolve({
            particleList: particleReturnList
          });
        });
      }
    }, function (reject) {
      return reject;
    });
  });
};
export var getResources = function getResources(resourcesUrl) {
  var params = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  var options = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
  return qurttyApi.getResourcesAPI(resourcesUrl, params).then(function (json) {
    var _normalize2;

    var key = Object.keys(json)[0];
    var ids = json[key].map(function (rec) {
      return rec.id;
    });
    var modelName = resourcesUrlToModelName(resourcesUrl);
    var normalizedData = normalize(json, (_normalize2 = {}, _normalize2[key] = [schemaModel[modelName]], _normalize2));
    normalizedDataInsertORM(normalizedData);
    var particleReturnList = session[modelName].filter(function (rec) {
      return ids.includes(rec.id);
    }).orderBy('display_order', 'asc');

    if (!options.noArray) {
      particleReturnList = particleReturnList.toModelArray();
    }

    return particleReturnList;
  });
};
export var getSingleResource = function getSingleResource(resourcesUrl, id) {
  var params = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
  return qurttyApi.getSingleResourceAPI(resourcesUrl, id, params).then(function (json) {
    var _normalize3;

    var key = Object.keys(json)[0];
    var modelName = resourcesUrlToModelName(resourcesUrl);
    var normalizedData = normalize(json, (_normalize3 = {}, _normalize3[key] = schemaModel[modelName], _normalize3)); // console.log(modelName, normalizedData);

    normalizedDataInsertORM(normalizedData);
    return Number.isInteger(id) ? session[modelName].withId(id) : session[modelName].get({
      slug: id
    });
  });
};
export var getAccountMember = function getAccountMember(memberID) {
  var params = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

  var apiParam = _extends({}, params, {
    _with_roles: true,
    _with_cards: true,
    _with_card_parts: true,
    _with_company: true,
    _with_asset_pool: true
  });

  return qurttyApi.getUserAccountAPI(memberID, apiParam).then(function (json) {
    var normalizedData = normalize(json, {
      "member": schemaModel.Member
    });
    normalizedDataInsertORM(normalizedData);
    var member = session.Member.withId(memberID);
    return member;
  });
}; // Refresh the certain card. Return a promise for refresh the array or somethings else

export var refreshSingleMemberCard = function refreshSingleMemberCard(cardID) {
  var params = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

  var apiParam = _extends({}, params, {
    _with_roles: true,
    _with_cards: true,
    _with_card_parts: true,
    _with_company: true
  });

  qurttyApi.getSingleMemberCardAPI(cardID, apiParam).then(function (json) {
    var normalizedData = normalize(json, {
      "member_card": schemaModel.MemberCard
    });
    normalizedDataInsertORM(normalizedData);
    var memberCard = session.MemberCard.withId(cardID);
    return memberCard;
  });
};