/*
 * First level of API call.
 */
import { axios } from 'redux-token-auth'; // MemberCard API Card
// Getter

/**
 * GET request for resources
 * This function is used by APIHelper to get model instance list and store it into ORM.
 * @param  {String} resourcesUrl  resources url
 * @param  {Object} [apiParam={}] API Query String parameter
 * @param  {Function} afterAPI      callback
 * @return {Promise}               Promise of resources
 */

export var getResourcesAPI = function getResourcesAPI(resourcesUrl) {
  var apiParam = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  var afterAPI = arguments[2];
  var promise = void 0;

  if (apiParam.specific === true) {
    promise = axios.post(resourcesUrl, apiParam);
  } else {
    promise = axios.get(resourcesUrl, {
      params: apiParam
    });
  }

  return promise.then(function (resp) {
    return resp.data;
  }).then(function (resp) {
    if (afterAPI) {
      return afterAPI(resp);
    } else {
      return resp;
    }
  });
};
/**
 * http GET one resource
 * @param  {String} resourcesUrl  url
 * @param  {Object} [apiParam={}] query params
 * @param  {Function} afterAPI      callback
 * @return {Promise}               of the single resource
 */

export var getSingleResourceAPI = function getSingleResourceAPI(resourcesUrl, id) {
  var apiParam = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
  var afterAPI = arguments[3];
  return axios.get(resourcesUrl + '/' + id, {
    params: apiParam
  }).then(function (resp) {
    return resp.data;
  }).then(function (resp) {
    if (afterAPI) {
      return afterAPI(resp);
    } else {
      return resp;
    }
  });
};
export var getUserAccountAPI = function getUserAccountAPI(memberID) {
  var apiParam = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  var afterAPI = arguments[2];
  return axios.get('members/' + memberID, {
    params: apiParam
  }).then(function (resp) {
    return resp.data;
  }).then(function (resp) {
    if (afterAPI) {
      return afterAPI(resp);
    } else {
      return resp;
    }
  });
};
export var getInviteeAPI = function getInviteeAPI(invitationToken) {
  var apiParam = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  var afterAPI = arguments[2];
  return axios.get('invitees/' + invitationToken, {
    params: apiParam
  }).then(function (resp) {
    return resp.data;
  }).then(function (resp) {
    if (afterAPI) {
      return afterAPI(resp);
    } else {
      return resp;
    }
  });
};
export var signUpAsInvited = function signUpAsInvited() {
  var apiParam = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var afterAPI = arguments[1];
  return axios.put(process.env.REACT_APP_API_BASE_URL + '/members/invitation', apiParam).then(function (resp) {
    return resp.data;
  }).then(function (resp) {
    if (afterAPI) {
      return afterAPI(resp);
    } else {
      return resp;
    }
  });
};
export var signInUserWithInvited = function signInUserWithInvited() {
  var apiParam = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var afterAPI = arguments[1];
  return axios.post('auth/sign_in', apiParam).then(function (resp) {
    return resp.data;
  }).then(function (resp) {
    if (afterAPI) {
      return afterAPI(resp);
    } else {
      return resp;
    }
  });
};
export var forgetPassword = function forgetPassword() {
  var apiParam = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var afterAPI = arguments[1];
  return axios.post('auth/password', apiParam).then(function (resp) {
    return resp.data;
  }).then(function (resp) {
    if (afterAPI) {
      return afterAPI(resp);
    } else {
      return resp;
    }
  });
};
export var getSingleMemberCardAPI = function getSingleMemberCardAPI(memberID) {
  var apiParam = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  var afterAPI = arguments[2];
  axios.get('member_cards/' + memberID, {
    params: apiParam
  }).then(function (resp) {
    return resp.data;
  }).then(function (resp) {
    if (afterAPI) {
      return afterAPI(resp);
    } else {
      return resp;
    }
  });
}; // Creater

export var createMemberCardAPI = function createMemberCardAPI(param, afterAPI) {
  return axios.post('member_cards', param).then(function (resp) {
    return resp.data;
  }).then(function (resp, reject) {
    if (afterAPI) {
      return afterAPI(resp);
    } else {
      return resp;
    }
  });
};
export var createHoldMemberCardAPI = function createHoldMemberCardAPI(memberID, param, afterAPI) {
  return axios.post('/members/' + memberID + '/hold_member_cards', param).then(function (resp) {
    return resp.data;
  }).then(function (resp, reject) {
    if (afterAPI) {
      return afterAPI(resp);
    } else {
      return resp;
    }
  });
};
export var uploadPhoto = function uploadPhoto(url, formData) {
  return axios.post(url, formData);
};
export var getPhotoListAPI = function getPhotoListAPI() {
  var apiParam = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var afterAPI = arguments[1];
  return axios.get('photos', {
    params: apiParam
  }).then(function (resp) {
    return resp.data;
  }).then(function (resp) {
    if (afterAPI) {
      return afterAPI(resp);
    } else {
      return resp;
    }
  });
};
export var getAlbumListAPI = function getAlbumListAPI() {
  var apiParam = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var afterAPI = arguments[1];
  return axios.get('albums', {
    params: apiParam
  }).then(function (resp) {
    return resp.data;
  }).then(function (resp) {
    if (afterAPI) {
      return afterAPI(resp);
    } else {
      return resp;
    }
  });
};
export var getSocietyListAPI = function getSocietyListAPI() {
  var apiParam = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var afterAPI = arguments[1];
  return axios.get('societies', {
    params: apiParam
  }).then(function (resp) {
    return resp.data;
  }).then(function (resp) {
    if (afterAPI) {
      return afterAPI(resp);
    } else {
      return resp;
    }
  });
}; // Return list of association

export var modelAssociationRefreshAPI = function modelAssociationRefreshAPI(url) {
  var params = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  var afterAPI = arguments[2];
  var promise = void 0;

  if (params.specific === true) {
    promise = axios.post(url, params);
  } else {
    promise = axios.get(url, {
      params: params
    });
  }

  return promise.then(function (resp) {
    return resp.data;
  }).then(function (resp, reject) {
    if (afterAPI) {
      return afterAPI(resp);
    } else {
      return resp;
    }
  });
}; //

export var modelCreateAPI = function modelCreateAPI(url, param, afterAPI) {
  return axios.post(url, param).then(function (resp) {
    return resp.data;
  }).then(function (resp, reject) {
    if (afterAPI) {
      return afterAPI(resp);
    } else {
      return resp;
    }
  });
}; // Get

export var modelRefreshAPI = function modelRefreshAPI(url, id, param) {
  return axios.get(url + '/' + id, {
    params: param
  }).then(function (resp) {
    return resp.data;
  });
}; // Editor

export var modelSavingAPI = function modelSavingAPI(url, id, param) {
  return axios.put(url + '/' + id, param).then(function (resp) {
    return resp.data;
  });
}; // Delete

export var modelDeleteAPI = function modelDeleteAPI(url, id) {
  return axios.delete(url + '/' + id);
};