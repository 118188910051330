import 'array-flat-polyfill';
import "@babel/polyfill";
import * as qurttyApi from './api/api';
import axios from './api/axiosSetting';
import * as qurttyApiHelper from './api/apiHelper';
import * as ormHelper from './orm/ormHelpers';
import * as authHelper from './api/authHelper';
import * as reduxTokenAuth from 'redux-token-auth';
import { schemaModel, session, ormState } from './orm/schemas';
import * as projectHelper from './helpers/projectHelper';
import * as routerHelper from './helpers/routerHelper';
console.log('axios.defaults.baseURL: ', '' + process.env.REACT_APP_API_BASE_URL);
axios.defaults.baseURL = '' + process.env.REACT_APP_API_BASE_URL;
export { qurttyApi, qurttyApiHelper, ormHelper, authHelper, projectHelper, routerHelper, session, reduxTokenAuth, axios };