import React, {Component} from 'react';
import {connect} from 'react-redux';
// import { } from '../../actions/ActionTypes';
import * as actions from 'actions/Action.js';

import Categories from './categories/Categories'
import Phones from './phones/Phones'
import Colorbar from '../../components/colorbar/Colorbar';
import ProductPopup from './productPopup/ProductPopup';
import Loader from 'react-loader-spinner';

import Phone from '../../assets/materials/iphone-x.jpeg';
import Tablet from '../../assets/materials/tablet.jpg';
import Accessory from '../../assets/materials/vr.png';
import Icon from '../../assets/icon/cgt-logo.svg'
import loading_BG from "../../assets/photo/bg-1.jpg"

import {Footer, Header} from '../index.js';

import {SET_PRODUCT, SAVE_PATH} from '../../actions/ActionTypes';

class ProductsTable extends Component {
  constructor(props) {
    super(props);
    // this.state = {
    //   products: [
    //     {
    //       img: Phone,
    //       name: 'Used Mobile Phone',
    //       content: 'The marketing department is dedicated to building ecoATM Gazelle as the leading reCommerce brancom The marketing department is dedicated to building ecoATM Gazelle as the leading reCommerce brancom'
    //     }, {
    //       img: Tablet,
    //       name: 'Tablets',
    //       content: 'The marketing department is dedicated to building ecoATM Gazelle as the leading reCommerce brancom The marketing department is dedicated to building ecoATM Gazelle as the leading reCommerce brancom'
    //     }, {
    //       img: Accessory,
    //       name: 'Accessories',
    //       content: 'The marketing department is dedicated to building ecoATM Gazelle as the leading reCommerce brancom The marketing department is dedicated to building ecoATM Gazelle as the leading reCommerce brancom'
    //     }
    //   ]
    // }

  }

  componentDidMount() {
    this.props.scrollToTopImmediately();

    this.props.loadProducts(this.props.project, this.props.dataSet)
    //save location to this.props.previousPath, the prop is being checked at MainPage.js


    // this.props.loadData();
  }

  componentWillUnmount() {
    this.props.savePath("/product");
  }

  render() {

    if (this.props.categories.length > 0 && this.props.productBanner) {
      //FIX-ME: Should pass the category and find its correcponding brands from previous page
      //console.log(this.props.productBanner);
      let {current_category, current_brand, categories, setProducts} = this.props;

      if (current_category && current_brand) {
      } else {
        const category = categories.find( item => item.name_en === current_category) || categories[0]
        setProducts(category.name_en, category.brands[0]);
      }

      let imgSrc = this.props.productBanner.rows[0][0].values[0] ? this.props.productBanner.rows[0][0].values[0].resized_m_url : null

      return (
        <div id="ProductsTable">
          <Header links={this.props.links} scrollToComponent={this.props.scrollToComponent} linkClickHandler={this.props.linkClickHandler}/>

          <div className="background">
            <img src={imgSrc}/>
            <div className="title">
              {this.props.language === 'zh_hk' ? '產品' : 'PRODUCT'}
            </div>
          </div>
          <div className="container-fluid">
            <div className="row">
              <Categories categories={categories} toggleBackDrop={this.props.toggleBackDrop} scrollToTopImmediately={this.props.scrollToTopImmediately}/>
              <Phones current_brand={current_brand} toggleBackDrop={this.props.toggleBackDrop}/>
            </div>
          </div>

          <Footer links={this.props.links} scrollToComponent={this.props.scrollToComponent} linkClickHandler={this.props.linkClickHandler}/>
        </div>
      )
    } else {
      return (
        <div className="d-flex align-items-center justify-content-center w-100" style={{height: '100vh'}}>
          <img className="loading-bg" src={loading_BG}/>
          <Loader className="loading-spinner" type="Triangle" color="#00d877" height={150} width={150} />
          <div className="loading-icon-container">
            <img className="loading-icon" src={Icon}/>
          </div>
        </div>
      )
    }
  }
}

const mapStateToProps = (state) => {
  return {
    categories: state.uiReducer.categories,
    current_brand: state.uiReducer.current_brand,
    current_category: state.uiReducer.current_category,
    history: state.uiReducer.history,
    previousPath: state.uiReducer.previousPath,
    project: state.dataReducer.project,
    dataSet: state.dataReducer.dataSet,
    productBanner: state.dataReducer.productBanner,
    language: state.uiReducer.language
  };
};

const dispatchToProps = {
  loadProducts: actions.loadProducts,
  setProducts: (category, brand) => (dispatch) => dispatch({type: SET_PRODUCT, category: category, brand: brand}),
  savePath: (path) => (dispatch) => dispatch({type: SAVE_PATH, path: path}),
  loadData: actions.loadData
}

export default connect(mapStateToProps, dispatchToProps)(ProductsTable);
