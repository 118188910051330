import React, {Component} from 'react';
import {connect} from 'react-redux';
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem } from 'reactstrap';
import { TOGGLE_HEADER, SWITCH_LANGUAGE } from '../../actions/ActionTypes'; // ???
import Icon from '../../assets/icon/cgt-logo.svg'

class Header extends Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);

  }

  toggle() {
    this.props.toggleHeader();
  }

  render() {
    let {links, scrollToComponent, linkClickHandler} = this.props;

    // const NavItemJSX = links.map((obj, index) => {
    //   return (
    //     <NavItem key={index}>
    //       <div className="header-Link" onClick={()=>{scrollToComponent(obj.component); linkClickHandler(obj.hash, obj.component)}}>{obj.name}</div>
    //     </NavItem>
    //   )
    // })

    return (
      <div id="Header" >
      <Navbar color="light" light expand="md">
        <img onClick={()=>{scrollToComponent('Header'); linkClickHandler('#home', 'Header')}} className="logo" src={Icon}/>
        <NavbarToggler onClick={this.toggle} />
        <Collapse isOpen={this.props.isHeader} navbar>
          <Nav className="ml-auto header-title" navbar>
            {/*NavItemJSX*/}


          <NavItem>
            <div className="header-Link" onClick={()=>{scrollToComponent(links[0].component); linkClickHandler(links[0].hash, links[0].component)}}>{links[0][`name_${this.props.language}`]}</div>
          </NavItem>

          <UncontrolledDropdown nav inNavbar>
            <DropdownToggle nav caret>
              {this.props.language === 'zh_hk' ? '關於我們' : 'About Us'}
            </DropdownToggle>
            <DropdownMenu >
              <DropdownItem onClick={()=>{scrollToComponent(links[1].component); linkClickHandler(links[1].hash, links[1].component)}}>
                <NavItem>
                  <div className="header-Link" >{links[1][`name_${this.props.language}`]}</div>
                </NavItem>
              </DropdownItem>
              {/*
              <DropdownItem onClick={()=>{scrollToComponent(links[2].component); linkClickHandler(links[2].hash, links[2].component)}}>
                <NavItem>
                  <div className="header-Link" >{links[2][`name_${this.props.language}`]}</div>
                </NavItem>
              </DropdownItem>
              */}
              <DropdownItem onClick={()=>{scrollToComponent(links[3].component); linkClickHandler(links[3].hash, links[3].component)}}>
                <NavItem>
                  <div className="header-Link" >{links[3][`name_${this.props.language}`]}</div>
                </NavItem>
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>

          <UncontrolledDropdown nav inNavbar>
            <DropdownToggle nav caret>
              {this.props.language === 'zh_hk' ? '全球' : 'Worldwide'}
            </DropdownToggle>
            <DropdownMenu >
              <DropdownItem onClick={()=>{scrollToComponent(links[4].component); linkClickHandler(links[4].hash, links[4].component)}}>
                <NavItem>
                  <div className="header-Link" >{links[4][`name_${this.props.language}`]}</div>
                </NavItem>
              </DropdownItem>
              {/*
              <DropdownItem onClick={()=>{scrollToComponent(links[5].component); linkClickHandler(links[5].hash, links[5].component)}}>
                <NavItem>
                  <div className="header-Link" >{links[5][`name_${this.props.language}`]}</div>
                </NavItem>
              </DropdownItem>
              */}
            </DropdownMenu>
          </UncontrolledDropdown>

              <NavItem>
                <div className="header-Link" onClick={()=>{scrollToComponent(links[6].component); linkClickHandler(links[6].hash, links[6].component)}}>{links[6][`name_${this.props.language}`]}</div>
              </NavItem>

          <UncontrolledDropdown nav inNavbar>
            <DropdownToggle nav caret>
              {this.props.language === 'zh_hk' ? '為甚麼選擇我們？' : 'Why Us ?'}
            </DropdownToggle>
            <DropdownMenu>
              {/*
              <DropdownItem onClick={()=>{scrollToComponent(links[7].component); linkClickHandler(links[7].hash, links[7].component)}}>
                <NavItem>
                  <div className="header-Link" >{links[7][`name_${this.props.language}`]}</div>
                </NavItem>
              </DropdownItem>
              */}
              <DropdownItem onClick={()=>{scrollToComponent(links[8].component); linkClickHandler(links[8].hash, links[8].component)}}>
                <NavItem>
                  <div className="header-Link" >{links[8][`name_${this.props.language}`]}</div>
                </NavItem>
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>


            <div className="language-switch-container d-flex justify-content-center align-items-center" onClick={()=>{
              this.props.switchLanguage();
            }}>
              <div className="language-switch">
                {this.props.language === 'zh_hk' ? '中' : 'ENG'}
              </div>
            </div>


          </Nav>
        </Collapse>
      </Navbar>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    history: state.uiReducer.history,
    isHeader: state.uiReducer.isHeader,
    language: state.uiReducer.language
  };
};

const dispatchToProps = {
  toggleHeader: (bool) => (dispatch) => dispatch({type: TOGGLE_HEADER, bool: bool}),
  switchLanguage: () => (dispatch) => dispatch({type: SWITCH_LANGUAGE}),
}

export default connect(mapStateToProps, dispatchToProps)(Header);
