var _class, _temp;

function _classCallCheck(instance, Constructor) {
  if (!(instance instanceof Constructor)) {
    throw new TypeError("Cannot call a class as a function");
  }
}

function _possibleConstructorReturn(self, call) {
  if (!self) {
    throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
  }

  return call && (typeof call === "object" || typeof call === "function") ? call : self;
}

function _inherits(subClass, superClass) {
  if (typeof superClass !== "function" && superClass !== null) {
    throw new TypeError("Super expression must either be null or a function, not " + typeof superClass);
  }

  subClass.prototype = Object.create(superClass && superClass.prototype, {
    constructor: {
      value: subClass,
      enumerable: false,
      writable: true,
      configurable: true
    }
  });
  if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass;
}

import { QurttyModel } from './modelDefault';
import { fk, many, oneToOne, attr, Model, ORM } from 'redux-orm';
var Product = (_temp = _class = function (_QurttyModel) {
  _inherits(Product, _QurttyModel);

  function Product() {
    _classCallCheck(this, Product);

    return _possibleConstructorReturn(this, _QurttyModel.apply(this, arguments));
  }

  return Product;
}(QurttyModel), _class.modelName = 'Product', _class.fields = {
  photos: many('Photo'),
  photoable_photos: many('PhotoablePhoto'),
  extension_entity: oneToOne('Eav_Entity')
}, _temp);
export var productModelRegister = function productModelRegister(orm) {
  orm.register(Product);
};