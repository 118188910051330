import * as reduxTokenAuth from 'redux-token-auth';
import { deleteAuthHeaders, deleteAuthHeadersFromDeviceStorage, getUserAttributesFromResponse, persistAuthHeadersInDeviceStorage, setAuthHeaders } from 'redux-token-auth/dist/services/auth';
import AsyncLocalStorage from 'redux-token-auth/dist/AsyncLocalStorage';
var axios = reduxTokenAuth.axios;
axios.interceptors.request.use(function (config) {
  return config;
}, function (error) {
  return Promise.reject(error);
});
axios.interceptors.response.use(function (response) {
  setAuthHeaders(response.headers);
  persistAuthHeadersInDeviceStorage(AsyncLocalStorage, response.headers); // setAuthHeaders() for subsequent requests
  // persistAuthHeadersInDeviceStorage() for the first verifyToken() after browser refresh

  return response;
}, function (error) {
  setAuthHeaders(error.response.headers);
  persistAuthHeadersInDeviceStorage(AsyncLocalStorage, error.response.headers);
  return Promise.reject(error);
});
export default axios;