import React, {Component} from 'react';
import {connect} from 'react-redux';
import { } from '../../actions/ActionTypes';

class Colorbar extends Component {

  render() {
    return (
      <div
        id="Colorbar"
        style={this.props.isWhite ?
          {background: 'white', width: this.props.width ? this.props.width : '120px' }
          :
          {width: this.props.width ? this.props.width : '120px'}}
      >

      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {

  };
};

const dispatchToProps = {
}

export default connect(mapStateToProps, dispatchToProps)(Colorbar);
