// resources url -> modelName
export var doubleAPILookupList = {
  received_friend_cards: {
    modelName: 'MemberCard'
  },
  card_addresses: {
    modelName: 'Address'
  },
  card_emails: {
    modelName: 'Email'
  },
  card_phones: {
    modelName: 'Phone'
  },
  participant_docs: {
    modelName: 'Doc'
  },
  'paper_trail/versions': {
    modelName: 'Version'
  },
  academic_records: {
    modelName: 'Recruit_AcademicRecord'
  },
  awards: {
    modelName: 'Recruit_Award'
  },
  questions: {
    modelName: 'Recruit_Question'
  },
  work_experiences: {
    modelName: 'Recruit_WorkExperience' // special association name -> resources url

  }
};
export var associationNameLookupList = {
  hold_member_cards: 'member_cards',
  requested_friendships: 'friendships',
  bag_photo_participations: 'photo_participations',
  structures: 'eav/structures',
  entities: 'eav/entities',
  attrs: 'eav/attrs',
  values: 'eav/values',
  entity_permissions: 'eav/entity_permissions',
  entity_comments: 'eav/entity_comments',
  child_departments: 'departments',
  versions: 'paper_trail/versions'
};
export var modelClassToModelNameLookupList = {
  'Card::Phone': 'Phone',
  'Card::Address': 'Address',
  'Card::Email': 'Email',
  'Card::Website': 'Website'
};
export var modelNamePolymorphicLookupList = {
  PhoneExposure: ['MemberCardPhoneExposure', 'CompanyCardPhoneExposure', 'BusinessCardPhoneExposure'],
  AddressExposure: ['MemberCardAddressExposure', 'CompanyCardAddressExposure', 'BusinessCardAddressExposure'],
  EmailExposure: ['MemberCardEmailExposure', 'CompanyCardEmailExposure', 'BusinessCardEmailExposure'],
  Website: ['ΔMemberCardΔWebsite', 'ΔCompanyCardΔWebsite', 'ΔBusinessCardΔWebsite'],
  PhotoParticipation: ['PhotoParticipationInMember', 'PhotoParticipationInProject', 'PhotoParticipationInDoc', 'PhotoParticipationInEav_Structure', 'PhotoParticipationInCompany'],
  PhotoablePhotoParticipation: ['MemberCardPhotoParticipationInMember', 'CompanyCardPhotoParticipationInMember', 'MemberCardPhotoParticipationInCompany', 'CompanyCardPhotoParticipationInCompany'],
  Version: ['ΔPhotoΔVersion', 'ΔMemberCardΔVersion', 'ΔCompanyCardΔVersion', 'ΔBusinessCardΔVersion', 'ΔProductΔVersion', 'ΔWebsiteΔVersion', 'ΔPhoneΔVersion', 'ΔPhoneExposureΔVersion', 'ΔAddressΔVersion', 'ΔAddressExposureΔVersion', 'ΔEmailΔVersion', 'ΔEmailExposureΔVersion', 'ΔEav_ValueΔVersion']
};