import {STORE_API_DATA_TO_DATA_REDUCER, STORE_PRODUCT_BANNER_TO_DATA_REDUCER} from 'actions/ActionTypes';

const initialState = {
  dataSet: null,
  project: null,
  productBanner: null
};

const dataReducer = (state = initialState, action) => {
  switch (action.type) {
    case STORE_API_DATA_TO_DATA_REDUCER: {
      console.log(action.payload.data)
      return {
        ...state,
        dataSet: action.payload.data,
        project: action.payload.project
      }
    }
    case STORE_PRODUCT_BANNER_TO_DATA_REDUCER: {
      return {
        ...state,
        productBanner: action.payload,
      }
    }

    default:
      return state;
  }
}

export default dataReducer;
