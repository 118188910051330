import React, {Component} from 'react';
import {connect} from 'react-redux';
import { } from '../../../actions/ActionTypes';

class AwardCard extends Component {
  constructor(props){
    super(props);
    this.state = {
      isFullText: false
    }
  }

  scrollToComponent(id) {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ block: 'start',  behavior: 'smooth' });
    }
  }

  render() {
    let imgSrc = this.props.item[0].values[0] ? this.props.item[0].values[0].resized_xs_url : null
    return (
      <div id="AwardCard" className={`mx-md-2 d-flex flex-column align-items-center ${this.state.isFullText ?  'full-text' : ''}`} >
        <img src={imgSrc} />

          <div className="name font-18">
            {this.props.item[1].values[0][`content_string_${this.props.language}`]}
          </div>
          <div className={`text font-10 ${this.state.isFullText ?  'full-text' : ''}`}>
            {this.state.isFullText ? this.props.item[3].values[0][`content_text_${this.props.language}`] : this.props.item[2].values[0][`content_text_${this.props.language}`]}
          </div>
          <div className="button" onClick={()=>{
            this.setState({isFullText: !this.state.isFullText});
            // this.scrollToComponent('Award');
          }}> {this.state.isFullText ?  'Less' : 'More'} </div>
        </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    language: state.uiReducer.language
  };
};

const dispatchToProps = {
}

export default connect(mapStateToProps, dispatchToProps)(AwardCard);
