function _classCallCheck(instance, Constructor) {
  if (!(instance instanceof Constructor)) {
    throw new TypeError("Cannot call a class as a function");
  }
}

function _possibleConstructorReturn(self, call) {
  if (!self) {
    throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
  }

  return call && (typeof call === "object" || typeof call === "function") ? call : self;
}

function _inherits(subClass, superClass) {
  if (typeof superClass !== "function" && superClass !== null) {
    throw new TypeError("Super expression must either be null or a function, not " + typeof superClass);
  }

  subClass.prototype = Object.create(superClass && superClass.prototype, {
    constructor: {
      value: subClass,
      enumerable: false,
      writable: true,
      configurable: true
    }
  });
  if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass;
}

import { Model } from 'redux-orm';
import pluralize from 'pluralize';
import _ from 'lodash';
import { modelRefresh, modelSaving, modelSavingChanges, modelDelete, modelCreate, modelPhotoUpload, modelAssociationRefresh, doubleModelAssociationRefresh } from '../ormHelpers';
export var QurttyModel = function (_Model) {
  _inherits(QurttyModel, _Model);

  function QurttyModel() {
    _classCallCheck(this, QurttyModel);

    for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    var _this = _possibleConstructorReturn(this, _Model.call.apply(_Model, [this].concat(args)));

    _this.modelUrl = pluralize(_.snakeCase(_this.constructor.modelName.replace('_', '00')).replace('_00_', '/'));
    _this.$_changes = {};
    return _this;
  }
  /**
   * GET this model instance by id
   * @param  {Object} [params={}] qurty params
   * @return {Promise}             a promise of the updated model instance
   */


  QurttyModel.prototype.$refresh = function $refresh() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return modelRefresh(this.modelUrl, this, params);
  };

  QurttyModel.prototype.$refreshAssociation = function $refreshAssociation(associationName, params) {
    return modelAssociationRefresh(this.modelUrl, associationName, this, params);
  };

  QurttyModel.prototype.$doubleRefreshAssociation = function $doubleRefreshAssociation(associationName, params) {
    return doubleModelAssociationRefresh(this.modelUrl, associationName, this, params);
  };
  /**
   * PUT properties you want
   * @param  {Object or Array} args {name: 'Sherman', gender: 'unknown'} or ['name', 'gender']
   * @return {Promise}      a promise of saved model instance
   */


  QurttyModel.prototype.$save = function $save() {
    return modelSaving(this.modelUrl, this.id, this).apply(undefined, arguments);
  };
  /**
   * PUT changed properties only (from $_changes)
   * @param  {Array} args e.g. ['name', 'gender'], will PUT only changed properties
   * @return {Promise}      a promise of saved model instance
   */


  QurttyModel.prototype.$saveChanges = function $saveChanges() {
    //console.log(args)
    return modelSavingChanges(this.modelUrl, this.id, this).apply(undefined, arguments);
  };
  /**
   * DELETE the model instance
   * @return {Promise} of the deleted model instance
   */


  QurttyModel.prototype.$destroy = function $destroy() {
    return modelDelete(this.modelUrl, this.id, this)();
  };
  /**
   * POST a new model instance
   * @param  {String} associationName the nested resource path given by API doc
   * @param  {Object} params          request body
   * @return {Promise}                 of the created model instance
   */


  QurttyModel.prototype.$createAssociation = function $createAssociation(associationName, params) {
    return modelCreate(associationName, this, params);
  };

  QurttyModel.prototype.$createAss = function $createAss() {
    return this.$createAssociation.apply(this, arguments);
  };
  /**
   * POST new Photo model instance(s)
   * @param  {String} associationName the nested resource path given by API doc
   * @param  {Array} fileList        array of photo files
   * @param  {Object} params          additional request body
   * @return {Promise}                 of the created Photo model instances
   */


  QurttyModel.prototype.$createPhotoAssociation = function $createPhotoAssociation(associationName, fileList, params) {
    var url = this.modelUrl + '/' + this.id + '/' + associationName;
    return modelPhotoUpload(url, fileList, params);
  };

  QurttyModel.prototype.getAssociation = function getAssociation(attributeName, filterArg) {
    if (filterArg) {
      return this[attributeName].filter(filterArg).orderBy('display_order', 'asc').toModelArray();
    } else {
      return this[attributeName].orderBy('display_order', 'asc').toModelArray();
    }
  };

  QurttyModel.prototype.getAss = function getAss(attributeName, filterArg) {
    return this.getAssociation(attributeName, filterArg);
  };

  QurttyModel.prototype.$refreshAss = function $refreshAss() {
    return this.$refreshAssociation.apply(this, arguments);
  };

  QurttyModel.prototype.$doubleRefreshAss = function $doubleRefreshAss() {
    return this.$doubleRefreshAssociation.apply(this, arguments);
  };

  QurttyModel.prototype.originModelName = function originModelName() {
    var proto = this;
    var name = proto.constructor.name;

    while (name !== 'QurttyModel' || name.includes('Δ')) {
      // to delete all inherited parents in orm
      proto = Object.getPrototypeOf(proto);
      name = proto.constructor.name;
    }

    return name;
  };

  return QurttyModel;
}(Model);