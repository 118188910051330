import React, {Component} from 'react';
import {connect} from 'react-redux';
import { } from '../../actions/ActionTypes';
import Colorbar from '../../components/colorbar/Colorbar';
import Supplier1 from '../../assets/materials/supplier1.jpg';

class Suppliers extends Component {

  render() {
    // console.log(this.props.data);
    const data = this.props.data;
    const heading = data[`heading_${this.props.language}`];
    const subheading = data[`subheading_${this.props.language}`]; const subsubheading = data[`subsubheading_${this.props.language}`]

    const partners = data.rows[0][0].values;

    if (this.props.data.is_hidden) {
      return (<div></div>)
    } else {
      return (
        <div id="Suppliers" >
          <div className="py-4 px-3 py-md-5 px-md-5 d-flex flex-column align-items-center">
            <h6 className="session font-weight-normal">{heading}</h6>
            <h2 className="title">{subheading}</h2>
            <Colorbar/>
            <div className="subsubheading font-12 mt-3"> {subsubheading} </div>

            <div className="supplier-container pt-4 pt-md-5 py-md-3 d-flex flex-row row justify-content-center">

              {partners.map((item, index) =>
                <img key={index} className="px-1 px-md-3" src={item.photo ? item.photo.resized_s_url : null} />
              )}

            </div>

          </div>
        </div>
      )
    }
  }
}

const mapStateToProps = (state) => {
  return {
    language: state.uiReducer.language,
    data: state.dataReducer.dataSet.partners
  };
};

const dispatchToProps = {
}

export default connect(mapStateToProps, dispatchToProps)(Suppliers);
