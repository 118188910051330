var _class, _temp, _class2, _temp2;

function _classCallCheck(instance, Constructor) {
  if (!(instance instanceof Constructor)) {
    throw new TypeError("Cannot call a class as a function");
  }
}

function _possibleConstructorReturn(self, call) {
  if (!self) {
    throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
  }

  return call && (typeof call === "object" || typeof call === "function") ? call : self;
}

function _inherits(subClass, superClass) {
  if (typeof superClass !== "function" && superClass !== null) {
    throw new TypeError("Super expression must either be null or a function, not " + typeof superClass);
  }

  subClass.prototype = Object.create(superClass && superClass.prototype, {
    constructor: {
      value: subClass,
      enumerable: false,
      writable: true,
      configurable: true
    }
  });
  if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass;
}

import { QurttyModel } from './modelDefault';
import { fk, many, oneToOne, attr } from 'redux-orm';
var Employment = (_temp = _class = function (_QurttyModel) {
  _inherits(Employment, _QurttyModel);

  function Employment() {
    _classCallCheck(this, Employment);

    return _possibleConstructorReturn(this, _QurttyModel.apply(this, arguments));
  }

  return Employment;
}(QurttyModel), _class.modelName = 'Employment', _class.fields = {
  company_id: fk({
    to: 'Company',
    as: 'company',
    relatedName: 'employments'
  }),
  employee_master_id: fk({
    to: 'Member',
    as: 'employee_master',
    relatedName: 'employments'
  })
}, _temp);
var EmploymentDepartment = (_temp2 = _class2 = function (_QurttyModel2) {
  _inherits(EmploymentDepartment, _QurttyModel2);

  function EmploymentDepartment() {
    _classCallCheck(this, EmploymentDepartment);

    return _possibleConstructorReturn(this, _QurttyModel2.apply(this, arguments));
  }

  return EmploymentDepartment;
}(QurttyModel), _class2.modelName = 'EmploymentDepartment', _class2.fields = {
  employment_id: fk({
    to: 'Employment',
    as: 'employment',
    relatedName: 'employment_departments'
  }),
  department_id: fk({
    to: 'Department',
    as: 'department',
    relatedName: 'employment_departments'
  })
}, _temp2);
export var employmentModelRegister = function employmentModelRegister(orm) {
  orm.register(Employment, EmploymentDepartment);
};