import { ORM } from 'redux-orm';
import './models/modelDefault';
import { cardModelRegister } from './models/cardModel';
import { projectModelRegister } from './models/projectModel';
import { exposuresModelRegister } from './models/exposuresModel';
import { photoModelRegister } from './models/photoModel';
import { productModelRegister } from './models/productModel';
import { friendModelRegister } from './models/friendModel';
import { invitationModelRegister } from './models/invitationModel';
import { memberModelRegister } from './models/memberModel';
import { companyModelRegister } from './models/companyModel';
import { employmentModelRegister } from './models/employmentModel';
import { versionModelRegister } from './models/versionModel';
import { hkPostalModelRegister } from './models/hkPostalModel';
import { recruitModelRegister } from './models/recruitModel';
var orm = new ORM();
hkPostalModelRegister(orm);
memberModelRegister(orm);
companyModelRegister(orm);
cardModelRegister(orm);
projectModelRegister(orm);
exposuresModelRegister(orm);
photoModelRegister(orm);
productModelRegister(orm);
friendModelRegister(orm);
invitationModelRegister(orm);
employmentModelRegister(orm);
versionModelRegister(orm);
recruitModelRegister(orm);
export { orm };