var _class, _temp2, _class2, _temp3, _class3, _temp4, _class4, _temp5, _class5, _temp7, _class6, _temp8, _class7, _temp9, _class8, _temp11, _class9, _temp12, _class10, _temp13, _class11, _temp14, _class12, _temp16, _class13, _temp18, _class14, _temp19, _class15, _temp20, _class16, _temp21, _class17, _temp23, _class18, _temp25, _class19, _temp26, _class20, _temp27, _class21, _temp28;

function _classCallCheck(instance, Constructor) {
  if (!(instance instanceof Constructor)) {
    throw new TypeError("Cannot call a class as a function");
  }
}

function _possibleConstructorReturn(self, call) {
  if (!self) {
    throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
  }

  return call && (typeof call === "object" || typeof call === "function") ? call : self;
}

function _inherits(subClass, superClass) {
  if (typeof superClass !== "function" && superClass !== null) {
    throw new TypeError("Super expression must either be null or a function, not " + typeof superClass);
  }

  subClass.prototype = Object.create(superClass && superClass.prototype, {
    constructor: {
      value: subClass,
      enumerable: false,
      writable: true,
      configurable: true
    }
  });
  if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass;
}

import { QurttyModel } from './modelDefault';
import { fk, many, oneToOne, attr, Model, ORM } from 'redux-orm'; // Exposures Start

var AddressExposure = (_temp2 = _class = function (_QurttyModel) {
  _inherits(AddressExposure, _QurttyModel);

  function AddressExposure() {
    var _temp, _this, _ret;

    _classCallCheck(this, AddressExposure);

    for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    return _ret = (_temp = (_this = _possibleConstructorReturn(this, _QurttyModel.call.apply(_QurttyModel, [this].concat(args))), _this), _this.modelUrl = 'card/address_exposures', _temp), _possibleConstructorReturn(_this, _ret);
  }

  return AddressExposure;
}(QurttyModel), _class.modelName = 'AddressExposure', _class.fields = {
  address_id: fk({
    to: 'Address',
    as: 'address',
    relatedName: 'address_exposures'
  })
}, _temp2);
var MemberCardAddressExposure = (_temp3 = _class2 = function (_AddressExposure) {
  _inherits(MemberCardAddressExposure, _AddressExposure);

  function MemberCardAddressExposure() {
    _classCallCheck(this, MemberCardAddressExposure);

    return _possibleConstructorReturn(this, _AddressExposure.apply(this, arguments));
  }

  return MemberCardAddressExposure;
}(AddressExposure), _class2.modelName = 'MemberCardAddressExposure', _class2.fields = {
  card_id: fk({
    to: 'MemberCard',
    as: 'card',
    relatedName: 'address_exposures'
  })
}, _temp3);
var CompanyCardAddressExposure = (_temp4 = _class3 = function (_AddressExposure2) {
  _inherits(CompanyCardAddressExposure, _AddressExposure2);

  function CompanyCardAddressExposure() {
    _classCallCheck(this, CompanyCardAddressExposure);

    return _possibleConstructorReturn(this, _AddressExposure2.apply(this, arguments));
  }

  return CompanyCardAddressExposure;
}(AddressExposure), _class3.modelName = 'CompanyCardAddressExposure', _class3.fields = {
  card_id: fk({
    to: 'CompanyCard',
    as: 'card',
    relatedName: 'address_exposures'
  })
}, _temp4);
var BusinessCardAddressExposure = (_temp5 = _class4 = function (_AddressExposure3) {
  _inherits(BusinessCardAddressExposure, _AddressExposure3);

  function BusinessCardAddressExposure() {
    _classCallCheck(this, BusinessCardAddressExposure);

    return _possibleConstructorReturn(this, _AddressExposure3.apply(this, arguments));
  }

  return BusinessCardAddressExposure;
}(AddressExposure), _class4.modelName = 'BusinessCardAddressExposure', _class4.fields = {
  card_id: fk({
    to: 'BusinessCard',
    as: 'card',
    relatedName: 'address_exposures'
  })
}, _temp5);
var Address = (_temp7 = _class5 = function (_QurttyModel2) {
  _inherits(Address, _QurttyModel2);

  function Address() {
    var _temp6, _this5, _ret2;

    _classCallCheck(this, Address);

    for (var _len2 = arguments.length, args = Array(_len2), _key2 = 0; _key2 < _len2; _key2++) {
      args[_key2] = arguments[_key2];
    }

    return _ret2 = (_temp6 = (_this5 = _possibleConstructorReturn(this, _QurttyModel2.call.apply(_QurttyModel2, [this].concat(args))), _this5), _this5.modelUrl = 'card/addresses', _temp6), _possibleConstructorReturn(_this5, _ret2);
  }

  return Address;
}(QurttyModel), _class5.modelName = 'Address', _class5.fields = {
  member_id: fk({
    to: 'Member',
    as: 'member',
    relatedName: 'card_addresses'
  }),
  company_id: fk({
    to: 'Company',
    as: 'company',
    relatedName: 'card_addresses'
  }),
  asset_pool_id: fk({
    to: 'AssetPool',
    as: 'asset_pool',
    relatedName: 'card_addresses'
  })
}, _temp7);
var ParsedAddress = (_temp8 = _class6 = function (_QurttyModel3) {
  _inherits(ParsedAddress, _QurttyModel3);

  function ParsedAddress() {
    _classCallCheck(this, ParsedAddress);

    return _possibleConstructorReturn(this, _QurttyModel3.apply(this, arguments));
  }

  return ParsedAddress;
}(QurttyModel), _class6.modelName = 'ParsedAddress', _class6.fields = {
  address_id: fk({
    to: 'Address',
    as: 'address',
    relatedName: 'parsed_addresses'
  })
}, _temp8);
var HkPostal_Address = (_temp9 = _class7 = function (_QurttyModel4) {
  _inherits(HkPostal_Address, _QurttyModel4);

  function HkPostal_Address() {
    _classCallCheck(this, HkPostal_Address);

    return _possibleConstructorReturn(this, _QurttyModel4.apply(this, arguments));
  }

  return HkPostal_Address;
}(QurttyModel), _class7.modelName = 'HkPostal_Address', _class7.fields = {
  card_address_id: fk({
    to: 'Address',
    as: '_address',
    relatedName: 'hk_postal_addresses'
  }),
  district_id: fk({
    to: 'HkPostal_District',
    as: '_district',
    relatedName: 'hk_postal_addresses'
  }),
  street_id: fk({
    to: 'HkPostal_Street',
    as: '_street',
    relatedName: 'hk_postal_addresses'
  }),
  street_number_id: fk({
    to: 'HkPostal_StreetNumber',
    as: '_street_number',
    relatedName: 'hk_postal_addresses'
  }),
  estate_id: fk({
    to: 'HkPostal_Estate',
    as: '_estate',
    relatedName: 'hk_postal_addresses'
  }),
  phase_id: fk({
    to: 'HkPostal_Phase',
    as: '_phase',
    relatedName: 'hk_postal_addresses'
  }),
  building_id: fk({
    to: 'HkPostal_Building',
    as: '_building',
    relatedName: 'hk_postal_addresses'
  }),
  floor_id: fk({
    to: 'HkPostal_Floor',
    as: '_floor',
    relatedName: 'hk_postal_addresses'
  }),
  unit_id: fk({
    to: 'HkPostal_Unit',
    as: '_unit',
    relatedName: 'hk_postal_addresses'
  })
}, _temp9);
var EmailExposure = (_temp11 = _class8 = function (_QurttyModel5) {
  _inherits(EmailExposure, _QurttyModel5);

  function EmailExposure() {
    var _temp10, _this8, _ret3;

    _classCallCheck(this, EmailExposure);

    for (var _len3 = arguments.length, args = Array(_len3), _key3 = 0; _key3 < _len3; _key3++) {
      args[_key3] = arguments[_key3];
    }

    return _ret3 = (_temp10 = (_this8 = _possibleConstructorReturn(this, _QurttyModel5.call.apply(_QurttyModel5, [this].concat(args))), _this8), _this8.modelUrl = 'card/email_exposures', _temp10), _possibleConstructorReturn(_this8, _ret3);
  }

  return EmailExposure;
}(QurttyModel), _class8.modelName = 'EmailExposure', _class8.fields = {
  email_id: fk({
    to: 'Email',
    as: 'email',
    relatedName: 'email_exposures'
  })
}, _temp11);
var MemberCardEmailExposure = (_temp12 = _class9 = function (_EmailExposure) {
  _inherits(MemberCardEmailExposure, _EmailExposure);

  function MemberCardEmailExposure() {
    _classCallCheck(this, MemberCardEmailExposure);

    return _possibleConstructorReturn(this, _EmailExposure.apply(this, arguments));
  }

  return MemberCardEmailExposure;
}(EmailExposure), _class9.modelName = 'MemberCardEmailExposure', _class9.fields = {
  card_id: fk({
    to: 'MemberCard',
    as: 'card',
    relatedName: 'email_exposures'
  })
}, _temp12);
var CompanyCardEmailExposure = (_temp13 = _class10 = function (_EmailExposure2) {
  _inherits(CompanyCardEmailExposure, _EmailExposure2);

  function CompanyCardEmailExposure() {
    _classCallCheck(this, CompanyCardEmailExposure);

    return _possibleConstructorReturn(this, _EmailExposure2.apply(this, arguments));
  }

  return CompanyCardEmailExposure;
}(EmailExposure), _class10.modelName = 'CompanyCardEmailExposure', _class10.fields = {
  card_id: fk({
    to: 'CompanyCard',
    as: 'card',
    relatedName: 'email_exposures'
  })
}, _temp13);
var BusinessCardEmailExposure = (_temp14 = _class11 = function (_EmailExposure3) {
  _inherits(BusinessCardEmailExposure, _EmailExposure3);

  function BusinessCardEmailExposure() {
    _classCallCheck(this, BusinessCardEmailExposure);

    return _possibleConstructorReturn(this, _EmailExposure3.apply(this, arguments));
  }

  return BusinessCardEmailExposure;
}(EmailExposure), _class11.modelName = 'BusinessCardEmailExposure', _class11.fields = {
  card_id: fk({
    to: 'BusinessCard',
    as: 'card',
    relatedName: 'email_exposures'
  })
}, _temp14);
var Email = (_temp16 = _class12 = function (_QurttyModel6) {
  _inherits(Email, _QurttyModel6);

  function Email() {
    var _temp15, _this12, _ret4;

    _classCallCheck(this, Email);

    for (var _len4 = arguments.length, args = Array(_len4), _key4 = 0; _key4 < _len4; _key4++) {
      args[_key4] = arguments[_key4];
    }

    return _ret4 = (_temp15 = (_this12 = _possibleConstructorReturn(this, _QurttyModel6.call.apply(_QurttyModel6, [this].concat(args))), _this12), _this12.modelUrl = 'card/emails', _temp15), _possibleConstructorReturn(_this12, _ret4);
  }

  return Email;
}(QurttyModel), _class12.modelName = 'Email', _class12.fields = {
  member_id: fk({
    to: 'Member',
    as: 'member',
    relatedName: 'card_emails'
  }),
  company_id: fk({
    to: 'Company',
    as: 'company',
    relatedName: 'card_emails'
  }),
  asset_pool_id: fk({
    to: 'AssetPool',
    as: 'asset_pool',
    relatedName: 'card_emails'
  })
}, _temp16);
var PhoneExposure = (_temp18 = _class13 = function (_QurttyModel7) {
  _inherits(PhoneExposure, _QurttyModel7);

  function PhoneExposure() {
    var _temp17, _this13, _ret5;

    _classCallCheck(this, PhoneExposure);

    for (var _len5 = arguments.length, args = Array(_len5), _key5 = 0; _key5 < _len5; _key5++) {
      args[_key5] = arguments[_key5];
    }

    return _ret5 = (_temp17 = (_this13 = _possibleConstructorReturn(this, _QurttyModel7.call.apply(_QurttyModel7, [this].concat(args))), _this13), _this13.modelUrl = 'card/phone_exposures', _temp17), _possibleConstructorReturn(_this13, _ret5);
  }

  return PhoneExposure;
}(QurttyModel), _class13.modelName = 'PhoneExposure', _class13.fields = {
  phone_id: fk({
    to: 'Phone',
    as: 'phone',
    relatedName: 'phone_exposures'
  })
}, _temp18);
var MemberCardPhoneExposure = (_temp19 = _class14 = function (_PhoneExposure) {
  _inherits(MemberCardPhoneExposure, _PhoneExposure);

  function MemberCardPhoneExposure() {
    _classCallCheck(this, MemberCardPhoneExposure);

    return _possibleConstructorReturn(this, _PhoneExposure.apply(this, arguments));
  }

  return MemberCardPhoneExposure;
}(PhoneExposure), _class14.modelName = 'MemberCardPhoneExposure', _class14.fields = {
  card_id: fk({
    to: 'MemberCard',
    as: 'card',
    relatedName: 'phone_exposures'
  })
}, _temp19);
var CompanyCardPhoneExposure = (_temp20 = _class15 = function (_PhoneExposure2) {
  _inherits(CompanyCardPhoneExposure, _PhoneExposure2);

  function CompanyCardPhoneExposure() {
    _classCallCheck(this, CompanyCardPhoneExposure);

    return _possibleConstructorReturn(this, _PhoneExposure2.apply(this, arguments));
  }

  return CompanyCardPhoneExposure;
}(PhoneExposure), _class15.modelName = 'CompanyCardPhoneExposure', _class15.fields = {
  card_id: fk({
    to: 'CompanyCard',
    as: 'card',
    relatedName: 'phone_exposures'
  })
}, _temp20);
var BusinessCardPhoneExposure = (_temp21 = _class16 = function (_PhoneExposure3) {
  _inherits(BusinessCardPhoneExposure, _PhoneExposure3);

  function BusinessCardPhoneExposure() {
    _classCallCheck(this, BusinessCardPhoneExposure);

    return _possibleConstructorReturn(this, _PhoneExposure3.apply(this, arguments));
  }

  return BusinessCardPhoneExposure;
}(PhoneExposure), _class16.modelName = 'BusinessCardPhoneExposure', _class16.fields = {
  card_id: fk({
    to: 'BusinessCard',
    as: 'card',
    relatedName: 'phone_exposures'
  })
}, _temp21);
var Phone = (_temp23 = _class17 = function (_QurttyModel8) {
  _inherits(Phone, _QurttyModel8);

  function Phone() {
    var _temp22, _this17, _ret6;

    _classCallCheck(this, Phone);

    for (var _len6 = arguments.length, args = Array(_len6), _key6 = 0; _key6 < _len6; _key6++) {
      args[_key6] = arguments[_key6];
    }

    return _ret6 = (_temp22 = (_this17 = _possibleConstructorReturn(this, _QurttyModel8.call.apply(_QurttyModel8, [this].concat(args))), _this17), _this17.modelUrl = 'card/phones', _temp22), _possibleConstructorReturn(_this17, _ret6);
  }

  return Phone;
}(QurttyModel), _class17.modelName = 'Phone', _class17.fields = {
  member_id: fk({
    to: 'Member',
    as: 'member',
    relatedName: 'card_phones'
  }),
  company_id: fk({
    to: 'Company',
    as: 'company',
    relatedName: 'card_phones'
  }),
  asset_pool_id: fk({
    to: 'AssetPool',
    as: 'asset_pool',
    relatedName: 'card_phones'
  })
}, _temp23);
var Website = (_temp25 = _class18 = function (_QurttyModel9) {
  _inherits(Website, _QurttyModel9);

  function Website() {
    var _temp24, _this18, _ret7;

    _classCallCheck(this, Website);

    for (var _len7 = arguments.length, args = Array(_len7), _key7 = 0; _key7 < _len7; _key7++) {
      args[_key7] = arguments[_key7];
    }

    return _ret7 = (_temp24 = (_this18 = _possibleConstructorReturn(this, _QurttyModel9.call.apply(_QurttyModel9, [this].concat(args))), _this18), _this18.modelUrl = 'card/websites', _temp24), _possibleConstructorReturn(_this18, _ret7);
  }

  return Website;
}(QurttyModel), _class18.modelName = 'Website', _class18.fields = {}, _temp25);
var ΔMemberCardΔWebsite = (_temp26 = _class19 = function (_Website) {
  _inherits(ΔMemberCardΔWebsite, _Website);

  function ΔMemberCardΔWebsite() {
    _classCallCheck(this, ΔMemberCardΔWebsite);

    return _possibleConstructorReturn(this, _Website.apply(this, arguments));
  }

  return ΔMemberCardΔWebsite;
}(Website), _class19.modelName = 'ΔMemberCardΔWebsite', _class19.fields = {
  card_id: fk({
    to: 'MemberCard',
    as: 'card',
    relatedName: 'websites'
  })
}, _temp26);
var ΔCompanyCardΔWebsite = (_temp27 = _class20 = function (_Website2) {
  _inherits(ΔCompanyCardΔWebsite, _Website2);

  function ΔCompanyCardΔWebsite() {
    _classCallCheck(this, ΔCompanyCardΔWebsite);

    return _possibleConstructorReturn(this, _Website2.apply(this, arguments));
  }

  return ΔCompanyCardΔWebsite;
}(Website), _class20.modelName = 'ΔCompanyCardΔWebsite', _class20.fields = {
  card_id: fk({
    to: 'CompanyCard',
    as: 'card',
    relatedName: 'websites'
  })
}, _temp27);
var ΔBusinessCardΔWebsite = (_temp28 = _class21 = function (_Website3) {
  _inherits(ΔBusinessCardΔWebsite, _Website3);

  function ΔBusinessCardΔWebsite() {
    _classCallCheck(this, ΔBusinessCardΔWebsite);

    return _possibleConstructorReturn(this, _Website3.apply(this, arguments));
  }

  return ΔBusinessCardΔWebsite;
}(Website), _class21.modelName = 'ΔBusinessCardΔWebsite', _class21.fields = {
  card_id: fk({
    to: 'BusinessCard',
    as: 'card',
    relatedName: 'websites'
  })
}, _temp28);
export var exposuresModelRegister = function exposuresModelRegister(orm) {
  orm.register(Phone, PhoneExposure, MemberCardPhoneExposure, CompanyCardPhoneExposure, BusinessCardPhoneExposure, ParsedAddress, HkPostal_Address, Address, AddressExposure, MemberCardAddressExposure, CompanyCardAddressExposure, BusinessCardAddressExposure, Email, EmailExposure, MemberCardEmailExposure, CompanyCardEmailExposure, BusinessCardEmailExposure, Website, ΔMemberCardΔWebsite, ΔCompanyCardΔWebsite, ΔBusinessCardΔWebsite);
};