var _class, _temp, _class2, _temp2, _class3, _temp3, _class4, _temp4, _class5, _temp5, _class6, _temp6, _class7, _temp7, _class8, _temp8, _class9, _temp9, _class10, _temp10, _class11, _temp11, _class12, _temp12, _class13, _temp13;

function _classCallCheck(instance, Constructor) {
  if (!(instance instanceof Constructor)) {
    throw new TypeError("Cannot call a class as a function");
  }
}

function _possibleConstructorReturn(self, call) {
  if (!self) {
    throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
  }

  return call && (typeof call === "object" || typeof call === "function") ? call : self;
}

function _inherits(subClass, superClass) {
  if (typeof superClass !== "function" && superClass !== null) {
    throw new TypeError("Super expression must either be null or a function, not " + typeof superClass);
  }

  subClass.prototype = Object.create(superClass && superClass.prototype, {
    constructor: {
      value: subClass,
      enumerable: false,
      writable: true,
      configurable: true
    }
  });
  if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass;
}

import { QurttyModel } from './modelDefault';
import * as apiHelper from '../../api/apiHelper';
import { fk, many, oneToOne, attr, Model, ORM } from 'redux-orm';
import { session } from '../schemas';
import _ from 'lodash';
var HkPostal_Zone = (_temp = _class = function (_QurttyModel) {
  _inherits(HkPostal_Zone, _QurttyModel);

  function HkPostal_Zone() {
    _classCallCheck(this, HkPostal_Zone);

    return _possibleConstructorReturn(this, _QurttyModel.apply(this, arguments));
  }

  return HkPostal_Zone;
}(QurttyModel), _class.modelName = 'HkPostal_Zone', _class.fields = {}, _temp);
var HkPostal_District = (_temp2 = _class2 = function (_QurttyModel2) {
  _inherits(HkPostal_District, _QurttyModel2);

  function HkPostal_District() {
    _classCallCheck(this, HkPostal_District);

    return _possibleConstructorReturn(this, _QurttyModel2.apply(this, arguments));
  }

  return HkPostal_District;
}(QurttyModel), _class2.modelName = 'HkPostal_District', _class2.fields = {
  zone_id: fk({
    to: 'HkPostal_Zone',
    as: '_zone',
    relatedName: 'districts'
  })
}, _temp2);
var HkPostal_Street = (_temp3 = _class3 = function (_QurttyModel3) {
  _inherits(HkPostal_Street, _QurttyModel3);

  function HkPostal_Street() {
    _classCallCheck(this, HkPostal_Street);

    return _possibleConstructorReturn(this, _QurttyModel3.apply(this, arguments));
  }

  return HkPostal_Street;
}(QurttyModel), _class3.modelName = 'HkPostal_Street', _class3.fields = {
  district_id: fk({
    to: 'HkPostal_District',
    as: '_district',
    relatedName: 'streets'
  })
}, _temp3);
var HkPostal_StreetNumber = (_temp4 = _class4 = function (_QurttyModel4) {
  _inherits(HkPostal_StreetNumber, _QurttyModel4);

  function HkPostal_StreetNumber() {
    _classCallCheck(this, HkPostal_StreetNumber);

    return _possibleConstructorReturn(this, _QurttyModel4.apply(this, arguments));
  }

  return HkPostal_StreetNumber;
}(QurttyModel), _class4.modelName = 'HkPostal_StreetNumber', _class4.fields = {
  district_id: fk({
    to: 'HkPostal_District',
    as: '_district',
    relatedName: 'street_numbers'
  }),
  street_id: fk({
    to: 'HkPostal_Street',
    as: '_street',
    relatedName: 'street_numbers'
  })
}, _temp4);
var HkPostal_Estate = (_temp5 = _class5 = function (_QurttyModel5) {
  _inherits(HkPostal_Estate, _QurttyModel5);

  function HkPostal_Estate() {
    _classCallCheck(this, HkPostal_Estate);

    return _possibleConstructorReturn(this, _QurttyModel5.apply(this, arguments));
  }

  return HkPostal_Estate;
}(QurttyModel), _class5.modelName = 'HkPostal_Estate', _class5.fields = {
  district_id: fk({
    to: 'HkPostal_District',
    as: '_district',
    relatedName: 'estates'
  })
}, _temp5);
var HkPostal_Phase = (_temp6 = _class6 = function (_QurttyModel6) {
  _inherits(HkPostal_Phase, _QurttyModel6);

  function HkPostal_Phase() {
    _classCallCheck(this, HkPostal_Phase);

    return _possibleConstructorReturn(this, _QurttyModel6.apply(this, arguments));
  }

  return HkPostal_Phase;
}(QurttyModel), _class6.modelName = 'HkPostal_Phase', _class6.fields = {
  estate_id: fk({
    to: 'HkPostal_Estate',
    as: '_estate',
    relatedName: 'phases'
  })
}, _temp6);
var HkPostal_Building = (_temp7 = _class7 = function (_QurttyModel7) {
  _inherits(HkPostal_Building, _QurttyModel7);

  function HkPostal_Building() {
    _classCallCheck(this, HkPostal_Building);

    return _possibleConstructorReturn(this, _QurttyModel7.apply(this, arguments));
  }

  return HkPostal_Building;
}(QurttyModel), _class7.modelName = 'HkPostal_Building', _class7.fields = {
  district_id: fk({
    to: 'HkPostal_District',
    as: '_district',
    relatedName: 'buildings'
  }),
  estate_id: fk({
    to: 'HkPostal_Estate',
    as: '_estate',
    relatedName: 'buildings'
  }),
  phase_id: fk({
    to: 'HkPostal_Phase',
    as: '_phase',
    relatedName: 'buildings'
  })
}, _temp7);
var HkPostal_Floor = (_temp8 = _class8 = function (_QurttyModel8) {
  _inherits(HkPostal_Floor, _QurttyModel8);

  function HkPostal_Floor() {
    _classCallCheck(this, HkPostal_Floor);

    return _possibleConstructorReturn(this, _QurttyModel8.apply(this, arguments));
  }

  return HkPostal_Floor;
}(QurttyModel), _class8.modelName = 'HkPostal_Floor', _class8.fields = {
  building_id: fk({
    to: 'HkPostal_Building',
    as: '_building',
    relatedName: 'floors'
  })
}, _temp8);
var HkPostal_Unit = (_temp9 = _class9 = function (_QurttyModel9) {
  _inherits(HkPostal_Unit, _QurttyModel9);

  function HkPostal_Unit() {
    _classCallCheck(this, HkPostal_Unit);

    return _possibleConstructorReturn(this, _QurttyModel9.apply(this, arguments));
  }

  return HkPostal_Unit;
}(QurttyModel), _class9.modelName = 'HkPostal_Unit', _class9.fields = {
  floor_id: fk({
    to: 'HkPostal_Floor',
    as: '_floor',
    relatedName: 'units'
  })
}, _temp9);
var HkPostal_StreetBuilding = (_temp10 = _class10 = function (_QurttyModel10) {
  _inherits(HkPostal_StreetBuilding, _QurttyModel10);

  function HkPostal_StreetBuilding() {
    _classCallCheck(this, HkPostal_StreetBuilding);

    return _possibleConstructorReturn(this, _QurttyModel10.apply(this, arguments));
  }

  return HkPostal_StreetBuilding;
}(QurttyModel), _class10.modelName = 'HkPostal_StreetBuilding', _class10.fields = {
  street_id: fk({
    to: 'HkPostal_Street',
    as: '_street',
    relatedName: 'street_buildings'
  }),
  building_id: fk({
    to: 'HkPostal_Building',
    as: '_building',
    relatedName: 'street_buildings'
  })
}, _temp10);
var HkPostal_StreetEstate = (_temp11 = _class11 = function (_QurttyModel11) {
  _inherits(HkPostal_StreetEstate, _QurttyModel11);

  function HkPostal_StreetEstate() {
    _classCallCheck(this, HkPostal_StreetEstate);

    return _possibleConstructorReturn(this, _QurttyModel11.apply(this, arguments));
  }

  return HkPostal_StreetEstate;
}(QurttyModel), _class11.modelName = 'HkPostal_StreetEstate', _class11.fields = {
  street_id: fk({
    to: 'HkPostal_Street',
    as: '_street',
    relatedName: 'street_estates'
  }),
  estate_id: fk({
    to: 'HkPostal_Estate',
    as: '_estate',
    relatedName: 'street_estates'
  })
}, _temp11);
var HkPostal_StreetNumberBuilding = (_temp12 = _class12 = function (_QurttyModel12) {
  _inherits(HkPostal_StreetNumberBuilding, _QurttyModel12);

  function HkPostal_StreetNumberBuilding() {
    _classCallCheck(this, HkPostal_StreetNumberBuilding);

    return _possibleConstructorReturn(this, _QurttyModel12.apply(this, arguments));
  }

  return HkPostal_StreetNumberBuilding;
}(QurttyModel), _class12.modelName = 'HkPostal_StreetNumberBuilding', _class12.fields = {
  street_number_id: fk({
    to: 'HkPostal_StreetNumber',
    as: '_street',
    relatedName: 'street_number_buildings'
  }),
  building_id: fk({
    to: 'HkPostal_Building',
    as: '_building',
    relatedName: 'street_number_buildings'
  })
}, _temp12);
var HkPostal_StreetNumberEstate = (_temp13 = _class13 = function (_QurttyModel13) {
  _inherits(HkPostal_StreetNumberEstate, _QurttyModel13);

  function HkPostal_StreetNumberEstate() {
    _classCallCheck(this, HkPostal_StreetNumberEstate);

    return _possibleConstructorReturn(this, _QurttyModel13.apply(this, arguments));
  }

  return HkPostal_StreetNumberEstate;
}(QurttyModel), _class13.modelName = 'HkPostal_StreetNumberEstate', _class13.fields = {
  street_number_id: fk({
    to: 'HkPostal_StreetNumber',
    as: '_street',
    relatedName: 'street_number_estates'
  }),
  estate_id: fk({
    to: 'HkPostal_Estate',
    as: '_estate',
    relatedName: 'street_number_estates'
  })
}, _temp13);
export var hkPostalModelRegister = function hkPostalModelRegister(orm) {
  return orm.register(HkPostal_Zone, HkPostal_District, HkPostal_Street, HkPostal_StreetNumber, HkPostal_Estate, HkPostal_Phase, HkPostal_Building, HkPostal_Floor, HkPostal_Unit, HkPostal_StreetBuilding, HkPostal_StreetEstate, HkPostal_StreetNumberBuilding, HkPostal_StreetNumberEstate);
};