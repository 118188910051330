import React, {Component} from 'react';
import {connect} from 'react-redux';
import bg1 from '../../assets/photo/banner-1.jpg'

import {
  Carousel,
  CarouselItem,
  CarouselControl,
  CarouselIndicators,
  CarouselCaption
} from 'reactstrap';



class Background extends Component {

  constructor(props) {
    super(props);
    const data = props.data;
    const photos = data.rows[0][0].values;

    this.state = {
      activeIndex: 0,
      items: photos.map( photo => {
        return {
          src: photo ? photo.resized_l_url : null,
          altText: '',
          caption: ''}
      })
    };

    this.next = this.next.bind(this);
    this.previous = this.previous.bind(this);
    this.goToIndex = this.goToIndex.bind(this);
    this.onExiting = this.onExiting.bind(this);
    this.onExited = this.onExited.bind(this);
  }

  onExiting() {
    this.animating = true;
  }

  onExited() {
    this.animating = false;
  }

  next() {
    if (this.animating) return;
    const nextIndex = this.state.activeIndex === this.state.items.length - 1 ? 0 : this.state.activeIndex + 1;
    this.setState({ activeIndex: nextIndex });
  }

  previous() {
    if (this.animating) return;
    const nextIndex = this.state.activeIndex === 0 ? this.state.items.length - 1 : this.state.activeIndex - 1;
    this.setState({ activeIndex: nextIndex });
  }

  goToIndex(newIndex) {
    if (this.animating) return;
    this.setState({ activeIndex: newIndex });
  }

  render() {
    const { activeIndex } = this.state;

    const slides = this.state.items.map((item, index) => {
      return (
        <CarouselItem
          onExiting={this.onExiting}
          onExited={this.onExited}
          key={index}
        >
          <img className="backgroundImg" src={item.src} alt={item.altText} />
          {/*  <div>
              <div> Wholesale Recycled M </div>
            </div> */ }

          <CarouselCaption captionText={item.caption} captionHeader={item.caption} />
        </CarouselItem>
      );
    });

    if (this.props.data.is_hidden) {
      return (<div></div>)
    } else {
      return (
        <div id="Background">
          <Carousel
            interval="15000"

            activeIndex={activeIndex}
            next={this.next}
            previous={this.previous}
            >
            <CarouselIndicators items={this.state.items} activeIndex={activeIndex} onClickHandler={this.goToIndex} />
            {slides}
            <CarouselControl direction="prev" directionText="Previous" onClickHandler={this.previous} />
            <CarouselControl direction="next" directionText="Next" onClickHandler={this.next} />
          </Carousel>
        </div>
      );
    }
  }

}

const mapStateToProps = (state) => {
  return {
    language: state.uiReducer.language,
    data: state.dataReducer.dataSet.homepageBanner,
  };
};

const dispatchToProps = {
  // test: (key) => (dispatch) => dispatch({type: TEST}),
}

export default connect(mapStateToProps, dispatchToProps)(Background);
