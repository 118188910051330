var _class, _temp, _class2, _temp2, _class3, _temp3;

function _classCallCheck(instance, Constructor) {
  if (!(instance instanceof Constructor)) {
    throw new TypeError("Cannot call a class as a function");
  }
}

function _possibleConstructorReturn(self, call) {
  if (!self) {
    throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
  }

  return call && (typeof call === "object" || typeof call === "function") ? call : self;
}

function _inherits(subClass, superClass) {
  if (typeof superClass !== "function" && superClass !== null) {
    throw new TypeError("Super expression must either be null or a function, not " + typeof superClass);
  }

  subClass.prototype = Object.create(superClass && superClass.prototype, {
    constructor: {
      value: subClass,
      enumerable: false,
      writable: true,
      configurable: true
    }
  });
  if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass;
}

import { QurttyModel } from './modelDefault';
import { fk, many, oneToOne, attr } from 'redux-orm';
var Company = (_temp = _class = function (_QurttyModel) {
  _inherits(Company, _QurttyModel);

  function Company() {
    _classCallCheck(this, Company);

    return _possibleConstructorReturn(this, _QurttyModel.apply(this, arguments));
  }

  return Company;
}(QurttyModel), _class.modelName = 'Company', _class.fields = {}, _temp);
var AssetPool = (_temp2 = _class2 = function (_QurttyModel2) {
  _inherits(AssetPool, _QurttyModel2);

  function AssetPool() {
    _classCallCheck(this, AssetPool);

    return _possibleConstructorReturn(this, _QurttyModel2.apply(this, arguments));
  }

  return AssetPool;
}(QurttyModel), _class2.modelName = 'AssetPool', _class2.fields = {
  company_id: fk({
    to: 'Company',
    as: 'company',
    relatedName: 'asset_pool'
  })
}, _temp2);
var Department = (_temp3 = _class3 = function (_QurttyModel3) {
  _inherits(Department, _QurttyModel3);

  function Department() {
    _classCallCheck(this, Department);

    return _possibleConstructorReturn(this, _QurttyModel3.apply(this, arguments));
  }

  return Department;
}(QurttyModel), _class3.modelName = 'Department', _class3.fields = {
  company_id: fk({
    to: 'Company',
    as: 'company',
    relatedName: 'departments'
  }),
  parent_department_id: fk({
    to: 'Department',
    as: 'parent_department',
    relatedName: 'child_departments'
  })
}, _temp3);
export var companyModelRegister = function companyModelRegister(orm) {
  orm.register(Company, AssetPool, Department);
};