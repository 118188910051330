import React, { Component } from 'react';
import {connect} from 'react-redux';

import { Header, ProductsTable, Footer } from './scenes/index.js';
import MainPage from './scenes/MainPage';

import { TOGGLE_CATEGORY, SET_LANGUAGE } from './actions/ActionTypes';
import * as actions from './actions/Action.js';
import Loader from 'react-loader-spinner';
import Icon from './assets/icon/cgt-logo.svg'
import topIcon from './assets/icon/upwards-arrow.svg'
import queryString from 'query-string';

//used Router instead of BrowserRouter to sync the history props, history is defined in uiReducer
import { Router, Route, Link, Switch, Redirect} from 'react-router-dom';

class App extends Component {
  constructor(props){
    super(props);
    this.state = {
      isShowBackDrop: false,
      pageYOffset: 0
    }
    console.log(props);
    let params = queryString.parse(this.props.history.location.search);
    this.props.setLanguage(params.lang || '')

    this.scrollToTopImmediately = this.scrollToTopImmediately.bind(this);
    this.toggleBackDrop = this.toggleBackDrop.bind(this);
    this.handleScroll = this.handleScroll.bind(this);
    this.scrollToComponent = this.scrollToComponent.bind(this);
    this.linkClickHandler = this.linkClickHandler.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (this.props.language !== prevProps.language) {
      console.log('lang updated!!!');
      this.props.history.push({
        search: `lang=${this.props.language}`
      })
    }
  }
  componentDidMount() {
    document.title = "Creative Global Technology";
    window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  //control scrollToTop appear or not
  handleScroll() {
    this.setState({pageYOffset: window.pageYOffset});
  }

  scrollToComponent(id) {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ block: 'start',  behavior: 'smooth' });
    }
  }

  scrollToTopImmediately() {
    window.scrollTo(0, 0)
  }

  scrollToTopGently() {
    //Note: use react-scroll to achieve same scrolling time at different location
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }

  toggleBackDrop() {
    this.setState({ isShowBackDrop: !this.state.isShowBackDrop });
  }

  scrollToComponent(id) {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ block: 'start',  behavior: 'smooth' });
    }
  }

  //first variable is path location + hash
  //second variable is componentID corresponding to the hash, saved as state in the "history" prop
  //second variable is used to scrollIntoView the component if previousPath is outside Mainpage
  //**look at this.props.history.location for further info**
  linkClickHandler(hash, component) {
    this.props.history.push({hash: hash, search: `?lang=${this.props.language}`, pathname:'/'}, {id: component});
  }

  render() {
    const links = [
      {name_en: 'Home', footerName_en: 'Home', name_zh_hk: '首頁', footerName_zh_hk: '首頁', component: 'Header', hash: '#home'},
      {name_en: 'CGT', footerName_en: 'About', name_zh_hk: 'CGT', footerName_zh_hk: '關於我們', component: 'About', hash: '#about'},
      {name_en: 'Our Team', footerName_en: 'People', name_zh_hk: '我們的團隊', footerName_zh_hk: '成員', component: 'People', hash: '#people'},
      {name_en: 'Join Us', footerName_en: 'Teams', name_zh_hk: '加入我們', footerName_zh_hk: '團隊', component: 'Teams', hash: '#teams'},
      {name_en: 'Suppliers', footerName_en: 'Partners', name_zh_hk: '合作夥伴', footerName_zh_hk: '合作夥伴', component: 'Suppliers', hash: '#suppliers'},
      {name_en: 'Customers', footerName_en: 'Clients', name_zh_hk: '顧客', footerName_zh_hk: '顧客', component: 'Client', hash: '#client'},
      {name_en: 'Products', footerName_en: 'Products', name_zh_hk: '產品', footerName_zh_hk: '產品', component: 'Products', hash: '#products'},
      {name_en: 'Certifications', footerName_en: 'Awards', name_zh_hk: '專業認證', footerName_zh_hk: '專業認證', component: 'Award', hash: '#award'},
      {name_en: 'Product Grading', footerName_en: 'Grading', name_zh_hk: '產品質量', footerName_zh_hk: '產品質量', component: 'Grading', hash: '#grading'},
      {name_en: 'Packaging', footerName_en: 'Packaging', name_zh_hk: '包裝', footerName_zh_hk: '包裝', component: 'Packaging', hash: '#packaging'},
    ]

    const topClass = this.state.pageYOffset > 0 ? "BackToTop active":"BackToTop";

    // if (this.props.dataSet) {
    // if (false) {
      return (
        <Router history={this.props.history}>
          <div className={`App ${this.props.allowMainScroll ? '' : 'avoid-scroll'}`} >
            {this.state.isShowBackDrop && <div className="backdrop" onClick={()=> {this.props.toggleCategory(); this.toggleBackDrop()}}/>}
            <Switch>
              <Route exact path="/" render={(props) => <MainPage {...props} links={links} scrollToComponent={this.scrollToComponent} linkClickHandler={this.linkClickHandler} />}  />
              <Route path="/product" render={(props) => <ProductsTable {...props} links={links} scrollToComponent={this.scrollToComponent} linkClickHandler={this.linkClickHandler} scrollToTopImmediately={this.scrollToTopImmediately} toggleBackDrop={this.toggleBackDrop}/>} />
              <Redirect path="/"/>
            </Switch>


            <div className={topClass} onClick={()=>this.scrollToTopGently()}>
              <img src={topIcon}/>
            </div>
          </div>

        </Router>
      )

  }
}

const mapStateToProps = (state) => {
  return {
    history: state.uiReducer.history,
    dataSet: state.dataReducer.dataSet,
    allowMainScroll: state.uiReducer.allowMainScroll,
    language: state.uiReducer.language
  };
};
const dispatchToProps = {
  toggleCategory: () => dispatch => dispatch({type: TOGGLE_CATEGORY}),
  loadData: actions.loadData,
  setLanguage: (language) => (dispatch) => dispatch({type: SET_LANGUAGE, language: language}),

}


export default connect(mapStateToProps, dispatchToProps)(App);
