var _class, _temp, _class2, _temp3, _class3, _temp5, _class4, _temp7, _class5, _temp8, _class6, _temp10, _class7, _temp12;

function _classCallCheck(instance, Constructor) {
  if (!(instance instanceof Constructor)) {
    throw new TypeError("Cannot call a class as a function");
  }
}

function _possibleConstructorReturn(self, call) {
  if (!self) {
    throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
  }

  return call && (typeof call === "object" || typeof call === "function") ? call : self;
}

function _inherits(subClass, superClass) {
  if (typeof superClass !== "function" && superClass !== null) {
    throw new TypeError("Super expression must either be null or a function, not " + typeof superClass);
  }

  subClass.prototype = Object.create(superClass && superClass.prototype, {
    constructor: {
      value: subClass,
      enumerable: false,
      writable: true,
      configurable: true
    }
  });
  if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass;
}

import { QurttyModel } from './modelDefault';
import { fk, many, oneToOne, attr, Model, ORM } from 'redux-orm';
import orm, { session, schemaModel } from '../schemas';
import { organizeParam, normalizedDataInsertORM, modelCreate, modelSaving, modelDelete } from '../ormHelpers';
import { normalize } from 'normalizr';

var getPhotoUrls = function getPhotoUrls(urlName) {
  var category = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'card_profile';
  var options = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
  var photos = this.getPhotoablePhotoParticipations().filter(function (ppp) {
    return ppp.category === category;
  }).map(function (ppp) {
    return ppp.photo_participation.photo;
  }).filter(Boolean);

  if ((photos || []).length > 0) {
    var urls = photos.map(function (p) {
      return p[urlName] || p.icon_url;
    });

    if (options.noCache) {
      urls = urls.map(function (url) {
        return url + ('?timestamp=' + Date.now());
      });
    }

    if (options.noSsl) {
      urls = urls.map(function (url) {
        return url.replace(/^https:\/\//i, 'http://');
      });
    }

    return urls;
  } else {
    if (options.noFallback) {
      return [];
    } else {
      return [this.fallbackurl];
    }
  }
};

var getPhotoUrl = function getPhotoUrl(urlName) {
  var category = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'card_profile';
  var options = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
  return this.getPhotoUrls(urlName, category, options)[0];
}; // Card Start


var StaffCard = (_temp = _class = function (_QurttyModel) {
  _inherits(StaffCard, _QurttyModel);

  function StaffCard() {
    _classCallCheck(this, StaffCard);

    return _possibleConstructorReturn(this, _QurttyModel.apply(this, arguments));
  }

  return StaffCard;
}(QurttyModel), _class.modelName = 'StaffCard', _class.fields = {
  employment_id: fk({
    to: 'Employment',
    as: 'employment',
    relatedName: 'staff_cards'
  })
}, _temp);
var Membership = (_temp3 = _class2 = function (_QurttyModel2) {
  _inherits(Membership, _QurttyModel2);

  function Membership() {
    var _temp2, _this2, _ret;

    _classCallCheck(this, Membership);

    for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    return _ret = (_temp2 = (_this2 = _possibleConstructorReturn(this, _QurttyModel2.call.apply(_QurttyModel2, [this].concat(args))), _this2), _this2.modelUrl = 'card/memberships', _temp2), _possibleConstructorReturn(_this2, _ret);
  }

  return Membership;
}(QurttyModel), _class2.modelName = 'Membership', _class2.fields = {
  member_card_id: fk({
    to: 'MemberCard',
    as: 'member_card',
    relatedName: 'memberships'
  }),
  company_card_id: fk({
    to: 'CompanyCard',
    as: 'company_card',
    relatedName: 'memberships'
  }),
  business_card: oneToOne('BusinessCard')
}, _temp3);
var MemberCard = (_temp5 = _class3 = function (_QurttyModel3) {
  _inherits(MemberCard, _QurttyModel3);

  function MemberCard() {
    var _temp4, _this3, _ret2;

    _classCallCheck(this, MemberCard);

    for (var _len2 = arguments.length, args = Array(_len2), _key2 = 0; _key2 < _len2; _key2++) {
      args[_key2] = arguments[_key2];
    }

    return _ret2 = (_temp4 = (_this3 = _possibleConstructorReturn(this, _QurttyModel3.call.apply(_QurttyModel3, [this].concat(args))), _this3), _this3.modelUrl = 'member_cards', _this3.fallbackurl = 'https://my.qurtty.com/assets/themes/1/images/icon/noprofilephoto.png', _this3.photoablePhotoParticipationKeyName = 'member_card_photo_participations', _this3.getPhotoUrls = getPhotoUrls, _this3.getPhotoUrl = getPhotoUrl, _temp4), _possibleConstructorReturn(_this3, _ret2);
  }

  MemberCard.prototype.getPhotoablePhotoParticipations = function getPhotoablePhotoParticipations() {
    return this.getAssociation('member_card_in_member_photo_participations');
  };

  MemberCard.prototype.printName = function printName() {
    var locale = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 'en';
    var combination = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
    var subj = void 0;

    if (this.member) {
      subj = this.member.employment_id ? this.member._employment.employee_master : this.member;
      subj = subj || this;
    } else {
      subj = this;
    }

    var combo = combination || this.name_combination;
    return combo.split(' ').map(function (part) {
      return subj[part + '_' + locale] || '';
    }).join(' ').trim();
  };

  MemberCard.prototype.getBusinessCard = function getBusinessCard() {
    var membership = this.memberships.toModelArray()[0];
    return membership ? membership.business_card : null;
  };

  MemberCard.prototype.getGangCard = function getGangCard() {
    return this.memberships.toModelArray().map(function (c) {
      return c.company_card;
    })[0];
  };

  MemberCard.prototype.fromMeStatus = function fromMeStatus() {
    var card = this;

    if (card.getAssociation('sealed_cards').length > 0) {
      card = card.getAssociation('sealed_cards')[0];
    }

    if (card.sealed_for_card_id) {
      // linked to a real card
      var friendships = card.received_friendships;
      var friendship = friendships.first();

      if (friendship) {
        if (friendship.status === 'requesting') {
          return 'pending friendship accept';
        } else if (friendship.status === 'accepted') {
          return 'added as friend';
        } else {
          return 'unknown';
        }
      } else {
        return 'real';
      }
    } else {
      // not linked to a real card
      // when creating new request, received_friendships_count won't update from backend, so need to count by count()
      if (card['received_friendships_count'] > 0 || card.received_friendships.count() > 0) {
        return 'pending signup';
      } else {
        return 'virtual';
      }
    }
  };

  MemberCard.prototype.fromOtherStatus = function fromOtherStatus(myMemberId) {
    var card = this;

    if (card.sealed_for_card) {
      card = card.sealed_for_card;
    } // this should be a real card (others)


    var friendships = card.requested_friendships.filter({
      cos_member_id: myMemberId
    });
    var friendship = friendships.first();

    if (friendship) {
      if (friendship.status === 'requesting') {
        return 'pending friendship accept';
      } else if (friendship.status === 'accepted') {
        return 'added as friend';
      } else {
        return 'unknown';
      }
    } else {
      return 'error';
    }
  };

  return MemberCard;
}(QurttyModel), _class3.modelName = 'MemberCard', _class3.nameCombinations = ['firstname lastname', 'lastname middlename firstname', 'lastname (middlename) firstname', 'lastname firstname', 'title firstname lastname', 'title lastname middlename firstname', 'title lastname (middlename) firstname', 'title lastname firstname', 'firstname lastname title', 'lastname middlename firstname title', 'lastname (middlename) firstname title', 'lastname firstname title'], _class3.fields = {
  member_id: fk({
    to: 'Member',
    as: 'member',
    relatedName: 'hold_member_cards'
  }),
  owner_id: fk({
    to: 'Member',
    as: 'owner',
    relatedName: 'member_cards'
  }),
  extension_entity: oneToOne('Eav_Entity'),
  sealed_for_card_id: fk({
    to: 'MemberCard',
    as: 'sealed_for_card',
    relatedName: 'sealed_cards'
  }),
  sealed_for_staff_card_id: fk({
    to: 'StaffCard',
    as: 'sealed_for_staff_card',
    relatedName: 'member_card'
  }),
  sealed_for_employment_id: fk({
    to: 'Employment',
    as: 'sealed_for_employment',
    relatedName: 'sealed_employee_account_card'
  })
}, _temp5);
var CompanyCard = (_temp7 = _class4 = function (_QurttyModel4) {
  _inherits(CompanyCard, _QurttyModel4);

  function CompanyCard() {
    var _temp6, _this4, _ret3;

    _classCallCheck(this, CompanyCard);

    for (var _len3 = arguments.length, args = Array(_len3), _key3 = 0; _key3 < _len3; _key3++) {
      args[_key3] = arguments[_key3];
    }

    return _ret3 = (_temp6 = (_this4 = _possibleConstructorReturn(this, _QurttyModel4.call.apply(_QurttyModel4, [this].concat(args))), _this4), _this4.modelUrl = 'company_cards', _this4.fallbackurl = 'https://my.qurtty.com/assets/themes/1/images/icon/nocompany.png', _this4.photoablePhotoParticipationKeyName = 'company_card_photo_participations', _this4.getPhotoUrls = getPhotoUrls, _this4.getPhotoUrl = getPhotoUrl, _temp6), _possibleConstructorReturn(_this4, _ret3);
  }

  CompanyCard.prototype.getPhotoablePhotoParticipations = function getPhotoablePhotoParticipations() {
    return this.getAssociation('company_card_in_company_photo_participations').concat(this.getAssociation('company_card_in_member_photo_participations'));
  };

  CompanyCard.prototype.getGangsterCards = function getGangsterCards() {
    return this.memberships.toModelArray().map(function (c) {
      return c.member_card;
    }).filter(Boolean);
  };

  return CompanyCard;
}(QurttyModel), _class4.modelName = 'CompanyCard', _class4.fields = {
  company_id: fk({
    to: 'Company',
    as: 'company',
    relatedName: 'hold_company_cards'
  }),
  owner_id: fk({
    to: 'Member',
    as: 'owner',
    relatedName: 'comnpany_cards'
  }),
  sealed_for_staff_card_id: fk({
    to: 'StaffCard',
    as: 'sealed_for_staff_card',
    relatedName: 'company_card'
  }),
  sealed_for_employment_id: fk({
    to: 'Employment',
    as: 'sealed_for_employment',
    relatedName: 'sealed_company_card'
  }),
  extension_entity: oneToOne('Eav_Entity')
}, _temp7);
var BusinessCard = (_temp8 = _class5 = function (_QurttyModel5) {
  _inherits(BusinessCard, _QurttyModel5);

  function BusinessCard() {
    _classCallCheck(this, BusinessCard);

    return _possibleConstructorReturn(this, _QurttyModel5.apply(this, arguments));
  }

  return BusinessCard;
}(QurttyModel), _class5.modelName = 'BusinessCard', _class5.fields = {}, _temp8);
var Society = (_temp10 = _class6 = function (_QurttyModel6) {
  _inherits(Society, _QurttyModel6);

  function Society() {
    var _temp9, _this6, _ret4;

    _classCallCheck(this, Society);

    for (var _len4 = arguments.length, args = Array(_len4), _key4 = 0; _key4 < _len4; _key4++) {
      args[_key4] = arguments[_key4];
    }

    return _ret4 = (_temp9 = (_this6 = _possibleConstructorReturn(this, _QurttyModel6.call.apply(_QurttyModel6, [this].concat(args))), _this6), _this6.modelUrl = 'societies', _temp9), _possibleConstructorReturn(_this6, _ret4);
  }

  return Society;
}(QurttyModel), _class6.modelName = 'Society', _class6.fields = {
  member_id: fk({
    to: 'Member',
    as: 'member',
    relatedName: 'societies'
  }),
  parent_society_id: fk({
    to: 'Society',
    as: 'parent_society',
    relatedName: 'child_societies'
  })
}, _temp10);
var SocietyMemberCard = (_temp12 = _class7 = function (_QurttyModel7) {
  _inherits(SocietyMemberCard, _QurttyModel7);

  function SocietyMemberCard() {
    var _temp11, _this7, _ret5;

    _classCallCheck(this, SocietyMemberCard);

    for (var _len5 = arguments.length, args = Array(_len5), _key5 = 0; _key5 < _len5; _key5++) {
      args[_key5] = arguments[_key5];
    }

    return _ret5 = (_temp11 = (_this7 = _possibleConstructorReturn(this, _QurttyModel7.call.apply(_QurttyModel7, [this].concat(args))), _this7), _this7.modelUrl = 'society_member_cards', _temp11), _possibleConstructorReturn(_this7, _ret5);
  }

  return SocietyMemberCard;
}(QurttyModel), _class7.modelName = 'SocietyMemberCard', _class7.fields = {
  society_id: fk({
    to: 'Society',
    as: 'society',
    relatedName: 'society_member_cards'
  }),
  member_card_id: fk({
    to: 'MemberCard',
    as: 'member_card',
    relatedName: 'society_member_cards'
  })
}, _temp12);
export var cardModelRegister = function cardModelRegister(orm) {
  orm.register(Membership, MemberCard, CompanyCard, BusinessCard, Society, SocietyMemberCard, StaffCard);
};