import React, {Component} from 'react';
import {connect} from 'react-redux';
import { SET_PRODUCT, TOGGLE_POPUP, TOGGLE_CATEGORY } from '../../../actions/ActionTypes';
import { Collapse, Button, CardBody, Card, NavLink} from 'reactstrap';
import Colorbar from '../../../components/colorbar/Colorbar';

class Categories extends Component {
  constructor(props) {
    super(props);
    // console.log(this.props.category);
    this.toggle = this.toggle.bind(this);
    this.state = {
      collapse: false,
    };
  }

  // initizeByCategory(category){
  //   this.setState({ collapse: category });
  // }

  toggle(category) {
    if (this.state.collapse === category) {
      this.setState({ collapse: false });
    } else {
      this.setState({ collapse: category });
    }
  }

  // componentDidUpdate(prevProps) {
  //   if (this.props.current_category !== prevProps.current_category) {
  //     this.setState({ collapse: this.props.current_category });
  //   }
  // }

  componentDidMount(){
    if(this.state.collapse === false) {
      this.setState({ collapse: this.props.current_category });
    }
  }
  //only fire at mobile/tablet screen size
  handleMobileCategory() {
    if (window.matchMedia("(max-width: 768px)").matches) {
      this.props.toggleBackDrop();
      this.props.toggleCategory();
    }
  }
//href={`#${brand.name}`}
  render() {
    console.log(this.props.categories);
    return (
      <div id="Categories" className={`col-md-3 d-flex flex-column align-items-md-start ${this.props.isShowCategory ? 'active' : ''}`} >
        <div className="header">
          <div className="categories-title font-18 font-weight-bold">{this.props.language === 'zh_hk' ? '產品目錄' : 'PRODUCT CATEGORIES'}</div>
          <Colorbar width="100%"/>
        </div>

        <div>
          <div
          className={`type font-weight-bold category ${ this.props.current_category === 'All' ? 'active': ''}`}
          style={{ marginBottom: '1rem' }}
          onClick={() => {
            this.props.setProducts('All', {name: 'All', products: this.props.categories.map(item => item.brands.map(brand => brand.products.map( product => {return {...product, brand: brand.name}} ) ).flat()).flat() })
            this.props.togglePopup(false);
            this.handleMobileCategory();
          }}>
            {this.props.language === 'zh_hk' ? '所有' : 'All'}
          </div>
        </div>


       {this.props.categories.map((item, index) =>
        <div key={index}>
          <div className="type font-weight-bold" onClick={()=>this.toggle(item.name_en)} style={{ marginBottom: '1rem' }}>{item[`name_${this.props.language}`]}</div>
          <Collapse isOpen={this.state.collapse === item.name_en}>
            <Card>
              <CardBody>
                {item.brands.map((brand, i) =>
                  <NavLink key={i} className={this.props.current_category === item.name_en && brand.name === this.props.current_brand.name ? 'active' : ''}
                    onClick={()=>{
                      this.props.setProducts(item.name_en, brand);
                      this.props.togglePopup(false);
                      // this.props.scrollToTopImmediately(); //maybe gently?
                      this.handleMobileCategory();
                    }}
                  >{brand.name}</NavLink>
                )}
              </CardBody>
            </Card>
          </Collapse>
        </div>
      )}
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    isShowCategory: state.uiReducer.isShowCategory,
    current_brand: state.uiReducer.current_brand,
    current_category: state.uiReducer.current_category,
    language: state.uiReducer.language
  };
};

const dispatchToProps = {
  toggleCategory: () => dispatch => dispatch({type: TOGGLE_CATEGORY}),
  togglePopup: (bool) => dispatch => dispatch({type: TOGGLE_POPUP, bool: bool}),
  setProducts: (category, brand) => (dispatch) => dispatch({type: SET_PRODUCT, category: category, brand: brand}),
}

export default connect(mapStateToProps, dispatchToProps)(Categories);
