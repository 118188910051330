var _class, _temp2, _class2, _temp4, _class3, _temp6, _class4, _temp8, _class5, _temp10;

function _classCallCheck(instance, Constructor) {
  if (!(instance instanceof Constructor)) {
    throw new TypeError("Cannot call a class as a function");
  }
}

function _possibleConstructorReturn(self, call) {
  if (!self) {
    throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
  }

  return call && (typeof call === "object" || typeof call === "function") ? call : self;
}

function _inherits(subClass, superClass) {
  if (typeof superClass !== "function" && superClass !== null) {
    throw new TypeError("Super expression must either be null or a function, not " + typeof superClass);
  }

  subClass.prototype = Object.create(superClass && superClass.prototype, {
    constructor: {
      value: subClass,
      enumerable: false,
      writable: true,
      configurable: true
    }
  });
  if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass;
}

import { QurttyModel } from './modelDefault';
import { fk, many, oneToOne, attr, Model, ORM } from 'redux-orm';
import orm from '../schemas';
var RecruitForm = (_temp2 = _class = function (_QurttyModel) {
  _inherits(RecruitForm, _QurttyModel);

  function RecruitForm() {
    var _temp, _this, _ret;

    _classCallCheck(this, RecruitForm);

    for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    return _ret = (_temp = (_this = _possibleConstructorReturn(this, _QurttyModel.call.apply(_QurttyModel, [this].concat(args))), _this), _this.modelUrl = 'recruit_forms', _temp), _possibleConstructorReturn(_this, _ret);
  }

  return RecruitForm;
}(QurttyModel), _class.modelName = 'RecruitForm', _class.fields = {
  employment_id: fk({
    to: 'Employment',
    as: '_employment',
    relatedName: 'recruit_forms'
  }),
  department_id: fk({
    to: 'Department',
    as: '_department',
    relatedName: 'recruit_forms'
  }),
  applicant_card: oneToOne('MemberCard', 'recruit_form')
}, _temp2);
var Recruit_AcademicRecord = (_temp4 = _class2 = function (_QurttyModel2) {
  _inherits(Recruit_AcademicRecord, _QurttyModel2);

  function Recruit_AcademicRecord() {
    var _temp3, _this2, _ret2;

    _classCallCheck(this, Recruit_AcademicRecord);

    for (var _len2 = arguments.length, args = Array(_len2), _key2 = 0; _key2 < _len2; _key2++) {
      args[_key2] = arguments[_key2];
    }

    return _ret2 = (_temp3 = (_this2 = _possibleConstructorReturn(this, _QurttyModel2.call.apply(_QurttyModel2, [this].concat(args))), _this2), _this2.modelUrl = 'recruit/academic_records', _temp3), _possibleConstructorReturn(_this2, _ret2);
  }

  return Recruit_AcademicRecord;
}(QurttyModel), _class2.modelName = 'Recruit_AcademicRecord', _class2.fields = {
  recruit_form_id: fk({
    to: 'RecruitForm',
    as: 'recruit_form',
    relatedName: 'academic_records'
  })
}, _temp4);
var Recruit_Award = (_temp6 = _class3 = function (_QurttyModel3) {
  _inherits(Recruit_Award, _QurttyModel3);

  function Recruit_Award() {
    var _temp5, _this3, _ret3;

    _classCallCheck(this, Recruit_Award);

    for (var _len3 = arguments.length, args = Array(_len3), _key3 = 0; _key3 < _len3; _key3++) {
      args[_key3] = arguments[_key3];
    }

    return _ret3 = (_temp5 = (_this3 = _possibleConstructorReturn(this, _QurttyModel3.call.apply(_QurttyModel3, [this].concat(args))), _this3), _this3.modelUrl = 'recruit/awards', _temp5), _possibleConstructorReturn(_this3, _ret3);
  }

  return Recruit_Award;
}(QurttyModel), _class3.modelName = 'Recruit_Award', _class3.fields = {
  recruit_form_id: fk({
    to: 'RecruitForm',
    as: 'recruit_form',
    relatedName: 'awards'
  })
}, _temp6);
var Recruit_Question = (_temp8 = _class4 = function (_QurttyModel4) {
  _inherits(Recruit_Question, _QurttyModel4);

  function Recruit_Question() {
    var _temp7, _this4, _ret4;

    _classCallCheck(this, Recruit_Question);

    for (var _len4 = arguments.length, args = Array(_len4), _key4 = 0; _key4 < _len4; _key4++) {
      args[_key4] = arguments[_key4];
    }

    return _ret4 = (_temp7 = (_this4 = _possibleConstructorReturn(this, _QurttyModel4.call.apply(_QurttyModel4, [this].concat(args))), _this4), _this4.modelUrl = 'recruit/questions', _temp7), _possibleConstructorReturn(_this4, _ret4);
  }

  return Recruit_Question;
}(QurttyModel), _class4.modelName = 'Recruit_Question', _class4.fields = {
  recruit_form_id: fk({
    to: 'RecruitForm',
    as: 'recruit_form',
    relatedName: 'questions'
  })
}, _temp8);
var Recruit_WorkExperience = (_temp10 = _class5 = function (_QurttyModel5) {
  _inherits(Recruit_WorkExperience, _QurttyModel5);

  function Recruit_WorkExperience() {
    var _temp9, _this5, _ret5;

    _classCallCheck(this, Recruit_WorkExperience);

    for (var _len5 = arguments.length, args = Array(_len5), _key5 = 0; _key5 < _len5; _key5++) {
      args[_key5] = arguments[_key5];
    }

    return _ret5 = (_temp9 = (_this5 = _possibleConstructorReturn(this, _QurttyModel5.call.apply(_QurttyModel5, [this].concat(args))), _this5), _this5.modelUrl = 'recruit/work_experiences', _temp9), _possibleConstructorReturn(_this5, _ret5);
  }

  return Recruit_WorkExperience;
}(QurttyModel), _class5.modelName = 'Recruit_WorkExperience', _class5.fields = {
  recruit_form_id: fk({
    to: 'RecruitForm',
    as: 'recruit_form',
    relatedName: 'work_experiences'
  })
}, _temp10);
export var recruitModelRegister = function recruitModelRegister(orm) {
  orm.register(RecruitForm, Recruit_AcademicRecord, Recruit_Award, Recruit_Question, Recruit_WorkExperience);
};