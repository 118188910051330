import React, {Component} from 'react';
import {connect} from 'react-redux';
import { } from '../../actions/ActionTypes';
import logo_white from '../../assets/icon/cgt-logo-white.png';

class Footer extends Component {

  scrollToComponent(id) {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ block: 'start',  behavior: 'smooth' });
    }
  }

  // componentDidUpdate(prevProps) {
  //   if (this.props.language !== prevProps.language) {
  //     this.setState(this)
  //   }
  // }
  Link() {
    let {links, scrollToComponent, linkClickHandler} = this.props;
    //NOTE: hard cord modification according to Sherman :)
    links = links.filter((link, index) => index === 0 || index === 1 || index === 3 || index === 4 || index === 6);
    let links1 = [], links2 = [];

    for (let i = 0; i < links.length; i++) {
      if (i < links.length / 2) {
        links1.push(links[i]);
      } else {
        links2.push(links[i]);
      }
    }

    const links1JSX = (links1.map((obj,index)=><div key={index} className="link" href={obj.hash} onClick={()=>{scrollToComponent(obj.component); linkClickHandler(obj.hash, obj.component)}}> > {obj[`footerName_${this.props.language}`]} </div>));
    const links2JSX = (links2.map((obj,index)=><div key={index} className="link" href={obj.hash} onClick={()=>{scrollToComponent(obj.component); linkClickHandler(obj.hash, obj.component)}}> > {obj[`footerName_${this.props.language}`]} </div>));

    return (
      <div className="col d-flex flex-column align-items-start">
        <div className="font-20 text-white"> {this.props.language === 'zh_hk' ? '連結' : 'Link'} </div>
        <div className="container-fluid">
          <div className="row">
            <div className="info-container col-12 col-md-4">
              {links1JSX}
            </div>
            <div className="info-container col-12 col-md-8">
              {links2JSX}
            </div>
          </div>
        </div>
      </div>
    )
  }

  Contact(addresses, emails, tels, time) {
    setInterval(()=>{ console.log(this.props.language); }, 3000);
    console.log();
    return (
      <div className="col d-flex flex-column align-items-center align-items-md-start">
        <div className="font-20 text-white"> {this.props.language === 'zh_hk' ? '聯絡我們' : 'Contact'} </div>
          <div className="container-fluid">
            <div className="row">
              <div className="info-container col-12 col-md-6">
                {addresses.map( (address, index) =>
                  <div key={index} className="address">{address.address[`line_1_${this.props.language}`]}</div>
                )}
                <div>{time}</div>
              </div>
              <div className="info-container col-12 col-md-6">
                {tels.map( (tel, index) =>
                  <div key={index} className="phone">{tel.phone.category}: {tel.phone.number}</div>
                  )}
                <br/>
                {emails.map( (email, index) =>
                  <div key={index} >{email.email.content}</div>
                  )}
              </div>
          </div>
          </div>
      </div>
    )
  }

  shouldComponentUpdate(nextProps, nextState) {
    return nextProps.data !== this.props.data || nextProps.language !== this.props.language
  }

  render() {
    console.log("RENDER FOOTER");

    // setInterval(()=>{ console.log(this.props.language); }, 3000);
    if (this.props.data && this.props.data.rows[0][0].values[0]) {
      console.log("footer", this.props.data);
      const data = this.props.data;
      const addresses = data.rows[0][0].values[0].getAssociation('address_exposures');
      const emails = data.rows[0][0].values[0].getAssociation('email_exposures');
      const tels = data.rows[0][0].values[0].getAssociation('phone_exposures');
      const time = data.rows[0][0].values[0].extension_entity.getValue(0)[`content_text_${this.props.language}`];

      // console.log(addresses);
      // console.log(emails);
      // console.log(tels);

      return (

        <div id="Footer">
          <div className="py-4 py-md-5 px-4 w-100">
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-2">
                  <img className="" src={logo_white} alt='OCTlogo'/>
                </div>
                <div className="col-md-10 row">
                  <div className="col-12 col-md-6 pt-3 pt-md-0">
                    {this.Contact(addresses, emails, tels, time)}
                  </div>
                  <div className="link-container col-12 col-md-6 pt-3 pt-md-0">
                    {this.Link()}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )
    }
    return <div/>
  }
}

const mapStateToProps = (state) => {
  return {
    data: state.dataReducer.dataSet ? state.dataReducer.dataSet.contact : null,
    language: state.uiReducer.language,
  };
};

const dispatchToProps = {
}

export default connect(mapStateToProps, dispatchToProps)(Footer);
